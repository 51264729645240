import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardFooter
} from "reactstrap";

import { Button, FormInputs } from "..";

import { getFoodPrep, updateFoodPrep } from "../../actions/foodprep_action";

import {
  getUIFoodPrepIsEdit,
  editFoodPrep,
  onCancelFoodPrepForm
} from "../../actions/ui_action";

class FoodPrepComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.foodprep
    };
  }

  render() {
    return (
      <Card className="card-chart">
        <CardHeader>
          <h5 className="title">Food Preparation Time</h5>
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-round btn-simple btn-icon"
              color="default"
            >
              <i className="now-ui-icons arrows-1_minimal-down" />
            </DropdownToggle>
            {!this.props.foodPrepIsEdit && (
              <DropdownMenu right>
                <DropdownItem onClick={() => this.props.editFoodPrep()}>
                  Edit
                </DropdownItem>
              </DropdownMenu>
            )}
          </UncontrolledDropdown>
        </CardHeader>
        <CardBody>
          <form>
            <FormInputs
              ncols={["col-md-6 pr-3", "col-md-6 pr-3"]}
              proprieties={[
                {
                  label: "A.M. Time",
                  inputProps: {
                    type: "text",
                    disabled: !this.props.foodPrepIsEdit,
                    value: this.state.am_time,
                    onChange: event =>
                      this.setState({
                        am_time: event.target.value
                      }),
                    style: this.state.am_time
                      ? null
                      : { border: "2px solid red" }
                  }
                },
                {
                  label: "P.M. Time",
                  inputProps: {
                    type: "number",
                    disabled: !this.props.foodPrepIsEdit,
                    value: this.state.pm_time,
                    onChange: event =>
                      this.setState({
                        pm_time: event.target.value
                      }),
                    style: this.state.pm_time
                      ? null
                      : { border: "2px solid red" }
                  }
                }
              ]}
            />
          </form>
        </CardBody>
        {this.props.foodPrepIsEdit && (
          <CardFooter className="card-footer-buttons-padding">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Button
                color="primary"
                style={{ width: "20%" }}
                onClick={() =>
                  this.props.updateFoodPrep(this.state, options =>
                    this.props.notifyFunc(options)
                  )
                }
              >
                Save
              </Button>
              <Button
                color="secondary"
                style={{ width: "75%" }}
                onClick={() => this.props.onCancelFoodPrepForm()}
              >
                Cancel
              </Button>
            </div>
          </CardFooter>
        )}
      </Card>
    );
  }
}

export default withRouter(
  connect(
    createStructuredSelector({
      foodprep: getFoodPrep,
      foodPrepIsEdit: getUIFoodPrepIsEdit
    }),
    {
      onCancelFoodPrepForm,
      editFoodPrep,
      updateFoodPrep
    }
  )(FoodPrepComponent)
);
