import React from "react";
import { Row, Col } from "reactstrap";
import { LoginComponent } from "../../components";
import { LoginBackground } from "../../assets/backgrounds";

const Login = (props) => (
  <div>
    <div
      style={{ height: "100vh", backgroundImage: `url(${LoginBackground})`, backgroundSize: 'cover' }}
    >
      <Row
        style={{
          top: 0,
          bottom: 0,
          position: "absolute",
          width: '100%',
          margin: 0
        }}
      >
        <Col md={2} xs={2}/>
        <Col md={8} xs={8} style={{ marginTop: "auto", marginBottom: "auto" }}>
          <LoginComponent/>
        </Col>
        <Col md={2} xs={2}/>
      </Row>
    </div>
  </div>
);

export default Login;