import React from "react";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  Button,
  ButtonGroup
} from "reactstrap";

import {
  PanelHeader,
  CustomersSuperComponent,
  CustomerFormSuperComponent
} from "../../components";

import {
  getUICustomerSelectedID,
  getUICustomerIsEdit,
  onCancelOrderForm,
  selectCustomerSuper
} from "../../actions/ui_action";

import { retrieveMode, updateMode } from "../../actions/mode_action";
import { getMode } from "../../actions/mode_action";
import { retrieveCustomers } from "../../actions/customers_action";
import { getCustomer } from "../../actions/customer_action";

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.notifyRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.customer.id !== this.props.customer.id ||
      nextProps.customerIsEdit !== this.props.customerIsEdit ||
      nextProps.mode !== this.props.mode
    );
  }

  componentDidMount() {
    this.props.onCancelOrderForm();
    this.props.retrieveMode();
    this.props.retrieveCustomers();
  }

  onNotification = options => this.notifyRef.current.notificationAlert(options);

  render() {
    return (
      <div>
        <NotificationAlert ref={this.notifyRef} />
        <PanelHeader size="sm" />
        <div className="content">
          {!this.props.customerIsEdit && (
            <Row>
              <Col xs={12} md={12}>
                <CustomersSuperComponent />
              </Col>
            </Row>
          )}

          {this.props.customerSelectedID &&
            this.props.customer.id === this.props.customerSelectedID && (
              <Row>
                <Col xs={12} md={12}>
                  <CustomerFormSuperComponent
                    key={
                      this.props.customerIsEdit
                        ? 0
                        : this.props.customerSelectedID
                    }
                    notifyFunc={options => this.onNotification(options)}
                  />
                </Col>
              </Row>
            )}

          {!this.props.customerIsEdit && (
            <Card body>
              <CardTitle style={{ fontWeight: 700 }}>
                Application Toggle
              </CardTitle>
              <CardText>
                {this.props.mode
                  ? "Showing Non-GST Records"
                  : "Hiding Non-GST Records"}
              </CardText>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    this.props.updateMode(false, options =>
                      this.onNotification(options)
                    );
                    this.props.retrieveCustomers();
                  }}
                  disabled={!this.props.mode}
                >
                  Hide Non-Tax
                </Button>
                <Button
                  onClick={() => {
                    this.props.updateMode(true, options =>
                      this.onNotification(options)
                    );
                    this.props.retrieveCustomers();
                  }}
                  disabled={this.props.mode}
                >
                  Show Non-Tax
                </Button>
              </ButtonGroup>
            </Card>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
    customerSelectedID: getUICustomerSelectedID,
    customerIsEdit: getUICustomerIsEdit,
    customer: getCustomer,
    mode: getMode
  }),
  {
    retrieveCustomers,
    retrieveMode,
    updateMode,
    onCancelOrderForm,
    selectCustomerSuper
  }
)(Customer);
