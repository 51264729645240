import {
  FETCH_TRIPS,
  SET_TRIPS,
  SET_TRIP,
  ERROR_TRIPS,
} from "../actions/trips_action";

const INITIAL_STATE = {
  trips: [],
  trip: {},
  loading: false,
  error: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TRIPS:
      return {
        ...state,
        loading: true,
      };
    case SET_TRIPS:
      return {
        ...state,
        trips: action.value,
        error: '',
        loading: false,
      };
    case SET_TRIP:
      return {
        ...state,
        trip: action.value,
        error: '',
        loading: false,
      };
    case ERROR_TRIPS:
      return {
        ...state,
        error: action.message,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;