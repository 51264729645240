import axios from "axios";
import {DJANGO_CORE_ADDR} from "../config";
import moment from "moment";
import history from "../history";
import { notifySuccess, notifyError } from '../components/NotificationOptions';

import {
  retrievePendingOrders,
} from "./orders_action";

import {
  getUser,
} from "./user_action";

import {
  getUIOrderSelectedID,
  getUICustomerSelectedID,
  onCancelOrderForm
} from "./ui_action";

export const FETCH_ORDER = "order/FETCH_ORDER";
export const SET_ORDER = "order/SET_ORDER";
export const ERROR_ORDER = "order/ERROR_ORDER";

export const getOrder = state => state.order;
export const getOrderLoading = state => state.order.loading;
export const getOrderError = state => state.order.error;

export const fetchOrder = () => ({
  type: FETCH_ORDER
});

export const setOrder = value => ({
  type: SET_ORDER,
  value
});

export const errorOrder = message => ({
  type: ERROR_ORDER,
  message
});

export const createOrder = (order, notifyFunc) => async (dispatch, getState) => {
  dispatch(fetchOrder());
  if (!order.name) {
    dispatch(errorOrder("Customer Name is required"));
  } else {
    try {
      const responsePostOrder = await axios.post(
        `${DJANGO_CORE_ADDR}/orders/create/`,
        Object.assign(
          {},
          order,
          {
            customer: getUICustomerSelectedID(getState()),
            active: 'N',
            lastUpdateUser: getUser(getState()).id,
            lastUpdateDate: moment().unix()
          }
        ),
        { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } }
      );
      console.log("Order created!", responsePostOrder);
      await notifyFunc(notifySuccess(`${order.name}: Order created successful. Pending orders are shown in the Dashboard.`));
      await dispatch(onCancelOrderForm());
      history.push("./dashboard");
    } catch (error) {
      console.error(error);
      dispatch(errorOrder("Some Error!"));
    }
  }
};

export const retrieveOrder = () => async (dispatch, getState) => {

  if(getUIOrderSelectedID(getState())) {
    dispatch(fetchOrder());
    try {
      console.log('WM: ', getUIOrderSelectedID(getState()));
      const responseGetOrder = await axios.get(`${DJANGO_CORE_ADDR}/orders/list/?id=${getUIOrderSelectedID(getState())}`, { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } });
      dispatch(setOrder(responseGetOrder.data));
    } catch (error) {
      console.error(error);
      dispatch(errorOrder("Some Error!"));
    }
  }

};

export const updateOrder = (order, notifyFunc) => async (dispatch, getState) => {
  try {
    const responsePutOrder = await axios.patch(
      `${DJANGO_CORE_ADDR}/orders/update/`,
      Object.assign(
        {},
        order,
        {
          lastUpdateUser: getUser(getState()).id,
          lastUpdateDate: moment().unix()
        }
        ),
      { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } }
    );

    console.log("Order edited!");
    await notifyFunc(notifySuccess(`${order.name}: Order update successful`));
    await dispatch(retrieveOrder(order.id));
    await dispatch(onCancelOrderForm());
  } catch (error) {
    console.error(error);
    dispatch(errorOrder("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};

export const removeOrder = (orderId, notifyFunc) => async (dispatch) => {
  try {
    const responseDeleteOrder = await axios.delete(
      `${DJANGO_CORE_ADDR}/orders/delete/?id=${orderId}`,
      { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } }
    );
    console.log("Order deleted!");
    notifyFunc(notifySuccess(`Order deletion successful`));
    dispatch(retrievePendingOrders());
  } catch (error) {
    console.error(error);
    dispatch(errorOrder("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};