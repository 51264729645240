import Dashboard from "../views/Dashboard/DashboardSales";
import CustomerPage from "../views/Dashboard/CustomerPage";
import OrderPage from "../views/Dashboard/OrderPage";
import SaleOrderPage from "../views/Dashboard/SalesOrderPage";
import DeliveryOrderPage from "../views/Dashboard/DeliveryOrderPage";
import ChangePassPage from "../views/Dashboard/UserManagePass";
import SetMenuPage from "../views/Dashboard/SetMenuPage";
import SetFoodPrepPage from "../views/Dashboard/SetFoodPrepPage";


const dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "design_app",
    component: Dashboard
  },
  {
    path: "/customer-page",
    name: "Customer Profile",
    icon: "business_briefcase-24",
    component: CustomerPage
  },
  {
    hide: true,
    path: "/order-page",
    name: "Order Details",
    icon: "files_paper",
    component: OrderPage
  },
  {
    path: "/salesorder-page",
    name: "Sales Order",
    icon: "shopping_delivery-fast",
    component: SaleOrderPage
  },
  {
    path: "/deliveryorder-page",
    name: "Delivery Order",
    icon: "files_box",
    component: DeliveryOrderPage
  },
  {
    path: "/setcuisine-page",
    name: "Cuisine Management",
    icon: "loader_gear",
    component: SetMenuPage
  },
  {
    path: "/setfoodprep-page",
    name: "Food Prep. Time",
    icon: "loader_gear",
    component: SetFoodPrepPage
  },
  {
    hide: true,
    path: "/changepass",
    component: ChangePassPage
  },
  { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];
export default dashRoutes;
