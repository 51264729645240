import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import { Header, Sidebar, Footer } from "../../components";
import { getRoutes } from "../../routes";

import { getUser, retrieveUserFromToken } from "../../actions/user_action";

let ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
      document.body.classList.toggle("perfect-scrollbar-on");
    }

    this.props.retrieveUserFromToken();
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.refs.mainPanel.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  render() {
    /*
    if (!this.props.user.logged_in) {
      return <Redirect to="./login"/>;
    }
    */
    return (
      <div className="wrapper">
        <Sidebar {...this.props} routes={getRoutes(this.props.user.role)} />
        <div className="main-panel" ref="mainPanel">
          <Header {...this.props} />
          <Switch>
            {getRoutes(this.props.user.role).map((prop, key) => {
              if (prop.redirect) {
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              }
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    createStructuredSelector({
      user: getUser
    }),
    {
      retrieveUserFromToken
    }
  )(Dashboard)
);
