import axios from "axios";
import moment from "moment";
import { DJANGO_CORE_ADDR } from "../config";

export const FETCH_CHART_DATA = "chartdata/FETCH_CHART_DATA";
export const SET_CHART_DATA = "chartdata/SET_CHART_DATA";
export const ERROR_CHART_DATA = "chartdata/ERROR_CHART_DATA";

export const getChartData = state => state.chartdata.chartdata;
export const getChartDataLoading = state => state.chartdata.loading;
export const getChartDataError = state => state.chartdata.error;

export const fetchChartData = () => ({
  type: FETCH_CHART_DATA
});

export const setChartData = value => ({
  type: SET_CHART_DATA,
  value
});

export const errorChartData = message => ({
  type: ERROR_CHART_DATA,
  message
});

export const retrieveChartSalesData = () => async dispatch => {
  dispatch(fetchChartData());
  try {
    const responseGetChart = await axios.get(
      `${DJANGO_CORE_ADDR}/chart/list/?month=${moment().month() +
        1}&year=${moment().year()}`,
      {
        headers: { Authorization: `JWT ${localStorage.getItem("token")}` }
      }
    );
    dispatch(setChartData(responseGetChart.data));
  } catch (error) {
    dispatch(errorChartData("Some Error!"));
  }
};
