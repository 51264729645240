import {
  FETCH_ORDER,
  SET_ORDER,
  ERROR_ORDER,
} from "../actions/order_action";

const INITIAL_STATE = {
  id: null,
  user: null,
  customer: null,
  trip: '',
  name: '',
  identification: '',
  email: '',
  contact: '',
  hpContact: '',
  address: '',
  country: '',
  postal: '',
  remarks: '',
  menu: '',
  rate: '',
  am_rate: '',
  pm_rate: '',
  am_pax: '',
  pm_pax: '',
  free_pax: 0,
  distance_scale: null,
  am_menu_A_monday: '',
  am_menu_B_monday: '',
  pm_menu_A_monday: '',
  pm_menu_B_monday: '',
  am_menu_A_tuesday: '',
  am_menu_B_tuesday: '',
  pm_menu_A_tuesday: '',
  pm_menu_B_tuesday: '',
  am_menu_A_wednesday: '',
  am_menu_B_wednesday: '',
  pm_menu_A_wednesday: '',
  pm_menu_B_wednesday: '',
  am_menu_A_thursday: '',
  am_menu_B_thursday: '',
  pm_menu_A_thursday: '',
  pm_menu_B_thursday: '',
  am_menu_A_friday: '',
  am_menu_B_friday: '',
  pm_menu_A_friday: '',
  pm_menu_B_friday: '',
  am_menu_A_saturday: '',
  am_menu_B_saturday: '',
  pm_menu_A_saturday: '',
  pm_menu_B_saturday: '',
  am_menu_A_sunday: '',
  am_menu_B_sunday: '',
  pm_menu_A_sunday: '',
  pm_menu_B_sunday: '',
  effectiveDate: null,
  expiryDate: null,
  active: 'N',
  terms: '',
  loading: false,
  error: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ORDER:
      return {
        ...state,
        loading: true,
      };
    case SET_ORDER:
      return {
        ...state,
        ...action.value,
        error: '',
        loading: false,
      };
    case ERROR_ORDER:
      return {
        ...state,
        error: action.message,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;