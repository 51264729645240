import React from "react";
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
  Row,
  Col,
  Progress, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
  Button,
  CardCategory,
  CardFooterStats,
  CustomDropdownMonth,
  CustomDropdownYear,
} from "..";

import { newOrder, selectOrder, onFilterMonth_order, onFilterYear_order } from "../../actions/ui_action";
import { getUser } from "../../actions/user_action";
import { getOrders } from "../../actions/orders_action";
import { getMenus } from "../../actions/menus_action";

class OrdersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.options = {
      sortIndicator: false,
    };
  }

  menuFormatter = (cell, row) =>
    cell.name;

  tripFormatter = (cell, row) =>
    cell.number;

  actionFormatter = (cell, row) => (
    <div>
          <React.Fragment>
            <Button
              id={`viewOrder${cell}`}
              round
              icon
              iconMini
              neutral
              color="info"
              onClick={() => {
                this.props.selectOrder(cell);
                this.props.history.push(`/order-page`);
              }}
            >
              <i className="now-ui-icons files_paper"/>
            </Button>
            <UncontrolledTooltip
              placement="left"
              target={`viewOrder${cell}`}
              delay={0}
            >
              View Order
            </UncontrolledTooltip>
          </React.Fragment>
    </div>
  );

  render() {
    return (
      <Card className="card-chart">
        <Progress animated value={100} style={!this.props.orders.loading ? {visibility: 'hidden'} : {visibility: 'visible'}}/>
        <CardHeader>
          <CardTitle className="title card-header-margin">{this.props.user.username === 'superadmin' ? 'All' : 'Active'} Orders</CardTitle>
          {this.props.user.username !== 'superadmin' &&
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-round btn-simple btn-icon"
                color="default"
              >
                <i className="now-ui-icons arrows-1_minimal-down"/>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => {
                    this.props.newOrder();
                    this.props.history.push(`/order-page`)
                  }}
                >
                  Add new Order
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          }
            <CardCategory>
              <Row>
                <Col xs={12} md={2}>
                  <CustomDropdownMonth retrieveFunc={value => this.props.onFilterMonth_order(value)}/>
                </Col>
                <Col xs={12} md={2}>
                  <CustomDropdownYear retrieveFunc={value => this.props.onFilterYear_order(value)}/>
                </Col>
              </Row>
            </CardCategory>
        </CardHeader>
        <CardBody style={{ paddingBottom: 0 }}>
            <BootstrapTable
              data={this.props.orders}
              options={this.options}
              tableHeaderClass="table-column-header"
              tableBodyClass="table-body-myclass"
              bordered={false}
              search={true}
              height="400"
              scrollTop={"Bottom"}
              key="current"
            >
              <TableHeaderColumn
                dataField="id"
                isKey
                hidden
                searchable={false}
                editable={false}
              >
                Order ID
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="name"
                dataSort
                editable={false}
                tdStyle={{ whiteSpace: 'normal' }}
              >
                Order
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="trip"
                dataFormat={this.tripFormatter}
                dataSort
                editable={false}
                headerAlign="center"
                dataAlign="center"
                width='60'
              >
                Trip
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="menu"
                dataFormat={this.menuFormatter}
                dataSort
                editable={false}
                headerAlign="center"
                dataAlign="center"
                width='120'
              >
                Cuisine
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="am_pax"
                dataSort
                searchable={false}
                editable={false}
                headerAlign="center"
                dataAlign="center"
                thStyle={{whiteSpace: 'normal'}}
                width='120'
              >
                Pax (a.m.)
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="pm_pax"
                dataSort
                searchable={false}
                editable={false}
                headerAlign="center"
                dataAlign="center"
                thStyle={{whiteSpace: 'normal'}}
                width='120'
              >
                Pax (p.m.)
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="rate"
                dataSort
                searchable={false}
                editable={false}
                headerAlign="center"
                dataAlign="center"
                thStyle={{whiteSpace: 'normal'}}
                width='90'
              >
                Rate ($)
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="id"
                dataFormat={this.actionFormatter}
                editable={false}
                headerAlign="right"
                dataAlign="right"
                width='90'
              />
            </BootstrapTable>
        </CardBody>
        <CardFooter className='card-footer-margin'>
          <CardFooterStats>
            {[
              {
                i: "now-ui-icons travel_info",
                t: `Total: ${this.props.orders.length} Orders`
              }
            ]}
          </CardFooterStats>
        </CardFooter>
      </Card>
    );
  }
}

export default withRouter(connect(
  createStructuredSelector({
    user: getUser,
    orders: getOrders,
    menus: getMenus,
  }),
  {
    newOrder,
    selectOrder,
    onFilterMonth_order,
    onFilterYear_order
  })(OrdersComponent));