import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
  Row,
  Col,
  Progress
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
  Button,
  CardCategory,
  CardFooterStats,
  CustomDropdownMonth,
  CustomDropdownYear,
  ThisMonthButton
} from "..";

import { getInvoices } from "../../actions/invoices_action";
import { getMenus } from "../../actions/menus_action";
import {
  getUIFilterYear,
  getUIFilterMonth,
  onFilterMonth_order,
  onFilterYear_order,
  onFilterYearMonth_order
} from "../../actions/ui_action";
import {
  retrieveInvoice,
  retrieveInvoiceGST
} from "../../actions/invoice_action";

class InvoicesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.options = {
      sortIndicator: false
    };
  }

  salesFormatter = (cell, row) => (cell ? `$${cell.toFixed(2)}` : `$0.00`);

  menuFormatter = (cell, row) => cell.name;

  actionFormatter = (cell, row) => (
    <div>
      <React.Fragment>
        <Button
          id={`viewInvoice${cell}`}
          round
          icon
          iconMini
          neutral
          color="info"
          onClick={() => {
            if (row.hasGST) {
              console.log("View GST Invoice: ", cell);
              this.props.retrieveInvoiceGST(cell, options =>
                this.props.notifyFunc(options)
              );
            } else {
              console.log("View Invoice: ", cell);
              this.props.retrieveInvoice(cell, options =>
                this.props.notifyFunc(options)
              );
            }
          }}
        >
          <i className="now-ui-icons files_paper" />
        </Button>
        <UncontrolledTooltip
          placement="left"
          target={`viewInvoice${cell}`}
          delay={0}
        >
          View Invoice
        </UncontrolledTooltip>
      </React.Fragment>
    </div>
  );

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.invoices.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">Invoices</CardTitle>
          <CardCategory>
            <Row>
              <Col xs={12} md={2}>
                <CustomDropdownMonth
                  retrieveFunc={value => this.props.onFilterMonth_order(value)}
                />
              </Col>
              <Col xs={12} md={2}>
                <CustomDropdownYear
                  retrieveFunc={value => this.props.onFilterYear_order(value)}
                />
              </Col>
              <Col xs={12} md={2}>
                <ThisMonthButton
                  retrieveFunc={(year, month) =>
                    this.props.onFilterYearMonth_order(year, month)
                  }
                />
              </Col>
            </Row>
          </CardCategory>
        </CardHeader>
        <CardBody style={{ paddingBottom: 0 }}>
          {this.props.filterYear < moment().year() ||
          (this.props.filterYear === moment().year() &&
            this.props.filterMonth <= moment().month() + 1) ? (
            <BootstrapTable
              data={this.props.invoices}
              options={this.options}
              tableHeaderClass="table-column-header"
              tableBodyClass="table-body-myclass"
              bordered={false}
              search={true}
              height="400"
              scrollTop={"Bottom"}
            >
              <TableHeaderColumn
                dataField="id"
                isKey
                hidden
                searchable={false}
                editable={false}
              >
                Invoice ID
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="name"
                dataSort
                editable={false}
                tdStyle={{ whiteSpace: "normal" }}
              >
                Invoice
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="menu"
                dataFormat={this.menuFormatter}
                dataSort
                editable={false}
                headerAlign="center"
                dataAlign="center"
                width="120"
              >
                Cuisine
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="am_packets"
                dataSort
                searchable={false}
                editable={false}
                headerAlign="center"
                dataAlign="center"
                thStyle={{ whiteSpace: "normal" }}
                width="150"
              >
                Packs (a.m.)
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="pm_packets"
                dataSort
                searchable={false}
                editable={false}
                headerAlign="center"
                dataAlign="center"
                thStyle={{ whiteSpace: "normal" }}
                width="150"
              >
                Packs (p.m.)
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="rate"
                dataSort
                searchable={false}
                editable={false}
                headerAlign="center"
                dataAlign="center"
                thStyle={{ whiteSpace: "normal" }}
                width="90"
              >
                Rate ($)
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="totalSales"
                dataFormat={this.salesFormatter}
                dataSort
                searchable={false}
                editable={false}
                headerAlign="center"
                dataAlign="center"
                thStyle={{ whiteSpace: "normal" }}
                width="120"
              >
                Sales ($)
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="id"
                dataFormat={this.actionFormatter}
                editable={false}
                headerAlign="right"
                dataAlign="right"
                width="90"
              />
            </BootstrapTable>
          ) : (
            <div>Selected Year / Month must be earlier than current Date</div>
          )}
        </CardBody>
        <CardFooter className="card-footer-margin">
          {(this.props.filterYear < moment().year() ||
            (this.props.filterYear === moment().year() &&
              this.props.filterMonth <= moment().month() + 1)) && (
            <CardFooterStats>
              {[
                {
                  i: "now-ui-icons travel_info",
                  t: `Total: ${this.props.invoices.length} Invoices`
                }
              ]}
            </CardFooterStats>
          )}
        </CardFooter>
      </Card>
    );
  }
}

export default connect(
  createStructuredSelector({
    invoices: getInvoices,
    menus: getMenus,
    filterYear: getUIFilterYear,
    filterMonth: getUIFilterMonth
  }),
  {
    onFilterMonth_order,
    onFilterYear_order,
    onFilterYearMonth_order,
    retrieveInvoice,
    retrieveInvoiceGST
  }
)(InvoicesComponent);
