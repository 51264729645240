import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";

import {
  getUIFilterMonth,
  getUIFilterYear,
  getUIFilterTrip,
  getUIFilterMenu
} from "./ui_action";
import { notifyError, notifySuccess } from "../components/NotificationOptions";

export const FETCH_SALESORDERS = "salesorders/FETCH_SALESORDERS";
export const SET_SALESORDERS = "salesorders/SET_SALESORDERS";
export const SET_SALESORDERS_EDIT = "salesorders/SET_SALESORDERS_EDIT";
export const ERROR_SALESORDERS = "salesorders/ERROR_SALESORDERS";

export const getSalesOrders = state => state.salesorders.sales;
export const getSalesEdits = state => state.salesorders.edits;
export const getSalesOrdersLoading = state => state.salesorders.loading;
export const getSalesOrdersError = state => state.salesorders.error;

export const fetchSalesOrders = () => ({
  type: FETCH_SALESORDERS
});

export const setSalesOrders = (sales, edits) => ({
  type: SET_SALESORDERS,
  sales,
  edits
});

export const setSalesOrdersEdit = (orderID, dayKey) => ({
  type: SET_SALESORDERS_EDIT,
  orderID,
  dayKey
});

export const errorSalesOrders = message => ({
  type: ERROR_SALESORDERS,
  message
});

export const editSaleOrdersState = (orderID, dayKey) => async dispatch => {
  dispatch(setSalesOrdersEdit(orderID, dayKey));
};

export const updateSaleOrder = (sale, notifyFunc) => async dispatch => {
  try {
    const responsePutSaleOrder = await axios.patch(
      `${DJANGO_CORE_ADDR}/sales/update/`,
      {
        id: sale.id,
        am_pax: sale.am_pax,
        pm_pax: sale.pm_pax
      },
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    console.log("Salesorder edited!");
    await notifyFunc(notifySuccess(`Updating Sales pax now...`));
    await dispatch(retrieveSalesOrders());
  } catch (error) {
    console.error(error);
    notifyFunc(notifyError("System error. Please try again later."));
  }
};

export const retrieveSalesOrders = () => async (dispatch, getState) => {
  dispatch(fetchSalesOrders());
  try {
    const tripParameter = getUIFilterTrip(getState())
      ? `&trip=${getUIFilterTrip(getState())}`
      : "";
    const menuParameter = getUIFilterMenu(getState())
      ? `&menu=${getUIFilterMenu(getState())}`
      : "";
    const responseGetSalesOrders = await axios.get(
      `${DJANGO_CORE_ADDR}/sales/list/?month=${getUIFilterMonth(
        getState()
      )}&year=${getUIFilterYear(getState())}${tripParameter}${menuParameter}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );

    const salesOrders = [];
    const salesEdits = { id: 0, key: 0 };

    responseGetSalesOrders.data.map(sale => {
      if (
        salesOrders.length === 0 ||
        sale.order.id !== salesOrders[salesOrders.length - 1].id
      ) {
        salesOrders.push(
          Object.assign(
            {},
            {
              id: sale.order.id,
              customer: sale.customer.name,
              order: sale.order.name,
              address:
                sale.order.address +
                (sale.order.postal && ` S${sale.order.postal}`),
              user: sale.customer.user.username,
              trip: sale.trip.number
            }
          )
        );
        //salesEdits[sale.order.id] = {};
      }
      salesOrders[salesOrders.length - 1][`pax_${sale.day}`] = sale;
      //salesEdits[sale.order.id][`edit_day_${sale.day}`] = false;
    });
    await dispatch(setSalesOrders(salesOrders, salesEdits));
  } catch (error) {
    console.error(error);
    await dispatch(errorSalesOrders("Some Error!"));
  }
};
