import React from "react";

const Index = (props) => (
  <div
    className={
      "panel-header " +
      (props.size !== undefined
        ? "panel-header-" + props.size
        : "")
    }
  >
    {props.content}
  </div>
);

export default Index;