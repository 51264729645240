import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";
import { notifySuccess, notifyError } from "../components/NotificationOptions";

import {
  getUIAccountSelectedID,
  getUIAccountSelectedGST,
  onCancelPaymentForm
} from "./ui_action";

import { retrievePayments } from "./payments_action";

export const FETCH_PAYMENT = "payment/FETCH_PAYMENT";
export const SET_PAYMENT = "payment/SET_PAYMENT";
export const ERROR_PAYMENT = "payment/ERROR_PAYMENT";

export const getPayment = state => state.payment;
export const getOutstandingSum = state =>
  state.payment.outstandings.debits.reduce(
    (accumulator, currentValue) => accumulator + currentValue.balance,
    0.0
  ) +
  state.payment.outstandings.invoices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.balance,
    0.0
  );
export const getPaymentLoading = state => state.payment.loading;
export const getPaymentError = state => state.payment.error;

export const fetchPayment = () => ({
  type: FETCH_PAYMENT
});

export const setPayment = value => ({
  type: SET_PAYMENT,
  value
});

export const errorPayment = message => ({
  type: ERROR_PAYMENT,
  message
});

export const createPayment = (payment, notifyFunc) => async (
  dispatch,
  getState
) => {
  dispatch(fetchPayment());
  if (!payment.description || !payment.amount) {
    dispatch(errorPayment("Fields are required"));
  } else {
    try {
      const responsePostPayment = await axios.post(
        `${DJANGO_CORE_ADDR}/payments/create/?gst=${getUIAccountSelectedGST(
          getState()
        )}`,
        Object.assign({}, payment, {
          customer: getUIAccountSelectedID(getState()),
          void: false
        }),
        { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
      );
      console.log("Payment Note created!", responsePostPayment);
      await notifyFunc(notifySuccess(`Payment created successfully.`));
      await dispatch(onCancelPaymentForm());
      await dispatch(
        retrievePayments(
          getUIAccountSelectedID(getState()),
          getUIAccountSelectedGST(getState())
        )
      );
    } catch (error) {
      console.error(error);
      dispatch(errorPayment("Some Error!"));
    }
  }
};

export const retrievePayment = paymentID => async (dispatch, getState) => {
  dispatch(fetchPayment());
  try {
    const responseGetPayment = await axios.get(
      `${DJANGO_CORE_ADDR}/payments/list/?id=${paymentID}&gst=${getUIAccountSelectedGST(
        getState()
      )}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    dispatch(setPayment(responseGetPayment.data.payment));
  } catch (error) {
    console.error(error);
    dispatch(errorPayment("Some Error!"));
  }
};

export const updatePayment = (payment, notifyFunc) => async (
  dispatch,
  getState
) => {
  try {
    const responsePutPayment = await axios.patch(
      `${DJANGO_CORE_ADDR}/payments/update/?gst=${getUIAccountSelectedGST(
        getState()
      )}`,
      {
        id: payment.id,
        description: payment.description
      },
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );

    console.log("Payment edited!");
    await notifyFunc(notifySuccess(`Payment updated successfully`));
    await dispatch(
      retrievePayments(
        getUIAccountSelectedID(getState()),
        getUIAccountSelectedGST(getState())
      )
    );
    await dispatch(onCancelPaymentForm());
  } catch (error) {
    console.error(error);
    dispatch(error("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};

export const removePayment = (paymentId, notifyFunc) => async (
  dispatch,
  getState
) => {
  try {
    const responseDeletePayment = await axios.delete(
      `${DJANGO_CORE_ADDR}/payments/delete/?gst=${getUIAccountSelectedGST(
        getState()
      )}&id=${paymentId}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    console.log("payment deleted!");
    await notifyFunc(notifySuccess(`payment deletion successful`));
    await dispatch(
      retrievePayments(
        getUIAccountSelectedID(getState()),
        getUIAccountSelectedGST(getState())
      )
    );
    await dispatch(onCancelPaymentForm());
  } catch (error) {
    console.error(error);
    dispatch(error("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};

export const retrieveOutstandings = () => async (dispatch, getState) => {
  dispatch(fetchPayment());
  try {
    const responseGetOutstandings = await axios.get(
      `${DJANGO_CORE_ADDR}/outstandings/list/?id=${getUIAccountSelectedID(
        getState()
      )}&gst=${getUIAccountSelectedGST(getState())}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );

    dispatch(
      setPayment({
        outstandings: responseGetOutstandings.data
      })
    );
  } catch (error) {
    console.error(error);
    dispatch(errorPayment("Some Error!"));
  }
};
