import React from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Row,
  Col,
  Progress,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Table
} from "reactstrap";
import {
  CardCategory,
  CardFooterStats,
  CustomDropdownTrip,
  CustomDropdownMenu
} from "..";
import {
  onFilterMenu_delivery,
  onFilterTrip_delivery
} from "../../actions/ui_action";
import { 
  getDeliveryOrders,
  getOrdersLoading 
} from "../../actions/orders_action";
import { getFoodPrep } from "../../actions/foodprep_action";
import { PRINTER_FILENAME } from "../../config";

class DeliveryOrdersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  constructSticker = (result, order, type) => {
    let getMoment;
    let todayDate;
    let today;
    let day;
    let shift;
    let foodtime;

    const time = type;

    if (moment().format("H") < 17) {
      getMoment = moment();
    } else {
      getMoment = moment().add(1, "days");
    }

    todayDate = getMoment.format("DD/MM/YYYY");
    today = getMoment.format("dddd").toLowerCase();
    day = getMoment.format("ddd").toUpperCase();
    shift = type.toUpperCase();
    foodtime =
      type.toUpperCase() === "AM"
        ? this.props.foodPrep.am_time
        : this.props.foodPrep.pm_time;

    let currentAPax = order[`${time}_menu_A_${today}`]["pax"];
    let currentBPax =
      order[`${time}_menu_A_${today}`]["pax"] === 0 &&
      order[`${time}_menu_B_${today}`]["pax"] === 0
        ? 0
        : order[`${time}_menu_B_${today}`]["pax"] + order["free_pax"];

    // Do not use currentAPax(Mutable). for-loop will go wrong
    for (
      let i = 0;
      i <
      Math.floor(
        parseInt(order[`${time}_menu_A_${today}`]["pax"]) /
          parseInt(order[`${time}_size`])
      );
      i++
    ) {
      result.push([
        order.customer,
        order.name,
        order[`${time}_menu_A_${today}`]["menuitem"],
        order[`${time}_menu_B_${today}`]["menuitem"],
        order[`${time}_size`],
        0,
        order.menu.slice(0, 1),
        order.trip,
        todayDate,
        day,
        shift,
        foodtime
      ]);
      currentAPax = currentAPax - order[`${time}_size`];
    }

    if (currentBPax > order[`${time}_size`]) {
      result.push([
        order.customer,
        order.name,
        order[`${time}_menu_A_${today}`]["menuitem"],
        order[`${time}_menu_B_${today}`]["menuitem"],
        currentAPax,
        order[`${time}_size`] - currentAPax,
        order.menu.slice(0, 1),
        order.trip,
        todayDate,
        day,
        shift,
        foodtime
      ]);
      currentBPax = currentBPax - (order[`${time}_size`] - currentAPax);
    } else {
      if (currentBPax > order[`${time}_size`] - currentAPax) {
        result.push([
          order.customer,
          order.name,
          order[`${time}_menu_A_${today}`]["menuitem"],
          order[`${time}_menu_B_${today}`]["menuitem"],
          currentAPax,
          order[`${time}_size`] - currentAPax,
          order.menu.slice(0, 1),
          order.trip,
          todayDate,
          day,
          shift,
          foodtime
        ]);
        currentBPax = currentBPax - (order[`${time}_size`] - currentAPax);
      } else {
        if (currentAPax > 0 || currentBPax > 0) {
          result.push([
            order.customer,
            order.name,
            order[`${time}_menu_A_${today}`]["menuitem"],
            order[`${time}_menu_B_${today}`]["menuitem"],
            currentAPax,
            currentBPax,
            order.menu.slice(0, 1),
            order.trip,
            todayDate,
            day,
            shift,
            foodtime
          ]);
          currentBPax = 0;
        }
      }
    }

    const checkB = currentBPax;
    // Do not use currentBPax(Mutable). for-loop will go wrong
    for (
      let i = 0;
      i < Math.floor(parseInt(checkB) / parseInt(order[`${time}_size`]));
      i++
    ) {
      result.push([
        order.customer,
        order.name,
        order[`${time}_menu_A_${today}`]["menuitem"],
        order[`${time}_menu_B_${today}`]["menuitem"],
        0,
        order[`${time}_size`],
        order.menu.slice(0, 1),
        order.trip,
        todayDate,
        day,
        shift,
        foodtime
      ]);
      currentBPax = currentBPax - order[`${time}_size`];
    }

    if (currentBPax > 0) {
      result.push([
        order.customer,
        order.name,
        order[`${time}_menu_A_${today}`]["menuitem"],
        order[`${time}_menu_B_${today}`]["menuitem"],
        0,
        currentBPax,
        order.menu.slice(0, 1),
        order.trip,
        todayDate,
        day,
        shift,
        foodtime
      ]);
    }
  };

  printOrderStickers = (id, type) => {
    if (this.props.orders.length !== 0 && id) {
      let printResult = [];
      const orderToPrint = this.props.orders.find(order => order.id === id);
      // console.log(`Today: ${today}, Time: ${time}, AM Bag: ${this.props.bag.am_size}, PM Bag: ${this.props.bag.pm_size} `);
      // console.log("Order to Print Obj: ", orderToPrint);
      // console.log(`Customer: ${orderToPrint.customer}, ItemA: ${orderToPrint[`${time}_menu_A_${today}`]['menuitem'].slice(0,-6)}, ItemB: ${orderToPrint[`${time}_menu_B_${today}`]['menuitem'].slice(0,-6)}, ItemAQty: ${orderToPrint[`${time}_menu_A_${today}`]['pax']}, ItemBQty: ${orderToPrint[`${time}_menu_B_${today}`]['pax']}, Cuisine: ${orderToPrint.menu.slice(0,1)}, Trip: ${orderToPrint.trip}`);
      this.constructSticker(printResult, orderToPrint, type);
      return printResult;
    }
    return [];
  };

  printAllOrdersStickers = type => {
    if (this.props.orders.length !== 0) {
      let printResult = [];
      this.props.orders.forEach(order => {
        if (order.allowPrint === "Y") {
          this.constructSticker(printResult, order, type);
        }
      });
      return printResult;
    }
    return [];
  };

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">
            Delivery Orders
          </CardTitle>
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-round btn-simple btn-icon"
              color="default"
            >
              <i className="now-ui-icons arrows-1_minimal-down" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <CSVLink
                  data={this.printAllOrdersStickers("am")}
                  filename={PRINTER_FILENAME}
                >
                  Print A.M. Orders
                </CSVLink>
              </DropdownItem>
              <DropdownItem>
                <CSVLink
                  data={this.printAllOrdersStickers("pm")}
                  filename={PRINTER_FILENAME}
                >
                  Print P.M. Orders
                </CSVLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <CardCategory>
            <Row>
              <Col xs={12} md={2}>
                <CustomDropdownTrip
                  retrieveFunc={value =>
                    this.props.onFilterTrip_delivery(value)
                  }
                />
              </Col>
              <Col xs={12} md={2}>
                <CustomDropdownMenu
                  retrieveFunc={value =>
                    this.props.onFilterMenu_delivery(value)
                  }
                />
              </Col>
            </Row>
          </CardCategory>
        </CardHeader>
        <CardBody>
          {this.props.orders.length !== 0 && (
            <Table
              responsive
              style={{ width: `${this.props.orders.length * 300 + 100}px` }}
            >
              <thead className="text-primary" style={{ fontSize: "16px" }}>
                <tr>
                  <th width={100} />
                  {this.props.orders.map(order => (
                    <th width={300} colSpan={4} key={`header_${order.id}`} />
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th
                    width={100}
                    colSpan={2}
                    className="table-freeze-leftheader"
                  >
                    <b>Agent</b>
                  </th>
                  {this.props.orders.map(order => (
                    <td
                      width={300}
                      colSpan={2}
                      key={`user_${order.id}`}
                      style={{
                        border: "1px solid #ccc",
                        textAlign: "center"
                      }}
                    >
                      {order.user}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th
                    width={100}
                    colSpan={2}
                    className="table-freeze-leftheader"
                  >
                    <b>Customer</b>
                  </th>
                  {this.props.orders.map(order => (
                    <td
                      width={300}
                      colSpan={2}
                      key={`customer_${order.id}`}
                      style={{
                        border: "1px solid #ccc",
                        textAlign: "center"
                      }}
                    >
                      {order.customer}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th
                    width={100}
                    colSpan={2}
                    className="table-freeze-leftheader"
                  >
                    <b>Order</b>
                  </th>
                  {this.props.orders.map(order => (
                    <td
                      width={300}
                      colSpan={2}
                      key={`order_${order.id}`}
                      style={{
                        border: "1px solid #ccc",
                        textAlign: "center"
                      }}
                    >
                      {order.name}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td
                    width={100}
                    colSpan={2}
                    className="table-freeze-leftheader"
                  >
                    <b>Trip</b>
                  </td>
                  {this.props.orders.map(order => (
                    <td
                      width={300}
                      colSpan={2}
                      key={`trip_${order.id}`}
                      style={{
                        border: "1px solid #ccc",
                        textAlign: "center"
                      }}
                    >
                      {order.trip}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th
                    width={100}
                    colSpan={2}
                    className="table-freeze-leftheader"
                  >
                    <b>Cuisine</b>
                  </th>
                  {this.props.orders.map(order => (
                    <td
                      width={300}
                      colSpan={2}
                      key={`menu_${order.id}`}
                      style={{
                        border: "1px solid #ccc",
                        textAlign: "center"
                      }}
                    >
                      {order.menu}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th
                    width={100}
                    colSpan={2}
                    className="table-freeze-leftheader"
                  >
                    <b>Free Pax</b>
                  </th>
                  {this.props.orders.map(order => (
                    <td
                      width={300}
                      colSpan={2}
                      key={`free_${order.id}`}
                      style={{
                        border: "1px solid #ccc",
                        textAlign: "center"
                      }}
                    >
                      {order.free_pax}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th
                    width={100}
                    colSpan={2}
                    className="table-freeze-leftheader"
                    style={{ borderRight: "1px solid #ccc" }}
                  >
                    <b>Menu</b>
                  </th>
                  {this.props.orders.map(order => (
                    <React.Fragment key={`${order.id}_MenuLabel`}>
                      <td
                        width={150}
                        key={`Menu`}
                        style={{ textAlign: "center" }}
                      >
                        <b>A</b>
                      </td>
                      <td
                        width={150}
                        key={`AltMenu`}
                        style={{ textAlign: "center" }}
                      >
                        <b>B</b>
                      </td>
                    </React.Fragment>
                  ))}
                </tr>
                {//Array.from([Array(7).keys()]).map(day => (
                Array.from(moment().format("H") < 17 ? [0] : [1]).map(day => (
                  <React.Fragment
                    key={moment()
                      .add(day, "days")
                      .format("dddd")}
                  >
                    <tr
                      key={`${moment()
                        .add(day, "days")
                        .format("dddd")}_am_value`}
                      style={{ lineHeight: "10px" }}
                    >
                      <td
                        rowSpan={4}
                        width={50}
                        className="table-freeze-leftheader"
                      >
                        {moment()
                          .add(day, "days")
                          .format("DD MMM")}
                        <br />
                        <br />
                        {moment()
                          .add(day, "days")
                          .format("dddd")}
                      </td>
                      <td
                        rowSpan={2}
                        width={50}
                        className="table-freeze-leftheader"
                        style={{
                          textAlign: "center",
                          left: "99px",
                          borderRight: "1px solid #ccc"
                        }}
                      >
                        a.m.
                      </td>
                      {this.props.orders.map(order => {
                        const dayKey = moment()
                          .add(day, "days")
                          .format("dddd")
                          .toLowerCase();
                        let amB = "";

                        if (
                          `am_menu_A_${dayKey}` in order &&
                          `am_menu_B_${dayKey}` in order &&
                          `pm_menu_A_${dayKey}` in order &&
                          `pm_menu_B_${dayKey}` in order
                        ) {
                          if (
                            order[`am_menu_A_${dayKey}`]["pax"] === 0 &&
                            order[`am_menu_B_${dayKey}`]["pax"] === 0
                          ) {
                            amB = "0";
                          } else {
                            amB =
                              order[`am_menu_B_${dayKey}`]["pax"] +
                              order["free_pax"];
                          }
                        }

                        return (
                          <React.Fragment
                            key={`${moment()
                              .add(day, "days")
                              .format("dddd")}_order${order.id}`}
                          >
                            <td
                              width={150}
                              style={{
                                textAlign: "center",
                                borderBottom: "none"
                              }}
                            >
                              {`am_menu_A_${dayKey}` in order
                                ? order[`am_menu_A_${dayKey}`]["pax"]
                                : ""}
                            </td>
                            <td
                              width={150}
                              style={{
                                borderRight: "1px solid #ccc",
                                textAlign: "center",
                                borderBottom: "none"
                              }}
                            >
                              {amB}
                            </td>
                          </React.Fragment>
                        );
                      })}
                    </tr>
                    <tr
                      key={`${moment()
                        .add(day, "days")
                        .format("dddd")}_am_label`}
                      style={{ lineHeight: "10px" }}
                    >
                      {this.props.orders.map(order => {
                        const dayKey = moment()
                          .add(day, "days")
                          .format("dddd")
                          .toLowerCase();

                        return (
                          <React.Fragment
                            key={`${moment()
                              .add(day, "days")
                              .format("dddd")}_order${order.id}`}
                          >
                            <td width={150} style={{ textAlign: "center" }}>
                              {`am_menu_A_${dayKey}` in order
                                ? order[`am_menu_A_${dayKey}`]["menuitem"]
                                : ""}
                            </td>
                            <td
                              width={150}
                              style={{
                                borderRight: "1px solid #ccc",
                                textAlign: "center"
                              }}
                            >
                              {`am_menu_B_${dayKey}` in order
                                ? order[`am_menu_B_${dayKey}`]["menuitem"]
                                : ""}
                            </td>
                          </React.Fragment>
                        );
                      })}
                    </tr>
                    <tr
                      key={`${moment()
                        .add(day, "days")
                        .format("dddd")}_pm_value`}
                      style={{ lineHeight: "10px" }}
                    >
                      <td
                        rowSpan={2}
                        width={50}
                        className="table-freeze-leftheader"
                        style={{
                          textAlign: "center",
                          left: "99px",
                          borderRight: "1px solid #ccc"
                        }}
                      >
                        p.m.
                      </td>
                      {this.props.orders.map(order => {
                        const dayKey = moment()
                          .add(day, "days")
                          .format("dddd")
                          .toLowerCase();

                        let pmB = "";

                        if (
                          `am_menu_A_${dayKey}` in order &&
                          `am_menu_B_${dayKey}` in order &&
                          `pm_menu_A_${dayKey}` in order &&
                          `pm_menu_B_${dayKey}` in order
                        ) {
                          if (
                            order[`pm_menu_A_${dayKey}`]["pax"] === 0 &&
                            order[`pm_menu_B_${dayKey}`]["pax"] === 0
                          ) {
                            pmB = "0";
                          } else {
                            pmB =
                              order[`pm_menu_B_${dayKey}`]["pax"] +
                              order["free_pax"];
                          }
                        }

                        return (
                          <React.Fragment
                            key={`${moment()
                              .add(day, "days")
                              .format("dddd")}_order${order.id}`}
                          >
                            <td
                              width={150}
                              style={{
                                textAlign: "center",
                                borderBottom: "none"
                              }}
                            >
                              {`pm_menu_A_${dayKey}` in order
                                ? order[`pm_menu_A_${dayKey}`]["pax"]
                                : ""}
                            </td>
                            <td
                              width={150}
                              style={{
                                borderRight: "1px solid #ccc",
                                textAlign: "center",
                                borderBottom: "none"
                              }}
                            >
                              {pmB}
                            </td>
                          </React.Fragment>
                        );
                      })}
                    </tr>
                    <tr
                      key={`${moment()
                        .add(day, "days")
                        .format("dddd")}_pm_label`}
                      style={{ lineHeight: "10px" }}
                    >
                      {this.props.orders.map(order => {
                        const dayKey = moment()
                          .add(day, "days")
                          .format("dddd")
                          .toLowerCase();

                        return (
                          <React.Fragment
                            key={`${moment()
                              .add(day, "days")
                              .format("dddd")}_order${order.id}`}
                          >
                            <td width={150} style={{ textAlign: "center" }}>
                              {`pm_menu_A_${dayKey}` in order
                                ? order[`pm_menu_A_${dayKey}`]["menuitem"]
                                : ""}
                            </td>
                            <td
                              width={150}
                              style={{
                                borderRight: "1px solid #ccc",
                                textAlign: "center"
                              }}
                            >
                              {`pm_menu_B_${dayKey}` in order
                                ? order[`pm_menu_B_${dayKey}`]["menuitem"]
                                : ""}
                            </td>
                          </React.Fragment>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                ))}
                <tr>
                  <th
                    width={100}
                    rowSpan={2}
                    className="table-freeze-leftheader"
                  >
                    <b>Print</b>
                  </th>
                  <td
                    width={50}
                    className="table-freeze-leftheader"
                    style={{
                      textAlign: "center",
                      left: "99px",
                      borderRight: "1px solid #ccc"
                    }}
                  >
                    a.m.
                  </td>
                  {this.props.orders.map(order => (
                    <td
                      width={300}
                      colSpan={2}
                      key={`print_am_${order.id}`}
                      style={{
                        borderRight: "1px solid #ccc",
                        textAlign: "center"
                      }}
                    >
                      {order.allowPrint === "Y" ? (
                        <CSVLink
                          data={this.printOrderStickers(order.id, "am")}
                          filename={PRINTER_FILENAME}
                        >
                          <i
                            className="now-ui-icons shopping_credit-card"
                            style={{ cursor: "pointer" }}
                          />
                        </CSVLink>
                      ) : (
                        ""
                      )}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td
                    width={50}
                    className="table-freeze-leftheader"
                    style={{
                      textAlign: "center",
                      left: "99px",
                      borderRight: "1px solid #ccc"
                    }}
                  >
                    p.m.
                  </td>
                  {this.props.orders.map(order => (
                    <td
                      width={300}
                      colSpan={2}
                      key={`print_pm_${order.id}`}
                      style={{
                        borderRight: "1px solid #ccc",
                        textAlign: "center"
                      }}
                    >
                      {order.allowPrint === "Y" ? (
                        <CSVLink
                          data={this.printOrderStickers(order.id, "pm")}
                          filename={PRINTER_FILENAME}
                        >
                          <i
                            className="now-ui-icons shopping_credit-card"
                            style={{ cursor: "pointer" }}
                          />
                        </CSVLink>
                      ) : (
                        ""
                      )}
                    </td>
                  ))}
                </tr>
              </tbody>
            </Table>
          )}
        </CardBody>
        <CardFooter className="card-footer-margin">
          <CardFooterStats>
            {[
              {
                i: "now-ui-icons travel_info",
                t: `Total: ${this.props.orders.length} Orders`
              }
            ]}
          </CardFooterStats>
        </CardFooter>
      </Card>
    );
  }
}

export default withRouter(
  connect(
    createStructuredSelector({
      orders: getDeliveryOrders,
      loading: getOrdersLoading,
      foodPrep: getFoodPrep
    }),
    {
      onFilterMenu_delivery,
      onFilterTrip_delivery
    }
  )(DeliveryOrdersComponent)
);
