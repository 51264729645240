import moment from "moment";

import { retrieveUser } from "./selecteduser_action";
import { retrieveCustomer } from "./customer_action";
import { retrieveAccounts } from "./customers_action";
import { retrieveCurrentOrders, retrieveDeliveryOrders } from "./orders_action";
import { retrieveInvoices, retrieveAccountInvoices } from "./invoices_action";
import {
  retrieveCustomersSummaryOrders,
  retrieveCustomersSummaryInvoices,
} from "./customers_summary_action";
import { retrieveSalesOrders } from "./salesorders_action";
import { retrieveMenu } from "./menus_action";
import { retrieveMenuItem } from "./menuitems_action";
import { retrieveTrip } from "./trips_action";

import { retrieveDebit } from "./debit_action";
import { retrieveDebits } from "./debits_action";

import { retrieveCredit } from "./credit_action";
import { retrieveCredits } from "./credits_action";

import { retrievePayment, retrieveOutstandings } from "./payment_action";
import { retrievePayments } from "./payments_action";

export const SET_UI_USER_IS_EDITPASS = "ui/SET_UI_USER_IS_EDITPASS";
export const SET_UI_USER_IS_EDIT = "ui/SET_UI_USER_IS_EDIT";
export const SET_UI_USER_IS_NEW = "ui/SET_UI_USER_IS_NEW";
export const SET_UI_USER_SELECTED_ID = "ui/SET_UI_USER_SELECTED_ID";
export const SET_UI_FILTER_MONTH = "ui/SET_FILTER_MONTH";
export const SET_UI_FILTER_YEAR = "ui/SET_FILTER_YEAR";
export const SET_UI_FILTER_MENU = "ui/SET_FILTER_MENU";
export const SET_UI_FILTER_TRIP = "ui/SET_FILTER_TRIP";
export const SET_UI_FILTER_SETMENU = "ui/SET_FILTER_SETMENU";
export const SET_UI_FILTER_SETMENUITEM = "ui/SET_FILTER_SETMENUITEM";
export const SET_UI_FILTER_SETTRIP = "ui/SET_UI_FILTER_SETTRIP";
export const SET_UI_FILTER_AGENT = "ui/SET_UI_FILTER_AGENT";
export const SET_UI_CUSTOMER_IS_EDIT = "ui/SET_UI_CUSTOMER_IS_EDIT";
export const SET_UI_CUSTOMER_IS_NEW = "ui/SET_UI_CUSTOMER_IS_NEW";
export const SET_UI_CUSTOMER_SELECTED_ID = "ui/SET_UI_CUSTOMER_SELECTED_ID";
export const SET_UI_ORDER_IS_EDIT = "ui/SET_UI_ORDER_IS_EDIT";
export const SET_UI_ORDER_IS_NEW = "ui/SET_UI_ORDER_IS_NEW";
export const SET_UI_ORDER_SELECTED_ID = "ui/SET_UI_ORDER_SELECTED_ID";
export const SET_UI_MENU_IS_EDIT = "ui/SET_UI_MENU_IS_EDIT";
export const SET_UI_MENU_IS_NEW = "ui/SET_UI_MENU_IS_NEW";
export const SET_UI_MENUITEM_IS_EDIT = "ui/SET_UI_MENUITEM_IS_EDIT";
export const SET_UI_MENUITEM_IS_NEW = "ui/SET_UI_MENUITEM_IS_NEW";
export const SET_UI_TRIP_IS_EDIT = "ui/SET_UI_TRIP_IS_EDIT";
export const SET_UI_TRIP_IS_NEW = "ui/SET_UI_TRIP_IS_NEW";
export const SET_UI_RATE_IS_EDIT = "ui/SET_UI_RATE_IS_EDIT";
export const SET_UI_BAG_IS_EDIT = "ui/SET_UI_BAG_IS_EDIT";
export const SET_UI_FOODPREP_IS_EDIT = "ui/SET_UI_FOODPREP_IS_EDIT";
export const SET_UI_ACCOUNT_SELECTED_GST = "ui/SET_UI_ACCOUNT_SELECTED_GST";
export const SET_UI_ACCOUNT_SELECTED_ID = "ui/SET_UI_ACCOUNT_SELECTED_ID";
export const SET_UI_DEBIT_SELECTED_ID = "ui/SET_UI_DEBIT_SELECTED_ID";
export const SET_UI_DEBIT_IS_EDIT = "ui/SET_UI_DEBIT_IS_EDIT";
export const SET_UI_DEBIT_IS_NEW = "ui/SET_UI_DEBIT_IS_NEW";
export const SET_UI_CREDIT_SELECTED_ID = "ui/SET_UI_CREDIT_SELECTED_ID";
export const SET_UI_CREDIT_IS_EDIT = "ui/SET_UI_CREDIT_IS_EDIT";
export const SET_UI_CREDIT_IS_NEW = "ui/SET_UI_CREDIT_IS_NEW";
export const SET_UI_PAYMENT_SELECTED_ID = "ui/SET_UI_PAYMENT_SELECTED_ID";
export const SET_UI_PAYMENT_IS_EDIT = "ui/SET_UI_PAYMENT_IS_EDIT";
export const SET_UI_PAYMENT_IS_NEW = "ui/SET_UI_PAYMENT_IS_NEW";

export const getUIUserIsEditPass = (state) => state.ui.userIsEditPass;
export const getUIUserIsEdit = (state) => state.ui.userIsEdit;
export const getUIUserIsNew = (state) => state.ui.userIsNew;
export const getUIUserSelectedID = (state) => state.ui.userSelectedID;
export const getUIFilterMonth = (state) => state.ui.filterMonth;
export const getUIFilterYear = (state) => state.ui.filterYear;
export const getUIFilterTrip = (state) => state.ui.filterTrip;
export const getUIFilterMenu = (state) => state.ui.filterMenu;
export const getUIFilterSetMenu = (state) => state.ui.filterSetMenu;
export const getUIFilterSetMenuItem = (state) => state.ui.filterSetMenuItem;
export const getUIFilterSetTrip = (state) => state.ui.filterSetTrip;
export const getUIFilterAgent = (state) => state.ui.filterAgent;
export const getUICustomerIsEdit = (state) => state.ui.customerIsEdit;
export const getUICustomerIsNew = (state) => state.ui.customerIsNew;
export const getUICustomerSelectedID = (state) => state.ui.customerSelectedID;
export const getUIOrderIsEdit = (state) => state.ui.orderIsEdit;
export const getUIOrderIsNew = (state) => state.ui.orderIsNew;
export const getUIOrderSelectedID = (state) => state.ui.orderSelectedID;
export const getUIMenuIsEdit = (state) => state.ui.menuIsEdit;
export const getUIMenuIsNew = (state) => state.ui.menuIsNew;
export const getUIMenuItemIsEdit = (state) => state.ui.menuItemIsEdit;
export const getUIMenuItemIsNew = (state) => state.ui.menuItemIsNew;
export const getUITripIsEdit = (state) => state.ui.tripIsEdit;
export const getUITripIsNew = (state) => state.ui.tripIsNew;
export const getUIRateIsEdit = (state) => state.ui.rateIsEdit;
export const getUIBagIsEdit = (state) => state.ui.bagIsEdit;
export const getUIFoodPrepIsEdit = (state) => state.ui.foodPrepIsEdit;
export const getUIAccountSelectedGST = (state) => state.ui.accountSelectedGST;
export const getUIAccountSelectedID = (state) => state.ui.accountSelectedID;
export const getUIDebitSelectedID = (state) => state.ui.debitSelectedID;
export const getUIDebitIsEdit = (state) => state.ui.debitIsEdit;
export const getUIDebitIsNew = (state) => state.ui.debitIsNew;
export const getUICreditSelectedID = (state) => state.ui.creditSelectedID;
export const getUICreditIsEdit = (state) => state.ui.creditIsEdit;
export const getUICreditIsNew = (state) => state.ui.creditIsNew;
export const getUIPaymentSelectedID = (state) => state.ui.paymentSelectedID;
export const getUIPaymentIsEdit = (state) => state.ui.paymentIsEdit;
export const getUIPaymentIsNew = (state) => state.ui.paymentIsNew;

export const setUIUserIsEditPass = (value) => ({
  type: SET_UI_USER_IS_EDITPASS,
  value,
});

export const setUIUserIsEdit = (value) => ({
  type: SET_UI_USER_IS_EDIT,
  value,
});

export const setUIUserIsNew = (value) => ({
  type: SET_UI_USER_IS_NEW,
  value,
});

export const setUIUserSelectedID = (value) => ({
  type: SET_UI_USER_SELECTED_ID,
  value,
});

export const setUIFilterMonth = (value) => ({
  type: SET_UI_FILTER_MONTH,
  value,
});

export const setUIFilterYear = (value) => ({
  type: SET_UI_FILTER_YEAR,
  value,
});

export const setUIFilterTrip = (value) => ({
  type: SET_UI_FILTER_TRIP,
  value,
});

export const setUIFilterMenu = (value) => ({
  type: SET_UI_FILTER_MENU,
  value,
});

export const setUIFilterSetMenu = (value) => ({
  type: SET_UI_FILTER_SETMENU,
  value,
});

export const setUIFilterSetMenuItem = (value) => ({
  type: SET_UI_FILTER_SETMENUITEM,
  value,
});

export const setUIFilterSetTrip = (value) => ({
  type: SET_UI_FILTER_SETTRIP,
  value,
});

export const setUIFilterAgent = (value) => ({
  type: SET_UI_FILTER_AGENT,
  value,
});

export const setUICustomerIsEdit = (value) => ({
  type: SET_UI_CUSTOMER_IS_EDIT,
  value,
});

export const setUICustomerIsNew = (value) => ({
  type: SET_UI_CUSTOMER_IS_NEW,
  value,
});

export const setUICustomerSelectedID = (value) => ({
  type: SET_UI_CUSTOMER_SELECTED_ID,
  value,
});

export const setUIOrderIsEdit = (value) => ({
  type: SET_UI_ORDER_IS_EDIT,
  value,
});

export const setUIOrderIsNew = (value) => ({
  type: SET_UI_ORDER_IS_NEW,
  value,
});

export const setUIOrderSelectedID = (value) => ({
  type: SET_UI_ORDER_SELECTED_ID,
  value,
});

export const setUIMenuIsEdit = (value) => ({
  type: SET_UI_MENU_IS_EDIT,
  value,
});

export const setUIMenuIsNew = (value) => ({
  type: SET_UI_MENU_IS_NEW,
  value,
});

export const setUIMenuItemIsEdit = (value) => ({
  type: SET_UI_MENUITEM_IS_EDIT,
  value,
});

export const setUIMenuItemIsNew = (value) => ({
  type: SET_UI_MENUITEM_IS_NEW,
  value,
});

export const setUITripIsEdit = (value) => ({
  type: SET_UI_TRIP_IS_EDIT,
  value,
});

export const setUITripIsNew = (value) => ({
  type: SET_UI_TRIP_IS_NEW,
  value,
});

export const setUIRateIsEdit = (value) => ({
  type: SET_UI_RATE_IS_EDIT,
  value,
});

export const setUIBagIsEdit = (value) => ({
  type: SET_UI_BAG_IS_EDIT,
  value,
});

export const setUIFoodPrepIsEdit = (value) => ({
  type: SET_UI_FOODPREP_IS_EDIT,
  value,
});

export const setUIAccountSelectedGST = (value) => ({
  type: SET_UI_ACCOUNT_SELECTED_GST,
  value,
});

export const setUIAccountSelectedID = (value) => ({
  type: SET_UI_ACCOUNT_SELECTED_ID,
  value,
});

export const setUIDebitIsEdit = (value) => ({
  type: SET_UI_DEBIT_IS_EDIT,
  value,
});

export const setUIDebitIsNew = (value) => ({
  type: SET_UI_DEBIT_IS_NEW,
  value,
});

export const setUIDebitSelectedID = (value) => ({
  type: SET_UI_DEBIT_SELECTED_ID,
  value,
});

export const setUICreditIsEdit = (value) => ({
  type: SET_UI_CREDIT_IS_EDIT,
  value,
});

export const setUICreditIsNew = (value) => ({
  type: SET_UI_CREDIT_IS_NEW,
  value,
});

export const setUICreditSelectedID = (value) => ({
  type: SET_UI_CREDIT_SELECTED_ID,
  value,
});

export const setUIPaymentIsEdit = (value) => ({
  type: SET_UI_PAYMENT_IS_EDIT,
  value,
});

export const setUIPaymentIsNew = (value) => ({
  type: SET_UI_PAYMENT_IS_NEW,
  value,
});

export const setUIPaymentSelectedID = (value) => ({
  type: SET_UI_PAYMENT_SELECTED_ID,
  value,
});

export const onFilterYearMonth_summary = (year, month) => async (dispatch) => {
  await dispatch(setUIFilterMonth(month));
  await dispatch(setUIFilterYear(year));
  dispatch(retrieveCustomersSummaryOrders());
};
export const onFilterYearMonth_order = (year, month) => async (dispatch) => {
  await dispatch(setUIFilterMonth(month));
  await dispatch(setUIFilterYear(year));
  dispatch(retrieveCurrentOrders());
};
export const onFilterYearMonth_sales = (year, month) => async (dispatch) => {
  await dispatch(setUIFilterMonth(month));
  await dispatch(setUIFilterYear(year));
  dispatch(retrieveSalesOrders());
};

export const onFilterMonth_summary = (value) => async (dispatch, getState) => {
  await dispatch(setUIFilterMonth(value));

  if (
    getUIFilterYear(getState()) === moment().year() &&
    getUIFilterMonth(getState()) === moment().month() + 1
  ) {
    dispatch(retrieveCustomersSummaryOrders());
  } else {
    dispatch(retrieveCustomersSummaryInvoices());
  }
};
export const onFilterMonth_order = (value) => async (dispatch, getState) => {
  await dispatch(setUIFilterMonth(value));

  if (
    getUIFilterYear(getState()) === moment().year() &&
    getUIFilterMonth(getState()) === moment().month() + 1
  ) {
    dispatch(retrieveCurrentOrders());
  } else {
    dispatch(retrieveInvoices());
  }
};
export const onFilterMonth_sales = (value) => async (dispatch) => {
  await dispatch(setUIFilterMonth(value));
  await dispatch(retrieveSalesOrders());
};

export const onFilterYear_summary = (value) => async (dispatch, getState) => {
  await dispatch(setUIFilterYear(value));

  if (
    getUIFilterYear(getState()) === moment().year() &&
    getUIFilterMonth(getState()) === moment().month() + 1
  ) {
    dispatch(retrieveCustomersSummaryOrders());
  } else {
    dispatch(retrieveCustomersSummaryInvoices());
  }
};
export const onFilterYear_order = (value) => async (dispatch, getState) => {
  await dispatch(setUIFilterYear(value));

  if (
    getUIFilterYear(getState()) === moment().year() &&
    getUIFilterMonth(getState()) === moment().month() + 1
  ) {
    dispatch(retrieveCurrentOrders());
  } else {
    dispatch(retrieveInvoices());
  }
};
export const onFilterYear_sales = (value) => async (dispatch) => {
  await dispatch(setUIFilterYear(value));
  await dispatch(retrieveSalesOrders());
};

export const onFilterTrip_sales = (value) => async (dispatch) => {
  await dispatch(setUIFilterTrip(value));
  await dispatch(retrieveSalesOrders());
};

export const onFilterMenu_sales = (value) => async (dispatch) => {
  await dispatch(setUIFilterMenu(value));
  await dispatch(retrieveSalesOrders());
};

export const onFilterTrip_delivery = (value) => async (dispatch) => {
  await dispatch(setUIFilterTrip(value));
  await dispatch(retrieveDeliveryOrders());
};

export const onFilterMenu_delivery = (value) => async (dispatch) => {
  await dispatch(setUIFilterMenu(value));
  await dispatch(retrieveDeliveryOrders());
};

export const onFilterSetMenu = (value) => async (dispatch) => {
  await dispatch(setUIFilterSetMenu(value));
  await dispatch(retrieveMenu());
};

export const onFilterSetMenuItem = (value) => async (dispatch) => {
  await dispatch(setUIFilterSetMenuItem(value));
  await dispatch(retrieveMenuItem());
};

export const onFilterSetTrip = (value) => async (dispatch) => {
  await dispatch(setUIFilterSetTrip(value));
  await dispatch(retrieveTrip());
};

export const onFilterAgent = (value) => async (dispatch) => {
  await dispatch(setUIAccountSelectedGST(null));
  await dispatch(setUIAccountSelectedID(null));
  await dispatch(setUIFilterAgent(value));
  await dispatch(retrieveAccounts());
};

export const onFilterMonth_accounts = (value) => async (dispatch, getState) => {
  await dispatch(setUIFilterMonth(value));
  dispatch(retrieveAccountInvoices());
  dispatch(
    retrieveDebits(
      getUIAccountSelectedID(getState()),
      getUIAccountSelectedGST(getState())
    )
  );
  dispatch(
    retrieveCredits(
      getUIAccountSelectedID(getState()),
      getUIAccountSelectedGST(getState())
    )
  );
  dispatch(
    retrievePayments(
      getUIAccountSelectedID(getState()),
      getUIAccountSelectedGST(getState())
    )
  );
};

export const onFilterYear_accounts = (value) => async (dispatch, getState) => {
  await dispatch(setUIFilterYear(value));
  dispatch(retrieveAccountInvoices());
  dispatch(
    retrieveDebits(
      getUIAccountSelectedID(getState()),
      getUIAccountSelectedGST(getState())
    )
  );
  dispatch(
    retrieveCredits(
      getUIAccountSelectedID(getState()),
      getUIAccountSelectedGST(getState())
    )
  );
  dispatch(
    retrievePayments(
      getUIAccountSelectedID(getState()),
      getUIAccountSelectedGST(getState())
    )
  );
};

export const onFilterYearMonth_accounts = (year, month) => async (
  dispatch,
  getState
) => {
  await dispatch(setUIFilterMonth(month));
  await dispatch(setUIFilterYear(year));
  dispatch(retrieveAccountInvoices());
  dispatch(
    retrieveDebits(
      getUIAccountSelectedID(getState()),
      getUIAccountSelectedGST(getState())
    )
  );
  dispatch(
    retrieveCredits(
      getUIAccountSelectedID(getState()),
      getUIAccountSelectedGST(getState())
    )
  );
  dispatch(
    retrievePayments(
      getUIAccountSelectedID(getState()),
      getUIAccountSelectedGST(getState())
    )
  );
};

export const selectUser = (value) => async (dispatch) => {
  await dispatch(setUIUserSelectedID(value));
  dispatch(retrieveUser(value));
};
export const editUserPass = () => async (dispatch) => {
  dispatch(setUIUserIsEditPass(true));
};
export const editUser = () => async (dispatch) => {
  dispatch(setUIUserIsEdit(true));
};
export const newUser = () => async (dispatch) => {
  dispatch(setUIUserIsNew(true));
};
export const onCancelUserForm = () => async (dispatch) => {
  dispatch(setUIUserIsEditPass(false));
  dispatch(setUIUserIsEdit(false));
  dispatch(setUIUserIsNew(false));
};

export const selectCustomer = (value) => async (dispatch, getState) => {
  await dispatch(setUICustomerSelectedID(value));
  dispatch(retrieveCustomer(value));

  if (
    getUIFilterYear(getState()) === moment().year() &&
    getUIFilterMonth(getState()) === moment().month() + 1
  ) {
    dispatch(retrieveCurrentOrders());
    // dispatch(retrieveCustomersSummaryOrders());
  } else {
    dispatch(retrieveInvoices());
    // dispatch(retrieveCustomersSummaryInvoices());
  }
};

export const selectCustomerSuper = (value) => async (dispatch) => {
  await dispatch(setUICustomerSelectedID(value));
  dispatch(retrieveCustomer(value));
};

export const editCustomer = () => async (dispatch) => {
  dispatch(setUICustomerIsEdit(true));
};
export const newCustomer = () => async (dispatch) => {
  dispatch(setUICustomerIsNew(true));
};
export const onCancelCustomerForm = () => async (dispatch) => {
  dispatch(setUICustomerIsEdit(false));
  dispatch(setUICustomerIsNew(false));
};

export const selectOrder = (value) => async (dispatch) => {
  await dispatch(setUIOrderSelectedID(value));
};
export const editOrder = () => async (dispatch) => {
  dispatch(setUIOrderIsEdit(true));
};
export const newOrder = () => async (dispatch) => {
  dispatch(setUIOrderIsNew(true));
};
export const onCancelOrderForm = () => async (dispatch) => {
  dispatch(setUIOrderIsEdit(false));
  dispatch(setUIOrderIsNew(false));
};

export const editMenu = () => async (dispatch) => {
  dispatch(setUIMenuIsEdit(true));
};
export const newMenu = () => async (dispatch) => {
  dispatch(setUIMenuIsNew(true));
};
export const onCancelMenuForm = () => async (dispatch) => {
  dispatch(setUIMenuIsEdit(false));
  dispatch(setUIMenuIsNew(false));
};

export const editMenuItem = () => async (dispatch) => {
  dispatch(setUIMenuItemIsEdit(true));
};
export const newMenuItem = () => async (dispatch) => {
  dispatch(setUIMenuItemIsNew(true));
};
export const onCancelMenuItemForm = () => async (dispatch) => {
  dispatch(setUIMenuItemIsEdit(false));
  dispatch(setUIMenuItemIsNew(false));
};

export const editTrip = () => async (dispatch) => {
  dispatch(setUITripIsEdit(true));
};
export const newTrip = () => async (dispatch) => {
  dispatch(setUITripIsNew(true));
};
export const onCancelTripForm = () => async (dispatch) => {
  dispatch(setUITripIsEdit(false));
  dispatch(setUITripIsNew(false));
};

export const editRate = () => async (dispatch) => {
  dispatch(setUIRateIsEdit(true));
};

export const onCancelRateForm = () => async (dispatch) => {
  dispatch(setUIRateIsEdit(false));
};

export const editBag = () => async (dispatch) => {
  dispatch(setUIBagIsEdit(true));
};

export const onCancelBagForm = () => async (dispatch) => {
  dispatch(setUIBagIsEdit(false));
};

export const editFoodPrep = () => async (dispatch) => {
  dispatch(setUIFoodPrepIsEdit(true));
};

export const onCancelFoodPrepForm = () => async (dispatch) => {
  dispatch(setUIFoodPrepIsEdit(false));
};

export const selectAccount = (value, isGST) => async (dispatch) => {
  await dispatch(setUIAccountSelectedGST(isGST));
  await dispatch(setUIAccountSelectedID(value));
  await dispatch(setUICustomerSelectedID(value));
  await dispatch(retrieveCustomer());
  await dispatch(retrieveAccountInvoices());
  await dispatch(retrieveDebits(value, isGST));
  await dispatch(retrieveCredits(value, isGST));
  await dispatch(retrievePayments(value, isGST));
  await dispatch(retrieveOutstandings());
};

export const selectDebit = (value) => async (dispatch) => {
  await dispatch(setUIDebitSelectedID(value));
  await dispatch(retrieveDebit(value));
  dispatch(setUIDebitIsEdit(true));
};
export const newDebit = () => async (dispatch) => {
  dispatch(setUIDebitIsNew(true));
};
export const onCancelDebitForm = () => async (dispatch) => {
  dispatch(setUIDebitIsEdit(false));
  dispatch(setUIDebitIsNew(false));
};

export const selectCredit = (value) => async (dispatch) => {
  await dispatch(setUICreditSelectedID(value));
  await dispatch(retrieveCredit(value));
  dispatch(setUICreditIsEdit(true));
};
export const newCredit = () => async (dispatch) => {
  dispatch(setUICreditIsNew(true));
};
export const onCancelCreditForm = () => async (dispatch) => {
  dispatch(setUICreditIsEdit(false));
  dispatch(setUICreditIsNew(false));
};

export const selectPayment = (value) => async (dispatch) => {
  await dispatch(setUIPaymentSelectedID(value));
  await dispatch(retrievePayment(value));
  dispatch(setUIPaymentIsEdit(true));
};
export const newPayment = () => async (dispatch) => {
  dispatch(setUIPaymentIsNew(true));
};
export const onCancelPaymentForm = () => async (dispatch) => {
  dispatch(setUIPaymentIsEdit(false));
  dispatch(setUIPaymentIsNew(false));
};
