import {
  FETCH_MENUITEMS,
  SET_MENUITEMS,
  SET_MENUITEM,
  ERROR_MENUITEMS,
} from "../actions/menuitems_action";

const INITIAL_STATE = {
  items: [],
  item: {},
  loading: false,
  error: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MENUITEMS:
      return {
        ...state,
        loading: true,
      };
    case SET_MENUITEMS:
      return {
        ...state,
        items: action.value,
        error: '',
        loading: false,
      };
    case SET_MENUITEM:
      return {
        ...state,
        item: action.value,
        error: '',
        loading: false,
      };
    case ERROR_MENUITEMS:
      return {
        ...state,
        error: action.message,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;