import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";
import { notifyError, notifySuccess } from "../components/NotificationOptions";
import {
  onCancelRateForm,
} from "./ui_action";

export const FETCH_RATES = "rates/FETCH_RATES";
export const SET_RATE = "rates/SET_RATE";
export const ERROR_RATES = "rates/ERROR_RATES";

export const getRate = state => state.rates.rate;
export const getRatesLoading = state => state.rates.loading;
export const getRatesError = state => state.rates.error;

export const fetchRates = () => ({
  type: FETCH_RATES
});

export const setRate  = value => ({
  type: SET_RATE,
  value
});

export const errorRates  = message => ({
  type: ERROR_RATES,
  message
});

export const retrieveRate = () => async (dispatch) => {
  dispatch(fetchRates());
  try {
    const responseGetRate = await axios.get(`${DJANGO_CORE_ADDR}/rates/list/?id=1`,
      { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } });
    dispatch(setRate(responseGetRate.data));
  } catch (error) {
    console.error(error);
    dispatch(errorRates("Some Error!"));
  }
};

export const updateRate = (rate, notifyFunc) => async (dispatch) => {
  try {
    const responsePutRate = await axios.patch(
      `${DJANGO_CORE_ADDR}/rates/update/`,
      Object.assign(
        {},
        rate
      ),
      { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } }
    );
    console.log("Rates edited!");
    await notifyFunc(notifySuccess(`Rates update successful`));
    await dispatch(retrieveRate());
    await dispatch(onCancelRateForm());
  } catch (error) {
    console.error(error);
    dispatch(errorRates("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};