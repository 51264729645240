import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";
import { notifyError, notifySuccess } from "../components/NotificationOptions";

import { retrieveCustomers } from "./customers_action";

export const FETCH_MODE = "mode/FETCH_MODE";
export const SET_MODE = "mode/SET_MODE";
export const ERROR_MODE = "mode/ERROR_MODE";

export const getMode = state => state.mode.non_gst;

export const fetchMode = () => ({
  type: FETCH_MODE
});

export const setMode = value => ({
  type: SET_MODE,
  value
});

export const errorMode = message => ({
  type: ERROR_MODE,
  message
});

export const retrieveMode = () => async dispatch => {
  dispatch(fetchMode());
  try {
    const responseGetMode = await axios.get(`${DJANGO_CORE_ADDR}/mode/list/`, {
      headers: { Authorization: `JWT ${localStorage.getItem("token")}` }
    });
    dispatch(setMode(responseGetMode.data.non_gst));
  } catch (error) {
    console.error(error);
    dispatch(errorMode("Some Error!"));
  }
};

export const updateMode = (mode, notifyFunc) => async dispatch => {
  try {
    const responsePutMode = await axios.patch(
      `${DJANGO_CORE_ADDR}/mode/update/`,
      { non_gst: mode },
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    console.log("Mode edited!");
    await notifyFunc(notifySuccess(`Mode update successful`));
    await dispatch(retrieveMode());
    await dispatch(retrieveCustomers());
  } catch (error) {
    console.error(error);
    dispatch(errorMode("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};
