import React from "react";
import NotificationAlert from 'react-notification-alert';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  Row,
  Col,
} from "reactstrap";

import {
  PanelHeader,
  CustomerComponent,
  OrderFormComponent,
} from "../../components";

import {
  getUICustomerSelectedID,
  getUIOrderSelectedID,
  getUIOrderIsEdit,
  getUIOrderIsNew,
} from '../../actions/ui_action'

import {
  retrieveCustomer,
  getCustomer
} from '../../actions/customer_action';

import {
  retrieveOrder,
  getOrder
} from '../../actions/order_action';

import { retrieveUsers } from '../../actions/users_action';
import { retrieveMenus } from '../../actions/menus_action';
import { retrieveMenuItems } from '../../actions/menuitems_action';
import { retrieveTrips } from '../../actions/trips_action';

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.notifyRef = React.createRef();
  }

  componentDidMount() {
    this.props.retrieveCustomer();
    this.props.retrieveOrder();
    this.props.retrieveUsers();
    this.props.retrieveMenus();
    this.props.retrieveMenuItems();
    this.props.retrieveTrips();
  }

  onNotification = options => this.notifyRef.current.notificationAlert(options);

  render() {
    return (
      <div>
        <NotificationAlert ref={this.notifyRef} />
        <PanelHeader size="sm" />
        <div className="content">

          {((this.props.customerSelectedID && this.props.customer.id === this.props.customerSelectedID) && !(this.props.orderIsEdit || this.props.orderIsNew)) && (
            <Row>
              <Col xs={12} md={12}>
                <CustomerComponent />
              </Col>
            </Row>
          )}

          {((this.props.orderSelectedID && this.props.orderSelectedID === this.props.order.id) || this.props.orderIsNew) && (
              <Row>
                <Col xs={12} md={12}>
                  <OrderFormComponent
                    key={this.props.orderIsNew || this.props.orderIsEdit ? 0 : this.props.orderSelectedID}
                    notifyFunc={(options) => this.onNotification(options)}
                  />
                </Col>
              </Row>
            )}
        </div>
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
    customerSelectedID: getUICustomerSelectedID,
    customer: getCustomer,
    orderSelectedID: getUIOrderSelectedID,
    orderIsEdit: getUIOrderIsEdit,
    orderIsNew: getUIOrderIsNew,
    order: getOrder,
  }),
  {
    retrieveCustomer,
    retrieveOrder,
    retrieveUsers,
    retrieveMenus,
    retrieveMenuItems,
    retrieveTrips,
  })(Order);