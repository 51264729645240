import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";
import { getUIFilterMonth, getUIFilterYear } from "./ui_action";

export const FETCH_DEBITS = "debits/FETCH_DEBITS";
export const SET_DEBITS = "debits/SET_DEBITS";
export const ERROR_DEBITS = "debits/ERROR_DEBITS";

export const getDebits = state => state.debits.debits;
export const getDebitsSum = state =>
  state.debits.debits.reduce(
    (accumulator, currentValue) => accumulator + currentValue.amount,
    0.0
  );
export const getDebitsLoading = state => state.debits.loading;
export const getDebitsError = state => state.debits.error;

export const fetchDebits = () => ({
  type: FETCH_DEBITS
});

export const setDebits = value => ({
  type: SET_DEBITS,
  value
});

export const errorDebits = message => ({
  type: ERROR_DEBITS,
  message
});

export const retrieveDebits = (accountID, isGST) => async (
  dispatch,
  getState
) => {
  if (accountID && isGST) {
    dispatch(fetchDebits());
    try {
      const responseGetDebits = await axios.get(
        `${DJANGO_CORE_ADDR}/debits/list/summary/?id=${accountID}&gst=${isGST}&year=${getUIFilterYear(
          getState()
        )}&month=${getUIFilterMonth(getState())}`,
        { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
      );
      dispatch(setDebits(responseGetDebits.data));
    } catch (error) {
      console.error(error);
      dispatch(errorDebits("Some Error!"));
    }
  }
};
