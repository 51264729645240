import axios from "axios";
import { DJANGO_CORE_ADDR }  from "../config";
import { notifyError, notifySuccess } from "../components/NotificationOptions";
import {
  getUIFilterSetMenuItem,
  onCancelMenuItemForm,
  setUIFilterSetMenuItem
} from "./ui_action";

export const FETCH_MENUITEMS = "menuitems/FETCH_MENUITEMS";
export const SET_MENUITEMS = "menuitems/SET_MENUITEMS";
export const SET_MENUITEM = "menuitems/SET_MENUITEM";
export const ERROR_MENUITEMS = "menuitems/ERROR_MENUITEMS";

export const getMenuItems = state => state.menuitems.items;
export const getMenuItem = state => state.menuitems.item;
export const getMenuItemsLoading = state => state.menuitems.loading;
export const getMenuItemsError = state => state.menuitems.error;

export const fetchMenuItems = () => ({
  type: FETCH_MENUITEMS
});

export const setMenuItems = value => ({
  type: SET_MENUITEMS,
  value
});

export const setMenuItem = value => ({
  type: SET_MENUITEM,
  value
});

export const errorMenuItems  = message => ({
  type: ERROR_MENUITEMS,
  message
});

export const retrieveMenuItems = () => async (dispatch) => {
  dispatch(fetchMenuItems());
  try {
    const responseGetMenuItems = await axios.get(`${DJANGO_CORE_ADDR}/menuitems/list/`, { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } });
    dispatch(setMenuItems(responseGetMenuItems.data));
  } catch (error) {
    console.error(error);
    dispatch(errorMenuItems("Some Error!"));
  }
};

export const createMenuItem = (item, notifyFunc) => async (dispatch, getState) => {
  dispatch(fetchMenuItems());
  if (!item.name) {
    dispatch(errorMenuItems("Menu item name is required"));
  } else {
    try {
        const responsePostMenuItem = await axios.post(
          `${DJANGO_CORE_ADDR}/menuitems/create/`,
          Object.assign(
            {},
            item
          ),
          { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } }
        );
        console.log("Menu Item created!");
        notifyFunc(notifySuccess(`${item.name}: Menu Item creation successful`));
        await dispatch(retrieveMenuItems());
        await dispatch(onCancelMenuItemForm());
    } catch (error) {
      console.error(error);
      dispatch(errorMenuItems("Some Error!"));
      notifyFunc(notifyError("System error. Please try again later."));
    }
  }
};

export const retrieveMenuItem = () => async (dispatch, getState) => {
  dispatch(fetchMenuItems());
  try {
    const responseGetMenuItem = await axios.get(`${DJANGO_CORE_ADDR}/menuitems/list/?id=${getUIFilterSetMenuItem(getState())}`,
      { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } });
    if(responseGetMenuItem.data.length === 0) {
      const responseGetMenuItems = getMenuItems(getState());
      dispatch(setMenuItem(responseGetMenuItems[0]));
    } else {
      dispatch(setMenuItem(responseGetMenuItem.data));
    }
  } catch (error) {
    console.error(error);
    dispatch(errorMenuItems("Some Error!"));
  }
};

export const updateMenuItem = (item, notifyFunc) => async (dispatch) => {
  try {
    const responsePutMenuItem = await axios.patch(
      `${DJANGO_CORE_ADDR}/menuitems/update/`,
      Object.assign(
        {},
        item
      ),
      { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } }
    );
    console.log("Menu Item edited!");
    await notifyFunc(notifySuccess(`${item.name}: Menu Item update successful`));
    await dispatch(retrieveMenuItems());
    await dispatch(retrieveMenuItem());
    await dispatch(onCancelMenuItemForm());
  } catch (error) {
    console.error(error);
    dispatch(errorMenuItems("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};

export const removeMenuItem = (menuItemId, notifyFunc) => async (dispatch, getState) => {
  try {
    const responseDeleteMenuItem = await axios.delete(
      `${DJANGO_CORE_ADDR}/menuitems/delete/?id=${menuItemId}`,
      { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } }
    );
    console.log("Menu Item deleted!");
    await notifyFunc(notifySuccess(`Menu Item deletion successful`));
    await dispatch(setUIFilterSetMenuItem(getMenuItems(getState())[0].id));
    await dispatch(retrieveMenuItems());
    await dispatch(retrieveMenuItem());
    await dispatch(onCancelMenuItemForm());
  } catch (error) {
    console.error(error);
    dispatch(errorMenuItems("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};