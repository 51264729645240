import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  CardFooter
} from "reactstrap";

import {
  Button,
  CardCategory,
  CustomDropdownMenuItem,
  CustomDropdownSetMenu,
  FormInputs
} from "../";

import {
  getMenu,
  createMenu,
  updateMenu,
  removeMenu
} from "../../actions/menus_action";

import {
  getUIFilterSetMenu,
  getUIMenuIsNew,
  getUIMenuIsEdit,
  editMenu,
  newMenu,
  onCancelMenuForm,
  onFilterSetMenu
} from "../../actions/ui_action";

class MenuComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.menuIsNew
      ? {
          name: "",
          am_menu_A_monday: 1,
          am_menu_B_monday: 1,
          pm_menu_A_monday: 1,
          pm_menu_B_monday: 1,
          am_menu_A_tuesday: 1,
          am_menu_B_tuesday: 1,
          pm_menu_A_tuesday: 1,
          pm_menu_B_tuesday: 1,
          am_menu_A_wednesday: 1,
          am_menu_B_wednesday: 1,
          pm_menu_A_wednesday: 1,
          pm_menu_B_wednesday: 1,
          am_menu_A_thursday: 1,
          am_menu_B_thursday: 1,
          pm_menu_A_thursday: 1,
          pm_menu_B_thursday: 1,
          am_menu_A_friday: 1,
          am_menu_B_friday: 1,
          pm_menu_A_friday: 1,
          pm_menu_B_friday: 1,
          am_menu_A_saturday: 1,
          am_menu_B_saturday: 1,
          pm_menu_A_saturday: 1,
          pm_menu_B_saturday: 1,
          am_menu_A_sunday: 1,
          am_menu_B_sunday: 1,
          pm_menu_A_sunday: 1,
          pm_menu_B_sunday: 1,
          am_size: 1,
          pm_size: 1
        }
      : {
          ...this.props.menu,
          am_menu_A_monday:
            this.props.menu.am_menu_A_monday &&
            this.props.menu.am_menu_A_monday.id,
          am_menu_B_monday:
            this.props.menu.am_menu_B_monday &&
            this.props.menu.am_menu_B_monday.id,
          pm_menu_A_monday:
            this.props.menu.pm_menu_A_monday &&
            this.props.menu.pm_menu_A_monday.id,
          pm_menu_B_monday:
            this.props.menu.pm_menu_B_monday &&
            this.props.menu.pm_menu_B_monday.id,
          am_menu_A_tuesday:
            this.props.menu.am_menu_A_tuesday &&
            this.props.menu.am_menu_A_tuesday.id,
          am_menu_B_tuesday:
            this.props.menu.am_menu_B_tuesday &&
            this.props.menu.am_menu_B_tuesday.id,
          pm_menu_A_tuesday:
            this.props.menu.pm_menu_A_tuesday &&
            this.props.menu.pm_menu_A_tuesday.id,
          pm_menu_B_tuesday:
            this.props.menu.pm_menu_B_tuesday &&
            this.props.menu.pm_menu_B_tuesday.id,
          am_menu_A_wednesday:
            this.props.menu.am_menu_A_wednesday &&
            this.props.menu.am_menu_A_wednesday.id,
          am_menu_B_wednesday:
            this.props.menu.am_menu_B_wednesday &&
            this.props.menu.am_menu_B_wednesday.id,
          pm_menu_A_wednesday:
            this.props.menu.pm_menu_A_wednesday &&
            this.props.menu.pm_menu_A_wednesday.id,
          pm_menu_B_wednesday:
            this.props.menu.pm_menu_B_wednesday &&
            this.props.menu.pm_menu_B_wednesday.id,
          am_menu_A_thursday:
            this.props.menu.am_menu_A_thursday &&
            this.props.menu.am_menu_A_thursday.id,
          am_menu_B_thursday:
            this.props.menu.am_menu_B_thursday &&
            this.props.menu.am_menu_B_thursday.id,
          pm_menu_A_thursday:
            this.props.menu.pm_menu_A_thursday &&
            this.props.menu.pm_menu_A_thursday.id,
          pm_menu_B_thursday:
            this.props.menu.pm_menu_B_thursday &&
            this.props.menu.pm_menu_B_thursday.id,
          am_menu_A_friday:
            this.props.menu.am_menu_A_friday &&
            this.props.menu.am_menu_A_friday.id,
          am_menu_B_friday:
            this.props.menu.am_menu_B_friday &&
            this.props.menu.am_menu_B_friday.id,
          pm_menu_A_friday:
            this.props.menu.pm_menu_A_friday &&
            this.props.menu.pm_menu_A_friday.id,
          pm_menu_B_friday:
            this.props.menu.pm_menu_B_friday &&
            this.props.menu.pm_menu_B_friday.id,
          am_menu_A_saturday:
            this.props.menu.am_menu_A_saturday &&
            this.props.menu.am_menu_A_saturday.id,
          am_menu_B_saturday:
            this.props.menu.am_menu_B_saturday &&
            this.props.menu.am_menu_B_saturday.id,
          pm_menu_A_saturday:
            this.props.menu.pm_menu_A_saturday &&
            this.props.menu.pm_menu_A_saturday.id,
          pm_menu_B_saturday:
            this.props.menu.pm_menu_B_saturday &&
            this.props.menu.pm_menu_B_saturday.id,
          am_menu_A_sunday:
            this.props.menu.am_menu_A_sunday &&
            this.props.menu.am_menu_A_sunday.id,
          am_menu_B_sunday:
            this.props.menu.am_menu_B_sunday &&
            this.props.menu.am_menu_B_sunday.id,
          pm_menu_A_sunday:
            this.props.menu.pm_menu_A_sunday &&
            this.props.menu.pm_menu_A_sunday.id,
          pm_menu_B_sunday:
            this.props.menu.pm_menu_B_sunday &&
            this.props.menu.pm_menu_B_sunday.id
        };
  }

  render() {
    return (
      <Card className="card-chart">
        <CardHeader>
          <h5 className="title">Cuisine</h5>
          {!this.props.menuIsNew && (
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-round btn-simple btn-icon"
                color="default"
              >
                <i className="now-ui-icons arrows-1_minimal-down" />
              </DropdownToggle>
              {!this.props.menuIsEdit ? (
                <DropdownMenu right>
                  <DropdownItem onClick={() => this.props.editMenu()}>
                    Edit
                  </DropdownItem>
                  <DropdownItem onClick={() => this.props.newMenu()}>
                    Add new Cuisine
                  </DropdownItem>
                </DropdownMenu>
              ) : (
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() =>
                      this.props.removeMenu(this.state.id, options =>
                        this.props.notifyFunc(options)
                      )
                    }
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              )}
            </UncontrolledDropdown>
          )}
          {!(this.props.menuIsEdit || this.props.menuIsNew) && (
            <CardCategory>
              <Row>
                <Col xs={12} md={2}>
                  <CustomDropdownSetMenu />
                </Col>
              </Row>
            </CardCategory>
          )}
        </CardHeader>
        <CardBody>
          <form>
            {(this.props.menuIsEdit || this.props.menuIsNew) && (
              <FormInputs
                ncols={["col-md-12 pr-3"]}
                proprieties={[
                  {
                    label: "Cuisine Name",
                    inputProps: {
                      type: "text",
                      value: this.state.name,
                      onChange: event =>
                        this.setState({ name: event.target.value }),
                      style: this.state.name
                        ? null
                        : { border: "2px solid red" }
                    }
                  }
                ]}
              />
            )}
            <FormInputs
              ncols={["col-md-6 pr-3", "col-md-6 pr-3"]}
              proprieties={[
                {
                  label: "Bag Size (A.M.)",
                  inputProps: {
                    type: "number",
                    disabled: !(this.props.menuIsEdit || this.props.menuIsNew),
                    value: this.state.am_size,
                    onChange: event =>
                      this.setState({
                        am_size: event.target.value
                          ? parseFloat(event.target.value)
                          : ""
                      }),
                    style: this.state.am_size
                      ? null
                      : { border: "2px solid red" }
                  }
                },
                {
                  label: "Bag Size (P.M.)",
                  inputProps: {
                    type: "number",
                    disabled: !(this.props.menuIsEdit || this.props.menuIsNew),
                    value: this.state.pm_size,
                    onChange: event =>
                      this.setState({
                        pm_size: event.target.value
                          ? parseFloat(event.target.value)
                          : ""
                      }),
                    style: this.state.pm_size
                      ? null
                      : { border: "2px solid red" }
                  }
                }
              ]}
            />
          </form>
          <Table responsive>
            <thead className="text-primary" style={{ fontSize: "12px" }}>
              <tr>
                <th>Day</th>
                <th colSpan="2">Menu A</th>
                <th colSpan="2">Menu B</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td />
                <td>A.M.</td>
                <td>P.M.</td>
                <td>A.M.</td>
                <td>P.M.</td>
              </tr>
              {moment.weekdays().map(day => (
                <tr key={day}>
                  <td>{day}</td>
                  <td>
                    <CustomDropdownMenuItem
                      value={this.state[`am_menu_A_${day.toLowerCase()}`]}
                      disable={!(this.props.menuIsNew || this.props.menuIsEdit)}
                      retrieveFunc={value =>
                        this.setState({
                          [`am_menu_A_${day.toLowerCase()}`]: value
                        })
                      }
                    />
                  </td>
                  <td>
                    <CustomDropdownMenuItem
                      value={this.state[`pm_menu_A_${day.toLowerCase()}`]}
                      disable={!(this.props.menuIsNew || this.props.menuIsEdit)}
                      retrieveFunc={value =>
                        this.setState({
                          [`pm_menu_A_${day.toLowerCase()}`]: value
                        })
                      }
                    />
                  </td>
                  <td>
                    <CustomDropdownMenuItem
                      value={this.state[`am_menu_B_${day.toLowerCase()}`]}
                      disable={!(this.props.menuIsNew || this.props.menuIsEdit)}
                      retrieveFunc={value =>
                        this.setState({
                          [`am_menu_B_${day.toLowerCase()}`]: value
                        })
                      }
                    />
                  </td>
                  <td>
                    <CustomDropdownMenuItem
                      value={this.state[`pm_menu_B_${day.toLowerCase()}`]}
                      disable={!(this.props.menuIsNew || this.props.menuIsEdit)}
                      retrieveFunc={value =>
                        this.setState({
                          [`pm_menu_B_${day.toLowerCase()}`]: value
                        })
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
        {(this.props.menuIsEdit || this.props.menuIsNew) && (
          <CardFooter className="card-footer-buttons-padding">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Button
                color="primary"
                style={{ width: "20%" }}
                onClick={
                  this.props.menuIsEdit
                    ? () =>
                        this.props.updateMenu(this.state, options =>
                          this.props.notifyFunc(options)
                        )
                    : () =>
                        this.props.createMenu(this.state, options =>
                          this.props.notifyFunc(options)
                        )
                }
              >
                Save
              </Button>
              <Button
                color="secondary"
                style={{ width: "75%" }}
                onClick={() => this.props.onCancelMenuForm()}
              >
                Cancel
              </Button>
            </div>
          </CardFooter>
        )}
      </Card>
    );
  }
}

export default withRouter(
  connect(
    createStructuredSelector({
      menu: getMenu,
      filterSetMenu: getUIFilterSetMenu,
      menuIsNew: getUIMenuIsNew,
      menuIsEdit: getUIMenuIsEdit
    }),
    {
      onFilterSetMenu,
      onCancelMenuForm,
      editMenu,
      newMenu,
      createMenu,
      updateMenu,
      removeMenu
    }
  )(MenuComponent)
);
