const DJANGO_CORE_ADDR = "https://api.iso-delight.app/core";
const DJANGO_TOKEN_ADDR =
  "https://api.iso-delight.app/token-auth/";
// const DJANGO_CORE_ADDR = "http://localhost:8000/core";
// const DJANGO_TOKEN_ADDR = "http://localhost:8000/token-auth/";
const APPLICATION_FIRST_YEAR = 2018;
const PRINTER_FILENAME = "isoprint.csv";

// export default backendAddr;
export {
  DJANGO_CORE_ADDR,
  DJANGO_TOKEN_ADDR,
  APPLICATION_FIRST_YEAR,
  PRINTER_FILENAME,
};
