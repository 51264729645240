import React from "react";
import moment from "moment";
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledTooltip, CardFooter, CardTitle, Progress
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button, CardFooterStats } from "..";
import { getPendingOrders, getOrdersLoading } from "../../actions/orders_action";
import { getCustomers } from "../../actions/customers_action";
import { removeOrder } from "../../actions/order_action";
import { selectCustomer, selectOrder } from "../../actions/ui_action";

class PendingOrdersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.options = {
      sortIndicator: false,
    };
  }

  customerFormatter = (cell, row) =>
    cell.name;

  dateFormatter = (cell, row) =>
    cell ? moment.unix(cell).format("DD/MM/YYYY") : null;

  actionFormatter = (cell, row) => (
    <div>
      {
          <React.Fragment>
            <Button
              id={`viewOrder${cell}`}
              round
              icon
              iconMini
              neutral
              color="info"
              onClick={() => {
                this.props.selectCustomer(row.customer.id);
                this.props.selectOrder(cell);
                this.props.history.push(`/order-page`);
              }}
            >
              <i className="now-ui-icons files_paper"/>
            </Button>
            <Button
              id={`removeOrder${cell}`}
              round
              icon
              iconMini
              neutral
              color="danger"
              onClick={() => {
                this.props.removeOrder(cell, (options) => this.props.notifyFunc(options));
              }}
            >
              <i className="now-ui-icons ui-1_simple-remove"/>
            </Button>
            <UncontrolledTooltip
              placement="left"
              target={`viewOrder${cell}`}
              delay={0}
            >
              View Order
            </UncontrolledTooltip>
            <UncontrolledTooltip
              placement="left"
              target={`removeOrder${cell}`}
              delay={0}
            >
              Remove Order
            </UncontrolledTooltip>
          </React.Fragment>
      }
    </div>
  );

  render() {
    return (
      <Card className="card-chart">
        <Progress animated value={100} style={!this.props.loading ? {visibility: 'hidden'} : {visibility: 'visible'}}/>
        <CardHeader>
          <CardTitle className="title card-header-margin">Pending Orders</CardTitle>
        </CardHeader>
        <CardBody style={{ paddingBottom: 0 }}>
          <BootstrapTable
            data={this.props.pendingOrders}
            options={this.options}
            tableHeaderClass="table-column-header"
            tableBodyClass="table-body-myclass"
            bordered={false}
            search={true}
            height="400"
            scrollTop={"Bottom"}
          >
            <TableHeaderColumn
              dataField="id"
              isKey
              hidden
              searchable={false}
              editable={false}
            >
              Order ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="customer"
              dataFormat={this.customerFormatter}
              dataSort
              editable={false}
              tdStyle={{ whiteSpace: 'normal' }}
              width='200'
            >
              Customer
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="name"
              dataSort
              editable={false}
              tdStyle={{ whiteSpace: 'normal' }}
              width='200'
            >
              Order
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="am_pax"
              dataSort
              searchable={false}
              editable={false}
              headerAlign="center"
              dataAlign="center"
              thStyle={{whiteSpace: 'normal'}}
              width='120'
            >
              Pax (a.m.)
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="pm_pax"
              dataSort
              searchable={false}
              editable={false}
              headerAlign="center"
              dataAlign="center"
              thStyle={{whiteSpace: 'normal'}}
              width='120'
            >
              Pax (p.m.)
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="rate"
              dataSort
              searchable={false}
              editable={false}
              headerAlign="center"
              dataAlign="center"
              thStyle={{whiteSpace: 'normal'}}
              width='90'
            >
              Rate ($)
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="effectiveDate"
              dataFormat={this.dateFormatter}
              dataSort
              searchable={false}
              editable={false}
              headerAlign="center"
              dataAlign="center"
              thStyle={{whiteSpace: 'normal'}}
              width='150'
            >
              Effective Date
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="id"
              dataFormat={this.actionFormatter}
              editable={false}
              headerAlign="right"
              dataAlign="right"
              width='120'
            />
          </BootstrapTable>
        </CardBody>
        <CardFooter className='card-footer-margin'>
          <CardFooterStats>
            {[
              {
                i: "now-ui-icons travel_info",
                t: `Total: ${this.props.pendingOrders.length} Orders`
              }
            ]}
          </CardFooterStats>
        </CardFooter>
      </Card>
    );
  }
}

export default withRouter(connect(
  createStructuredSelector({
    pendingOrders: getPendingOrders,
    customers: getCustomers,
    loading: getOrdersLoading
  }),
  {
    selectCustomer,
    selectOrder,
    removeOrder,
  })(PendingOrdersComponent));