import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Progress
} from "reactstrap";
import { Button, FormInputs } from "..";
import { getUser } from "../../actions/user_action";
import { changePassUser } from "../../actions/selecteduser_action";
import { onCancelUserForm } from "../../actions/ui_action";

class PassFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.user,
      password: ""
    };
  }

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.user.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">
            Change User Password
          </CardTitle>
        </CardHeader>
        <CardBody>
          <form>
            <FormInputs
              ncols={["col-md-12 pr-3"]}
              proprieties={[
                {
                  label: "Password",
                  inputProps: {
                    type: "password",
                    placeholder: "Password",
                    value: this.state.password,
                    onChange: event =>
                      this.setState({ password: event.target.value })
                  }
                }
              ]}
            />
          </form>
        </CardBody>
        <CardFooter className="card-footer-buttons-padding">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Button
              color="primary"
              style={{ width: "20%" }}
              onClick={() =>
                this.props.changePassUser(this.state, options =>
                  this.props.notifyFunc(options)
                )
              }
            >
              Save
            </Button>
            {!this.props.selfService && (
              <Button
                color="secondary"
                style={{ width: "75%" }}
                onClick={() => this.props.onCancelUserForm()}
              >
                Cancel
              </Button>
            )}
          </div>
        </CardFooter>
      </Card>
    );
  }
}

export default connect(
  createStructuredSelector({
    user: getUser
  }),
  {
    changePassUser,
    onCancelUserForm
  }
)(PassFormComponent);
