import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardTitle
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button } from "..";
import { getUsers } from "../../actions/users_action";
import {
  getUIUserSelectedID,
  selectUser,
  newUser
} from "../../actions/ui_action";

class UserManagementComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.options = {
      sortIndicator: false
    };

    this.notifyRef = React.createRef();
  }

  dateFormatter = (cell, row) =>
    cell ? moment(cell).format("DD/MM/YYYY hh:mm:ss A") : null;

  activeFormatter = (cell, row) =>
    cell ? <i className={"now-ui-icons ui-1_check"} /> : null;

  actionFormatter = (cell, row) => (
    <div>
      <Button
        id={`viewUser${cell}`}
        round
        icon
        iconMini
        neutral
        disabled={row.id === this.props.userSelectedID}
        color="info"
        onClick={() => {
          this.props.selectUser(cell);
        }}
      >
        <i className="now-ui-icons files_paper" />
      </Button>
      <UncontrolledTooltip
        placement="left"
        target={`viewUser${cell}`}
        delay={0}
      >
        View User
      </UncontrolledTooltip>
    </div>
  );

  render() {
    return (
      <Card className="card-chart">
        <CardHeader>
          <CardTitle className="title card-header-margin">Users</CardTitle>
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-round btn-simple btn-icon"
              color="default"
            >
              <i className="now-ui-icons arrows-1_minimal-down" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => this.props.newUser()}>
                Add new User
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </CardHeader>
        <CardBody>
          <BootstrapTable
            data={this.props.users}
            options={this.options}
            tableHeaderClass="table-column-header"
            tableBodyClass="table-body-myclass"
            trClassName={(row, rowIdx) =>
              row.id === this.props.userSelectedID
                ? "table-highlight-myclass"
                : null
            }
            bordered={false}
            search={true}
            height="400"
            scrollTop={"Bottom"}
          >
            <TableHeaderColumn
              dataField="id"
              isKey
              hidden
              searchable={false}
              editable={false}
            >
              User ID
            </TableHeaderColumn>
            <TableHeaderColumn dataField="username" dataSort editable={false}>
              Username
            </TableHeaderColumn>
            <TableHeaderColumn dataField="groups" dataSort editable={false}>
              Role
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="last_login"
              dataFormat={this.dateFormatter}
              dataSort
              searchable={false}
              editable={false}
            >
              Last Login
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="is_active"
              dataFormat={this.activeFormatter}
              dataSort
              searchable={false}
              editable={false}
              headerAlign="right"
              dataAlign="right"
            >
              Active
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="id"
              dataFormat={this.actionFormatter}
              editable={false}
              headerAlign="right"
              dataAlign="right"
            />
          </BootstrapTable>
          <br />
          <br />
          <span>{`Total: ${this.props.users.length} users`}</span>
        </CardBody>
      </Card>
    );
  }
}

export default connect(
  createStructuredSelector({
    users: getUsers,
    userSelectedID: getUIUserSelectedID
  }),
  {
    selectUser,
    newUser
  }
)(UserManagementComponent);
