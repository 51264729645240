import React from "react";
import moment from "moment";
import { Container } from "reactstrap";

const Index = (props) => (
      <footer
        className={"footer" + (props.default ? " footer-default" : "")}
      >
        <Container fluid={!!props.fluid}>
          <div className="copyright">
            &copy; {moment.unix(moment().unix()).format("YYYY")}. Designed for{" "}
            <a
              href="./"
              target="_blank"
              rel="noopener noreferrer"
            >
              ISO Delight Pte Ltd
            </a>
          </div>
        </Container>
      </footer>
    );

export default Index;