import React from "react";
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  CardFooter
} from "reactstrap";

import {
  Button,
  CardCategory,
  CustomDropdownSetTrip,
  FormInputs,
} from "../";

import {
  getTrip,
  createTrip,
  updateTrip,
  removeTrip
} from '../../actions/trips_action';

import {
  getUIFilterSetTrip,
  getUITripIsNew,
  getUITripIsEdit,
  editTrip,
  newTrip,
  onCancelTripForm,
  onFilterSetTrip,
} from '../../actions/ui_action';

class MenuItemComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.tripIsNew ?
      {
        number: '',
        license: '',
      } :
      {
        ...this.props.trip,
      };
  }

  render() {
    return (
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="title">Trip</h5>
                  {!this.props.tripIsNew &&
                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn-round btn-simple btn-icon"
                        color="default"
                      >
                        <i className="now-ui-icons arrows-1_minimal-down"/>
                      </DropdownToggle>
                      {!this.props.tripIsEdit ?
                        <DropdownMenu right>
                          <DropdownItem onClick={() => this.props.editTrip()}>Edit</DropdownItem>
                          <DropdownItem onClick={() => this.props.newTrip()}>Add Trip</DropdownItem>
                        </DropdownMenu> :
                        <DropdownMenu right>
                          <DropdownItem onClick={() => this.props.removeTrip(this.state.id, (options) => this.props.notifyFunc(options))}>Delete</DropdownItem>
                        </DropdownMenu>
                      }
                    </UncontrolledDropdown>
                  }
                  {!(this.props.tripIsEdit || this.props.tripIsNew) &&
                    <CardCategory>
                      <Row>
                        <Col xs={12} md={2}>
                          <CustomDropdownSetTrip/>
                        </Col>
                      </Row>
                    </CardCategory>
                  }
                </CardHeader>
                <CardBody>
                    <form>
                      <FormInputs
                        ncols={["col-md-2 pr-3", "col-md-10 pr-3"]}
                        proprieties={[
                          {
                            label: "Trip Number",
                            inputProps: {
                              type: "number",
                              value: this.state.number,
                              disabled: !(this.props.tripIsNew || this.props.tripIsEdit),
                              onChange: event =>
                                this.setState({ number: event.target.value ? parseInt(event.target.value) : event.target.value}),
                              style: this.state.number ? null : {border: '2px solid red'}
                            }
                          },
                          {
                            label: "Vehicle License",
                            inputProps: {
                              type: "text",
                              value: this.state.license,
                              disabled: !(this.props.tripIsNew || this.props.tripIsEdit),
                              onChange: event =>
                                this.setState({ license: event.target.value }),
                              style: this.state.license ? null : {border: '2px solid red'}
                            }
                          }
                        ]}
                      />
                    </form>
                </CardBody>
                {(this.props.tripIsEdit || this.props.tripIsNew) &&
                <CardFooter className='card-footer-buttons-padding'>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      color="primary"
                      style={{ width: "20%" }}
                      onClick={this.props.tripIsEdit ? () => this.props.updateTrip(this.state, (options) => this.props.notifyFunc(options)) : () => this.props.createTrip(this.state, (options) => this.props.notifyFunc(options))}
                    >
                      Save
                    </Button>
                    <Button
                      color="secondary"
                      style={{ width: "75%" }}
                      onClick={() => this.props.onCancelTripForm()}
                    >
                      Cancel
                    </Button>
                  </div>
                </CardFooter>
                }
              </Card>
    );
  }
}

export default withRouter(connect(
  createStructuredSelector({
    trip: getTrip,
    filterSetTrip: getUIFilterSetTrip,
    tripIsNew: getUITripIsNew,
    tripIsEdit: getUITripIsEdit,
  }),
  {
    onFilterSetTrip,
    onCancelTripForm,
    editTrip,
    newTrip,
    createTrip,
    updateTrip,
    removeTrip
  })(MenuItemComponent));