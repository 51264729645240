import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { notifySuccess, notifyError } from "../components/NotificationOptions";

import {
  getUIAccountSelectedID,
  getUIAccountSelectedGST,
  onCancelDebitForm
} from "./ui_action";

import { retrieveDebits } from "./debits_action";

export const FETCH_DEBIT = "debit/FETCH_DEBIT";
export const SET_DEBIT = "debit/SET_DEBIT";
export const ERROR_DEBIT = "debit/ERROR_DEBIT";

export const getDebit = state => state.debit;
export const getDebitLoading = state => state.debit.loading;
export const getDebitError = state => state.debit.error;

export const fetchDebit = () => ({
  type: FETCH_DEBIT
});

export const setDebit = value => ({
  type: SET_DEBIT,
  value
});

export const errorDebit = message => ({
  type: ERROR_DEBIT,
  message
});

export const createDebit = (debit, notifyFunc) => async (
  dispatch,
  getState
) => {
  dispatch(fetchDebit());
  if (!debit.description || !debit.amount) {
    dispatch(errorDebit("Fields are required"));
  } else {
    try {
      const responsePostDebit = await axios.post(
        `${DJANGO_CORE_ADDR}/debits/create/?gst=${getUIAccountSelectedGST(
          getState()
        )}`,
        Object.assign({}, debit, {
          customer: getUIAccountSelectedID(getState()),
          void: false,
          balance: debit.amount
        }),
        { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
      );
      console.log("Debit Note created!", responsePostDebit);
      await notifyFunc(notifySuccess(`Debit Note created successfully.`));
      await dispatch(onCancelDebitForm());
      await dispatch(
        retrieveDebits(
          getUIAccountSelectedID(getState()),
          getUIAccountSelectedGST(getState())
        )
      );
    } catch (error) {
      console.error(error);
      dispatch(errorDebit("Some Error!"));
    }
  }
};

export const retrieveDebit = debitID => async (dispatch, getState) => {
  dispatch(fetchDebit());
  try {
    const responseGetDebit = await axios.get(
      `${DJANGO_CORE_ADDR}/debits/list/?id=${debitID}&gst=${getUIAccountSelectedGST(
        getState()
      )}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    dispatch(setDebit(responseGetDebit.data.debit));
  } catch (error) {
    console.error(error);
    dispatch(errorDebit("Some Error!"));
  }
};

export const updateDebit = (debit, notifyFunc) => async (
  dispatch,
  getState
) => {
  try {
    const responsePutDebit = await axios.patch(
      `${DJANGO_CORE_ADDR}/debits/update/?gst=${getUIAccountSelectedGST(
        getState()
      )}`,
      Object.assign({}, debit),
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );

    console.log("Debit edited!");
    await notifyFunc(notifySuccess(`Debit Note updated successfully`));
    await dispatch(
      retrieveDebits(
        getUIAccountSelectedID(getState()),
        getUIAccountSelectedGST(getState())
      )
    );
    await dispatch(onCancelDebitForm());
  } catch (error) {
    console.error(error);
    dispatch(error("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};

export const retrieveDebitPDF = (debitID, notifyFunc) => async (
  dispatch,
  getState
) => {
  dispatch(fetchDebit());
  try {
    await notifyFunc(notifySuccess(`Generating Debit Note...`));
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const responseGetDebit = await axios.get(
      `${DJANGO_CORE_ADDR}/debits/list/?id=${debitID}&gst=${getUIAccountSelectedGST(
        getState()
      )}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    console.log("Received Debit Note Generation: ", responseGetDebit.data);
    dispatch(setDebit(responseGetDebit.data.debit));

    let pdf = {};

    if (responseGetDebit.data.debit.customer.hasGST === "Y") {
      let customerInfo = [
        {
          text: `Company : ${responseGetDebit.data.debit.customer.name}`
        },
        {
          text: `Address : ${responseGetDebit.data.debit.customer.address}`
        },
        {
          text: `Singapore ${responseGetDebit.data.debit.customer.postal}`
        },
        {
          text: `E-Mail : ${responseGetDebit.data.debit.customer.email}`
        },
        {
          text: `Telephone : ${responseGetDebit.data.debit.customer.contact}`
        },
        {
          text: `HP : ${responseGetDebit.data.debit.customer.altContact}`,
          margin: [0, 0, 0, 5]
        }
      ];

      let info = [];

      info.push(customerInfo);

      let pdfTable = [
        [
          {
            text: "Description",
            alignment: "center",
            style: "tableHeader",
            border: [true, true, true, false]
          },
          {
            text: "Amount",
            alignment: "center",
            style: "tableHeader",
            border: [true, true, true, false]
          }
        ],
        [
          { text: responseGetDebit.data.debit.description },
          {
            text: `$${responseGetDebit.data.debit.amount.toFixed(2)}`,
            alignment: "center"
          }
        ]
      ];

      pdfTable.push([
        { text: " ", alignment: "center" },
        { text: " ", alignment: "center" }
      ]);

      const gstInPercent = (responseGetDebit.data.gst * 100).toFixed(0);
      const gstFormula = total =>
        (total * gstInPercent) / (100 + parseInt(gstInPercent));

      pdfTable.push([
        {
          text: `Total (inclusive of ${gstInPercent}% GST $${gstFormula(
            responseGetDebit.data.debit.amount
          ).toFixed(2)})`,
          alignment: "center"
        },
        {
          text: `$${responseGetDebit.data.debit.amount.toFixed(2)}`,
          alignment: "center"
        }
      ]);
      pdf = {
        content: [
          {
            text: "ISO DELIGHT PTE LTD",
            style: "header",
            alignment: "center"
          },
          {
            text: "Company / GST Registration Number: 200918960G",
            alignment: "center"
          },
          {
            text: "1002 Tai Seng Ave, #01-2550 Singapore 534409",
            alignment: "center"
          },
          {
            text: "Telephone : 64876387       Facsimile : 62823655",
            alignment: "center"
          },
          {
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    text: "DEBIT NOTE",
                    style: "subheader",
                    alignment: "center",
                    border: [false, true, false, false]
                  }
                ],
                [
                  {
                    alignment: "justify",
                    columns: [
                      info,
                      [
                        {
                          text: `Debit Note : ISO/DN/${
                            responseGetDebit.data.debit.year
                          }/${responseGetDebit.data.debit.id}`,
                          margin: [0, 0, 0, 5],
                          alignment: "right"
                        },
                        {
                          text: `Date : ${moment
                            .unix(responseGetDebit.data.debit.date)
                            .format("DD MMM YYYY")}`,
                          margin: [0, 0, 0, 5],
                          alignment: "right"
                        }
                      ]
                    ],
                    border: [false, false, false, false]
                  }
                ]
              ]
            },
            margin: [0, 5, 0, 5]
          },
          {
            table: {
              heights: [10, 400, 10, 10],
              widths: ["*", 100],
              headerRows: 1,
              body: pdfTable
            }
          }
        ],
        styles: {
          header: {
            fontSize: 16,
            bold: false,
            alignment: "justify",
            margin: [0, 0, 0, 5]
          },
          subheader: {
            fontSize: 12,
            bold: false,
            alignment: "justify",
            margin: [0, 0, 0, 5]
          },
          tableHeader: {
            bold: true,
            fontSize: 10
          }
        },
        defaultStyle: { fontSize: 9 }
      };
    } else {
      let customerInfo = [
        {
          text: `Company : ${responseGetDebit.data.debit.customer.name}`
        },
        {
          text: `Address : ${responseGetDebit.data.debit.customer.address}`
        },
        {
          text: `Singapore ${responseGetDebit.data.debit.customer.postal}`
        },
        {
          text: `E-Mail : ${responseGetDebit.data.debit.customer.email}`
        },
        {
          text: `Telephone : ${responseGetDebit.data.debit.customer.contact}`
        },
        {
          text: `HP : ${responseGetDebit.data.debit.customer.altContact}`,
          margin: [0, 0, 0, 5]
        }
      ];

      let info = [];

      info.push(customerInfo);

      let pdfTable = [
        [
          {
            text: "Description",
            alignment: "center",
            style: "tableHeader",
            border: [true, true, true, false]
          },
          {
            text: "Amount",
            alignment: "center",
            style: "tableHeader",
            border: [true, true, true, false]
          }
        ],
        [
          { text: responseGetDebit.data.debit.description },
          {
            text: `$${responseGetDebit.data.debit.amount.toFixed(2)}`,
            alignment: "center"
          }
        ]
      ];

      pdfTable.push([
        { text: " ", alignment: "center" },
        { text: " ", alignment: "center" }
      ]);
      pdfTable.push([
        { text: `Total `, alignment: "center" },
        {
          text: `$${responseGetDebit.data.debit.amount.toFixed(2)}`,
          alignment: "center"
        }
      ]);
      pdf = {
        content: [
          {
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    text: "DEBIT NOTE",
                    style: "subheader",
                    alignment: "center",
                    border: [false, false, false, false]
                  }
                ],
                [
                  {
                    alignment: "justify",
                    columns: [
                      info,
                      [
                        {
                          text: `Debit Note : DN/${
                            responseGetDebit.data.debit.year
                          }/${responseGetDebit.data.debit.id}`,
                          margin: [0, 0, 0, 5],
                          alignment: "right"
                        },
                        {
                          text: `Date : ${moment
                            .unix(responseGetDebit.data.debit.date)
                            .format("DD MMM YYYY")}`,
                          margin: [0, 0, 0, 5],
                          alignment: "right"
                        }
                      ]
                    ],
                    border: [false, false, false, false]
                  }
                ]
              ]
            },
            margin: [0, 5, 0, 5]
          },
          {
            table: {
              heights: [10, 400, 10, 10],
              widths: ["*", 100],
              headerRows: 1,
              body: pdfTable
            }
          }
        ],
        styles: {
          header: {
            fontSize: 16,
            bold: false,
            alignment: "justify",
            margin: [0, 0, 0, 5]
          },
          subheader: {
            fontSize: 12,
            bold: false,
            alignment: "justify",
            margin: [0, 0, 0, 5]
          },
          tableHeader: {
            bold: true,
            fontSize: 10
          }
        },
        defaultStyle: { fontSize: 9 }
      };
    }

    pdfMake.createPdf(pdf).open();
  } catch (error) {
    console.error(error);
    dispatch(errorDebit("Some Error!"));
  }
};
