import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";
import { getUIFilterMonth, getUIFilterYear } from "./ui_action";

export const FETCH_CREDITS = "credits/FETCH_CREDITS";
export const SET_CREDITS = "credits/SET_CREDITS";
export const ERROR_CREDITS = "credits/ERROR_CREDITS";

export const getCredits = state => state.credits.credits;
export const getCreditsSum = state =>
  state.credits.credits.reduce(
    (accumulator, currentValue) => accumulator + currentValue.amount,
    0.0
  );
export const getCreditsLoading = state => state.credits.loading;
export const getCreditsError = state => state.credits.error;

export const fetchCredits = () => ({
  type: FETCH_CREDITS
});

export const setCredits = value => ({
  type: SET_CREDITS,
  value
});

export const errorCredits = message => ({
  type: ERROR_CREDITS,
  message
});

export const retrieveCredits = (accountID, isGST) => async (
  dispatch,
  getState
) => {
  if (accountID && isGST) {
    dispatch(fetchCredits());
    try {
      const responseGetCredits = await axios.get(
        `${DJANGO_CORE_ADDR}/credits/list/summary/?id=${accountID}&gst=${isGST}&year=${getUIFilterYear(
          getState()
        )}&month=${getUIFilterMonth(getState())}`,
        { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
      );
      dispatch(setCredits(responseGetCredits.data));
    } catch (error) {
      console.error(error);
      dispatch(errorCredits("Some Error!"));
    }
  }
};
