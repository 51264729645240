import {
  FETCH_CREDIT,
  SET_CREDIT,
  ERROR_CREDIT,
} from "../actions/credit_action";

const INITIAL_STATE = {
  id: null,
  order: null,
  customer: null,
  date: null,
  month: null,
  year: null,
  description: '',
  amount: 0.0,
  void: false,
  loading: false,
  error: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CREDIT:
      return {
        ...state,
        loading: true,
      };
    case SET_CREDIT:
      return {
        ...state,
        ...action.value,
        error: '',
        loading: false,
      };
    case ERROR_CREDIT:
      return {
        ...state,
        error: action.message,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;