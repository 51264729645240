import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Label,
  Input
} from "reactstrap";
import { Button, FormInputs } from "../";

import { getUser } from "../../actions/user_action";
import { getCustomers } from "../../actions/customers_action";
import { getUsers } from "../../actions/users_action";
import {
  getCustomer,
  createCustomer,
  updateCustomer
} from "../../actions/customer_action";
import {
  getUICustomerSelectedID,
  getUICustomerIsNew,
  getUICustomerIsEdit,
  editCustomer,
  onCancelCustomerForm
} from "../../actions/ui_action";

class CustomerFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.customerIsNew
      ? {
          name: "",
          email: "",
          contact: "",
          altContact: "",
          address: "",
          country: "Singapore",
          postal: "",
          remarks: "",
          showCompany: "Y",
          user: "",
          inactive: false
        }
      : {
          ...this.props.customer,
          user: this.props.customer.user.id
        };
  }

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.customer.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">
            Customer Details
          </CardTitle>
          {this.props.me.username !== "superadmin" &&
            !(this.props.customerIsNew || this.props.customerIsEdit) && (
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-round btn-simple btn-icon"
                  color="default"
                >
                  <i className="now-ui-icons arrows-1_minimal-down" />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => this.props.editCustomer()}>
                    Edit
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
        </CardHeader>
        <CardBody>
          <form>
            <FormInputs
              ncols={["col-md-6 pr-3", "col-md-6 pr-3"]}
              proprieties={[
                {
                  label: "Company",
                  inputProps: {
                    type: "text",
                    disabled: !(
                      this.props.customerIsEdit || this.props.customerIsNew
                    ),
                    placeholder: "Company Name",
                    value: this.state.name,
                    onChange: event =>
                      this.setState({ name: event.target.value }),
                    style: this.state.name ? null : { border: "2px solid red" }
                  }
                },
                {
                  label: "Email address",
                  inputProps: {
                    type: "email",
                    disabled: !(
                      this.props.customerIsEdit || this.props.customerIsNew
                    ),
                    placeholder: "Email",
                    value: this.state.email,
                    onChange: event =>
                      this.setState({ email: event.target.value })
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-6 pr-3", "col-md-6 pr-3"]}
              proprieties={[
                {
                  label: "Office Contact",
                  inputProps: {
                    type: "number",
                    disabled: !(
                      this.props.customerIsEdit || this.props.customerIsNew
                    ),
                    placeholder: "Telephone Number",
                    value: this.state.contact,
                    onChange: event =>
                      this.setState({ contact: event.target.value })
                  }
                },
                {
                  label: "HP Contact",
                  inputProps: {
                    type: "number",
                    disabled: !(
                      this.props.customerIsEdit || this.props.customerIsNew
                    ),
                    placeholder: "Telephone Number",
                    value: this.state.altContact,
                    onChange: event =>
                      this.setState({
                        altContact: event.target.value
                      })
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-12 pr-3"]}
              proprieties={[
                {
                  label: "Address",
                  inputProps: {
                    type: "text",
                    disabled: !(
                      this.props.customerIsEdit || this.props.customerIsNew
                    ),
                    placeholder: "Office Address",
                    value: this.state.address,
                    onChange: event =>
                      this.setState({ address: event.target.value })
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-6 pr-3", "col-md-6 pr-3"]}
              proprieties={[
                {
                  label: "Country",
                  inputProps: {
                    type: "text",
                    disabled: !(
                      this.props.customerIsEdit || this.props.customerIsNew
                    ),
                    placeholder: "Country",
                    value: this.state.country,
                    onChange: event =>
                      this.setState({ country: event.target.value })
                  }
                },
                {
                  label: "Postal Code",
                  inputProps: {
                    type: "number",
                    disabled: !(
                      this.props.customerIsEdit || this.props.customerIsNew
                    ),
                    value: this.state.postal,
                    placeholder: "Postal Code",
                    onChange: event =>
                      this.setState({ postal: event.target.value })
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-12 pr-3"]}
              proprieties={[
                {
                  label: "Remarks",
                  inputProps: {
                    type: "textarea",
                    disabled: !(
                      this.props.customerIsEdit || this.props.customerIsNew
                    ),
                    value: this.state.remarks,
                    placeholder: "Customer Description / Remarks",
                    onChange: event =>
                      this.setState({ remarks: event.target.value })
                  }
                }
              ]}
            />
            {/*
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-md-12 pr-3">
                <Label for="showTypeSelect">Show Company Info (Invoice)</Label>
                <Input
                  id="showTypeSelect"
                  type="select"
                  name="select"
                  value={this.state.showCompany}
                  onChange={event =>
                    this.setState({ showCompany: event.target.value })
                  }
                  disabled={
                    !(this.props.customerIsEdit || this.props.customerIsNew)
                  }
                  style={{ height: "35px", textIndent: "10px" }}
                >
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </Input>
              </div>
            </div>
            */}
            {this.props.me.role === "admin" && (
              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-md-6 pr-3">
                  <Label for="showAgentSelect">Agent</Label>
                  <Input
                    id="showAgentSelect"
                    type="select"
                    name="select"
                    value={this.state.user}
                    onChange={event =>
                      this.setState({ user: event.target.value })
                    }
                    disabled={
                      !(this.props.customerIsEdit || this.props.customerIsNew)
                    }
                    style={{ height: "35px", textIndent: "10px" }}
                  >
                    <option key={0} value="">
                      Please select an Agent
                    </option>
                    {this.props.users.map(
                      user =>
                        user.groups === "sales" &&
                        user.is_active && (
                          <option key={user.id} value={user.id}>
                            {user.username}
                          </option>
                        )
                    )}
                  </Input>
                </div>
                <div className="col-md-6 pr-3">
                  <Label for="showInactiveSelect">Mark as Inactive</Label>
                  <Input
                    id="showInactiveSelect"
                    type="select"
                    name="select"
                    value={this.state.inactive}
                    onChange={event =>
                      this.setState({ inactive: event.target.value })
                    }
                    disabled={
                      !(this.props.customerIsEdit || this.props.customerIsNew)
                    }
                    style={{ height: "35px", textIndent: "10px" }}
                  >
                    <option key={0} value={false}>
                      No
                    </option>
                    <option key={1} value={true}>
                      Yes
                    </option>
                  </Input>
                </div>
              </div>
            )}
          </form>
        </CardBody>
        {(this.props.customerIsEdit || this.props.customerIsNew) && (
          <CardFooter className="card-footer-buttons-padding">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Button
                color="primary"
                style={{ width: "20%" }}
                onClick={
                  this.props.customerIsEdit
                    ? () =>
                        this.props.updateCustomer(this.state, options =>
                          this.props.notifyFunc(options)
                        )
                    : () =>
                        this.props.createCustomer(this.state, options =>
                          this.props.notifyFunc(options)
                        )
                }
              >
                Save
              </Button>
              <Button
                color="secondary"
                style={{ width: "75%" }}
                onClick={() => this.props.onCancelCustomerForm()}
              >
                Cancel
              </Button>
            </div>
          </CardFooter>
        )}
      </Card>
    );
  }
}

export default connect(
  createStructuredSelector({
    me: getUser,
    users: getUsers,
    customers: getCustomers,
    customer: getCustomer,
    customerSelectedID: getUICustomerSelectedID,
    customerIsNew: getUICustomerIsNew,
    customerIsEdit: getUICustomerIsEdit
  }),
  {
    createCustomer,
    updateCustomer,
    editCustomer,
    onCancelCustomerForm
  }
)(CustomerFormComponent);
