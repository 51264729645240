import React from "react";
import NotificationAlert from 'react-notification-alert';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Row, Col } from "reactstrap";

import { PanelHeader, SetTripAdminComponent } from "../../components";

import {
  getUIFilterSetTrip,
  getUITripIsEdit,
  getUITripIsNew,
  onCancelTripForm,
} from '../../actions/ui_action';

import { getTrip, retrieveTrips, retrieveTrip } from '../../actions/trips_action';

class Trip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.notifyRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {

    if(!this.props.trip)
    {
      return true;
    }

    return nextProps.trip.id !== this.props.trip.id ||
        nextProps.tripIsEdit !== this.props.tripIsEdit ||
        nextProps.tripIsNew !== this.props.tripIsNew ||
        nextProps.filterSetTrip !== this.props.filterSetTrip;
  }

  componentDidMount() {
    this.props.onCancelTripForm();
    this.props.retrieveTrips();
    this.props.retrieveTrip();
  }

  onNotification = options => this.notifyRef.current.notificationAlert(options);

  render() {
    return (
      <div>
        <NotificationAlert ref={this.notifyRef} />
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <SetTripAdminComponent
                key={this.props.tripIsNew || this.props.tripIsEdit ? 0 : this.props.trip.id}
                notifyFunc={(options) => this.onNotification(options)}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
    trip: getTrip,
    filterSetTrip: getUIFilterSetTrip,
    tripIsEdit: getUITripIsEdit,
    tripIsNew: getUITripIsNew,
  }),
  {
    retrieveTrips,
    retrieveTrip,
    onCancelTripForm
  })(Trip);