import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
  Row,
  Col,
  Progress
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
  Button,
  CardCategory,
  CardFooterStats,
  CustomDropdownMonth,
  CustomDropdownYear,
  ThisMonthButton
} from "..";

import {
  getSummary,
  getSummaryLength,
  getSummaryLoading
} from "../../actions/customers_summary_action";
import {
  getUIFilterYear,
  getUIFilterMonth,
  selectCustomer,
  onFilterMonth_summary,
  onFilterYear_summary,
  onFilterYearMonth_summary
} from "../../actions/ui_action";

class CustomerSummaryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.options = {
      sortIndicator: false
    };
  }

  salesFormatter = (cell, row) => (cell ? `$${cell.toFixed(2)}` : `$0.00`);

  actionFormatter = (cell, row) => (
    <div>
      {
        <React.Fragment>
          <Button
            id={`viewCustomer${cell}`}
            round
            icon
            iconMini
            neutral
            color="info"
            onClick={() => {
              this.props.selectCustomer(cell);
              this.props.history.push(`/customer-page`);
            }}
          >
            <i className="now-ui-icons files_paper" />
          </Button>
          <UncontrolledTooltip
            placement="left"
            target={`viewCustomer${cell}`}
            delay={0}
          >
            View Customer
          </UncontrolledTooltip>
        </React.Fragment>
      }
    </div>
  );

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">
            Customers Summary
          </CardTitle>
          <CardCategory>
            <Row>
              <Col xs={12} md={2}>
                <CustomDropdownMonth
                  retrieveFunc={value =>
                    this.props.onFilterMonth_summary(value)
                  }
                />
              </Col>
              <Col xs={12} md={2}>
                <CustomDropdownYear
                  retrieveFunc={value => this.props.onFilterYear_summary(value)}
                />
              </Col>
              <Col xs={12} md={2}>
                <ThisMonthButton
                  retrieveFunc={(year, month) =>
                    this.props.onFilterYearMonth_summary(year, month)
                  }
                />
              </Col>
            </Row>
          </CardCategory>
        </CardHeader>
        <CardBody style={{ paddingBottom: 0 }}>
          {this.props.filterYear < moment().year() ||
          (this.props.filterYear === moment().year() &&
            this.props.filterMonth <= moment().month() + 1) ? (
            <BootstrapTable
              data={this.props.summary}
              options={this.options}
              tableHeaderClass="table-column-header"
              tableBodyClass="table-body-myclass"
              bordered={false}
              search={true}
              height="400"
              scrollTop={"Bottom"}
            >
              <TableHeaderColumn
                dataField="id"
                isKey
                hidden
                searchable={false}
                editable={false}
              >
                Customer ID
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="customer"
                dataSort
                editable={false}
                tdStyle={{ whiteSpace: "normal" }}
                width="200"
              >
                Customer
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="invoices"
                dataSort
                searchable={false}
                editable={false}
                headerAlign="center"
                dataAlign="center"
                width="90"
              >
                Invoices
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="am_packets"
                dataSort
                searchable={false}
                editable={false}
                headerAlign="center"
                dataAlign="center"
                thStyle={{ whiteSpace: "normal" }}
                width="180"
              >
                Total Packs (a.m.)
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="pm_packets"
                dataSort
                searchable={false}
                editable={false}
                headerAlign="center"
                dataAlign="center"
                thStyle={{ whiteSpace: "normal" }}
                width="180"
              >
                Total Packs (p.m.)
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="totalSales"
                dataFormat={this.salesFormatter}
                dataSort
                searchable={false}
                editable={false}
                headerAlign="center"
                dataAlign="center"
                thStyle={{ whiteSpace: "normal" }}
                width="180"
              >
                Total Sales ($)
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="id"
                dataFormat={this.actionFormatter}
                editable={false}
                headerAlign="right"
                dataAlign="right"
                width="90"
              />
            </BootstrapTable>
          ) : (
            <div>Selected Year / Month must be earlier than current Date</div>
          )}
        </CardBody>
        <CardFooter className="card-footer-margin">
          {(this.props.filterYear < moment().year() ||
            (this.props.filterYear === moment().year() &&
              this.props.filterMonth <= moment().month() + 1)) && (
            <CardFooterStats>
              {[
                {
                  i: "now-ui-icons travel_info",
                  t: `Total: ${this.props.summary.length} Customers | ${
                    this.props.length
                  } Invoices`
                }
              ]}
            </CardFooterStats>
          )}
        </CardFooter>
      </Card>
    );
  }
}

export default withRouter(
  connect(
    createStructuredSelector({
      filterYear: getUIFilterYear,
      filterMonth: getUIFilterMonth,
      summary: getSummary,
      length: getSummaryLength,
      loading: getSummaryLoading
    }),
    {
      selectCustomer,
      onFilterMonth_summary,
      onFilterYear_summary,
      onFilterYearMonth_summary
    }
  )(CustomerSummaryComponent)
);
