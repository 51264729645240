import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";

export const FETCH_USERS = "users/FETCH_USERS";
export const SET_USERS = "users/SET_USERS";
export const ERROR_USERS = "users/ERROR_USERS";

export const getUsers = state => state.users.users;
export const getUsersLoading = state => state.users.loading;
export const getUsersError = state => state.users.error;

export const fetchUsers = () => ({
  type: FETCH_USERS
});

export const setUsers = value => ({
  type: SET_USERS,
  value
});

export const errorUsers = message => ({
  type: ERROR_USERS,
  message
});

export const retrieveUsers = () => async dispatch => {
  dispatch(fetchUsers());
  try {
    const responseGetUsers = await axios.get(
      `${DJANGO_CORE_ADDR}/users/list/`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    const users = [];
    responseGetUsers.data.forEach(user => {
      users.push({
        id: user.id,
        username: user.username,
        is_active: user.is_active,
        groups: user.groups.length > 0 ? user.groups[0].name : "",
        last_login: user.last_login
      });
    });
    dispatch(setUsers(users));
  } catch (error) {
    console.error(error);
    dispatch(errorUsers("Some Error!"));
  }
};
