import React from "react";
import moment from 'moment';
import {
  Button,
} from "reactstrap";

const ThisMonthButton = (props) => (
  <Button onClick={() => {
    props.retrieveFunc(moment().year(), moment().month()+1);
  }}>
    This Month
  </Button>
);

export default (ThisMonthButton);
