import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
  Progress
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button, CardFooterStats } from "../";

import { getDebits } from "../../actions/debits_action";
import { retrieveDebitPDF } from "../../actions/debit_action";
import {
  getUIDebitSelectedID,
  selectDebit,
  getUIAccountSelectedGST
} from "../../actions/ui_action";

class DebitsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.options = {
      sortIndicator: false
    };
  }

  idFormatter = (cell, row) =>
    this.props.hasGST === "Y"
      ? `DN/${row.year}/${cell}`
      : `${row.year}/${cell}`;

  dateFormatter = (cell, row) =>
    cell ? moment.unix(cell).format("DD/MM/YYYY") : null;

  statusFormatter = (cell, row) => {
    let status = "Partial";
    if (row.balance === 0) {
      status = "Completed";
    } else if (row.balance === row.amount) {
      status = "None";
    }
    return (
      <div id={`status${cell}`}>
        <span>{status}</span>
        {row.balance !== row.amount && (
          <UncontrolledTooltip
            placement="left"
            target={`status${cell}`}
            delay={0}
          >
            <br />
            {row.payments.map(payment => (
              <React.Fragment key={`payment${cell}`}>
                <div>Payment: {payment.payment.description}</div>
                <div>
                  Date : {moment.unix(payment.date).format("DD/MM/YYYY")}
                </div>
                <div>Amount : ${payment.amount}</div>
                <br />
              </React.Fragment>
            ))}
          </UncontrolledTooltip>
        )}
      </div>
    );
  };

  salesFormatter = (cell, row) => (cell ? `$${cell.toFixed(2)}` : `$0.00`);

  actionEditFormatter = (cell, row) => (
    <div>
      {
        <React.Fragment>
          <Button
            id={`editDebit${cell}`}
            round
            icon
            iconMini
            neutral
            color="info"
            onClick={() => {
              console.log("Edit Debit: ", cell);
              this.props.selectDebit(cell);
            }}
          >
            <i className="now-ui-icons shopping_tag-content" />
          </Button>
          <UncontrolledTooltip
            placement="left"
            target={`editDebit${cell}`}
            delay={0}
          >
            Edit Debit Note
          </UncontrolledTooltip>
        </React.Fragment>
      }
    </div>
  );

  actionViewFormatter = (cell, row) => (
    <div>
      {
        <React.Fragment>
          <Button
            id={`viewDebit${cell}`}
            round
            icon
            iconMini
            neutral
            color="info"
            onClick={() => {
              console.log("View Debit: ", cell);
              this.props.retrieveDebitPDF(cell, options =>
                this.props.notifyFunc(options)
              );
            }}
          >
            <i className="now-ui-icons files_paper" />
          </Button>
          <UncontrolledTooltip
            placement="left"
            target={`viewDebit${cell}`}
            delay={0}
          >
            View Debit Note
          </UncontrolledTooltip>
        </React.Fragment>
      }
    </div>
  );

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.debits.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">
            Debit Notes
          </CardTitle>
        </CardHeader>
        <CardBody style={{ paddingBottom: 0 }}>
          <BootstrapTable
            data={this.props.debits}
            options={this.options}
            tableHeaderClass="table-column-header"
            tableBodyClass="table-body-myclass"
            bordered={false}
            search={true}
            height="250"
            scrollTop={"Top"}
          >
            <TableHeaderColumn
              dataField="id"
              dataFormat={this.idFormatter}
              isKey
              searchable={false}
              editable={false}
            >
              ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="description"
              dataSort
              editable={false}
            >
              Description
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="amount"
              dataFormat={this.salesFormatter}
              editable={false}
              headerAlign="center"
              dataAlign="center"
              width="150"
            >
              Amount ($)
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="balance"
              dataFormat={this.salesFormatter}
              editable={false}
              headerAlign="center"
              dataAlign="center"
              width="150"
            >
              Balance ($)
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="date"
              dataFormat={this.dateFormatter}
              dataSort
              searchable={false}
              editable={false}
              headerAlign="center"
              dataAlign="center"
              thStyle={{ whiteSpace: "normal" }}
              width="150"
            >
              Date
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="id"
              dataFormat={this.statusFormatter}
              dataSort
              editable={false}
              tdStyle={{ whiteSpace: "normal" }}
              width="150"
            >
              Status
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="id"
              dataFormat={this.actionEditFormatter}
              editable={false}
              headerAlign="right"
              dataAlign="right"
              width="50"
            />
            <TableHeaderColumn
              dataField="id"
              dataFormat={this.actionViewFormatter}
              editable={false}
              headerAlign="right"
              dataAlign="right"
              width="50"
            />
          </BootstrapTable>
        </CardBody>
        <CardFooter className="card-footer-margin">
          <CardFooterStats>
            {[
              {
                i: "now-ui-icons travel_info",
                t: `Total: ${this.props.debits.length} Debit Notes`
              }
            ]}
          </CardFooterStats>
        </CardFooter>
      </Card>
    );
  }
}

export default connect(
  createStructuredSelector({
    debits: getDebits,
    debitSelectedID: getUIDebitSelectedID,
    hasGST: getUIAccountSelectedGST
  }),
  {
    selectDebit,
    retrieveDebitPDF
  }
)(DebitsComponent);
