import moment from "moment";

import {
  SET_UI_FILTER_MONTH,
  SET_UI_FILTER_YEAR,
  SET_UI_FILTER_MENU,
  SET_UI_FILTER_TRIP,
  SET_UI_FILTER_AGENT,
  SET_UI_FILTER_SETMENU,
  SET_UI_FILTER_SETMENUITEM,
  SET_UI_FILTER_SETTRIP,
  SET_UI_USER_IS_EDITPASS,
  SET_UI_USER_IS_EDIT,
  SET_UI_USER_IS_NEW,
  SET_UI_USER_SELECTED_ID,
  SET_UI_CUSTOMER_IS_EDIT,
  SET_UI_CUSTOMER_IS_NEW,
  SET_UI_CUSTOMER_SELECTED_ID,
  SET_UI_ORDER_IS_EDIT,
  SET_UI_ORDER_IS_NEW,
  SET_UI_ORDER_SELECTED_ID,
  SET_UI_MENU_IS_EDIT,
  SET_UI_MENU_IS_NEW,
  SET_UI_MENUITEM_IS_EDIT,
  SET_UI_MENUITEM_IS_NEW,
  SET_UI_TRIP_IS_EDIT,
  SET_UI_TRIP_IS_NEW,
  SET_UI_RATE_IS_EDIT,
  SET_UI_BAG_IS_EDIT,
  SET_UI_FOODPREP_IS_EDIT,
  SET_UI_ACCOUNT_SELECTED_GST,
  SET_UI_ACCOUNT_SELECTED_ID,
  SET_UI_DEBIT_IS_EDIT,
  SET_UI_DEBIT_IS_NEW,
  SET_UI_DEBIT_SELECTED_ID,
  SET_UI_CREDIT_IS_EDIT,
  SET_UI_CREDIT_IS_NEW,
  SET_UI_CREDIT_SELECTED_ID,
  SET_UI_PAYMENT_IS_EDIT,
  SET_UI_PAYMENT_IS_NEW,
  SET_UI_PAYMENT_SELECTED_ID
} from "../actions/ui_action";

const INITIAL_STATE = {
  filterMonth: moment().month() + 1,
  filterYear: moment().year(),
  filterMenu: 0,
  filterTrip: 0,
  filterAgent: 0,
  filterSetMenu: 1,
  filterSetMenuItem: 1,
  filterSetTrip: 1,
  userIsEditPass: false,
  userIsEdit: false,
  userIsNew: false,
  userSelectedID: null,
  customerIsEdit: false,
  customerIsNew: false,
  customerSelectedID: null,
  orderIsEdit: false,
  orderIsNew: false,
  orderSelectedID: null,
  menuIsEdit: false,
  menuIsNew: false,
  menuItemIsEdit: false,
  menuItemIsNew: false,
  tripIsEdit: false,
  tripIsNew: false,
  rateIsEdit: false,
  bagIsEdit: false,
  foodPrepIsEdit: false,
  accountSelectedGST: null,
  accountSelectedID: null,
  debitIsEdit: false,
  debitIsNew: false,
  debitSelectedID: null,
  creditIsEdit: false,
  creditIsNew: false,
  creditSelectedID: null,
  paymentIsEdit: false,
  paymentIsNew: false,
  paymentSelectedID: null
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_UI_FILTER_MONTH:
      return {
        ...state,
        filterMonth: action.value
      };
    case SET_UI_FILTER_YEAR:
      return {
        ...state,
        filterYear: action.value
      };
    case SET_UI_FILTER_MENU:
      return {
        ...state,
        filterMenu: action.value
      };
    case SET_UI_FILTER_TRIP:
      return {
        ...state,
        filterTrip: action.value
      };
    case SET_UI_FILTER_AGENT:
      return {
        ...state,
        filterAgent: action.value
      };
    case SET_UI_FILTER_SETMENU:
      return {
        ...state,
        filterSetMenu: action.value
      };
    case SET_UI_FILTER_SETMENUITEM:
      return {
        ...state,
        filterSetMenuItem: action.value
      };
    case SET_UI_FILTER_SETTRIP:
      return {
        ...state,
        filterSetTrip: action.value
      };
    case SET_UI_USER_IS_EDITPASS:
      return {
        ...state,
        userIsEditPass: action.value
      };
    case SET_UI_USER_IS_EDIT:
      return {
        ...state,
        userIsEdit: action.value
      };
    case SET_UI_USER_IS_NEW:
      return {
        ...state,
        userIsNew: action.value
      };
    case SET_UI_USER_SELECTED_ID:
      return {
        ...state,
        userSelectedID: action.value
      };
    case SET_UI_CUSTOMER_IS_EDIT:
      return {
        ...state,
        customerIsEdit: action.value
      };
    case SET_UI_CUSTOMER_IS_NEW:
      return {
        ...state,
        customerIsNew: action.value
      };
    case SET_UI_CUSTOMER_SELECTED_ID:
      return {
        ...state,
        customerSelectedID: action.value
      };
    case SET_UI_ORDER_IS_EDIT:
      return {
        ...state,
        orderIsEdit: action.value
      };
    case SET_UI_ORDER_IS_NEW:
      return {
        ...state,
        orderIsNew: action.value
      };
    case SET_UI_ORDER_SELECTED_ID:
      return {
        ...state,
        orderSelectedID: action.value
      };
    case SET_UI_MENU_IS_EDIT:
      return {
        ...state,
        menuIsEdit: action.value
      };
    case SET_UI_MENU_IS_NEW:
      return {
        ...state,
        menuIsNew: action.value
      };
    case SET_UI_MENUITEM_IS_EDIT:
      return {
        ...state,
        menuItemIsEdit: action.value
      };
    case SET_UI_MENUITEM_IS_NEW:
      return {
        ...state,
        menuItemIsNew: action.value
      };
    case SET_UI_TRIP_IS_EDIT:
      return {
        ...state,
        tripIsEdit: action.value
      };
    case SET_UI_TRIP_IS_NEW:
      return {
        ...state,
        tripIsNew: action.value
      };
    case SET_UI_RATE_IS_EDIT:
      return {
        ...state,
        rateIsEdit: action.value
      };
    case SET_UI_BAG_IS_EDIT:
      return {
        ...state,
        bagIsEdit: action.value
      };
    case SET_UI_FOODPREP_IS_EDIT:
      return {
        ...state,
        foodPrepIsEdit: action.value
      };
    case SET_UI_ACCOUNT_SELECTED_GST:
      return {
        ...state,
        accountSelectedGST: action.value
      };
    case SET_UI_ACCOUNT_SELECTED_ID:
      return {
        ...state,
        accountSelectedID: action.value
      };
    case SET_UI_DEBIT_IS_EDIT:
      return {
        ...state,
        debitIsEdit: action.value
      };
    case SET_UI_DEBIT_IS_NEW:
      return {
        ...state,
        debitIsNew: action.value
      };
    case SET_UI_DEBIT_SELECTED_ID:
      return {
        ...state,
        debitSelectedID: action.value
      };
    case SET_UI_CREDIT_IS_EDIT:
      return {
        ...state,
        creditIsEdit: action.value
      };
    case SET_UI_CREDIT_IS_NEW:
      return {
        ...state,
        creditIsNew: action.value
      };
    case SET_UI_CREDIT_SELECTED_ID:
      return {
        ...state,
        creditSelectedID: action.value
      };
    case SET_UI_PAYMENT_IS_EDIT:
      return {
        ...state,
        paymentIsEdit: action.value
      };
    case SET_UI_PAYMENT_IS_NEW:
      return {
        ...state,
        paymentIsNew: action.value
      };
    case SET_UI_PAYMENT_SELECTED_ID:
      return {
        ...state,
        paymentSelectedID: action.value
      };
    default:
      return state;
  }
};

export default reducer;
