import {
  FETCH_RATES,
  SET_RATE,
  ERROR_RATES,
} from "../actions/rates_action";

const INITIAL_STATE = {
  rate: {},
  loading: false,
  error: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_RATES:
      return {
        ...state,
        loading: true,
      };
    case SET_RATE:
      return {
        ...state,
        rate: action.value,
        error: '',
        loading: false,
      };
    case ERROR_RATES:
      return {
        ...state,
        error: action.message,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;