import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  FormGroup,
  Label,
  Input
} from "reactstrap";

import {
  getMenuItems,
} from '../../../../actions/menuitems_action';

const CustomDropdownMenuItem = (props) => (
  <FormGroup>
    <Label for="filterMenu">Menu Item</Label>
    <Input
      type="select"
      name="select"
      id="filterMenu"
      disabled={props.disable}
      value={props.value}
      onChange={event => props.retrieveFunc(parseInt(event.target.value))}
    >
      {
        props.menuItems.map(
          item => <option value={item.id} key={item.id}>{item.name}</option>
        )
      }
    </Input>
  </FormGroup>
);

export default connect(
  createStructuredSelector({
    menuItems: getMenuItems,
  }),
  {})(CustomDropdownMenuItem);
