import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
  Progress,
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button, CardFooterStats } from "..";

import { getInvoices } from "../../actions/invoices_action";
import { getMenus } from "../../actions/menus_action";
import {
  getUIFilterYear,
  getUIFilterMonth,
  getUIAccountSelectedGST,
} from "../../actions/ui_action";
import {
  retrieveInvoice,
  retrieveInvoiceGST,
} from "../../actions/invoice_action";

class InvoicesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.options = {
      sortIndicator: false,
    };
  }

  idFormatter = (cell, row) =>
    this.props.hasGST === "Y"
      ? `ISO/${row.year}/${cell}`
      : `${row.year}/${cell}`;

  dateInvoiceFormatter = (cell, row) =>
    row.month && row.year ? `${row.month}/${row.year}` : null;

  statusFormatter = (cell, row) => {
    let status = "Partial";
    if (row.balance === 0) {
      status = "Completed";
    } else if (row.balance === row.totalSales) {
      status = "None";
    }
    return (
      <div id={`status${cell}`}>
        <span>{status}</span>
        {row.balance !== row.totalSales && (
          <UncontrolledTooltip
            placement="left"
            target={`status${cell}`}
            delay={0}
          >
            <br />
            {row.payments.map((payment) => (
              <React.Fragment key={`payment${cell}`}>
                <div>
                  Payment: {payment.payment && payment.payment.description}
                </div>
                <div>
                  Date : {moment.unix(payment.date).format("DD/MM/YYYY")}
                </div>
                <div>Amount : ${payment.amount}</div>
                <br />
              </React.Fragment>
            ))}
            {row.credits.map((credit) => (
              <React.Fragment key={`credit${cell}`}>
                <div>Credit: {credit.credit.description}</div>
                <div>
                  Date : {moment.unix(credit.date).format("DD/MM/YYYY")}
                </div>
                <div>Amount : ${credit.credit.amount}</div>
                <br />
              </React.Fragment>
            ))}
          </UncontrolledTooltip>
        )}
      </div>
    );
  };

  salesFormatter = (cell) => (cell ? `$${cell.toFixed(2)}` : `$0.00`);

  actionFormatter = (cell, row) => (
    <div>
      <React.Fragment>
        <Button
          id={`viewInvoice${cell}`}
          round
          icon
          iconMini
          neutral
          color="info"
          onClick={() => {
            if (row.hasGST) {
              console.log("View GST Invoice: ", cell);
              this.props.retrieveInvoiceGST(cell, (options) =>
                this.props.notifyFunc(options)
              );
            } else {
              console.log("View Invoice: ", cell);
              this.props.retrieveInvoice(cell, (options) =>
                this.props.notifyFunc(options)
              );
            }
          }}
        >
          <i className="now-ui-icons files_paper" />
        </Button>
        <UncontrolledTooltip
          placement="left"
          target={`viewInvoice${cell}`}
          delay={0}
        >
          View Invoice
        </UncontrolledTooltip>
      </React.Fragment>
    </div>
  );

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.invoices.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">Invoices</CardTitle>
        </CardHeader>
        <CardBody style={{ paddingBottom: 0 }}>
          {this.props.filterYear < moment().year() ||
          (this.props.filterYear === moment().year() &&
            this.props.filterMonth <= moment().month() + 1) ? (
            <BootstrapTable
              data={this.props.invoices}
              options={this.options}
              tableHeaderClass="table-column-header"
              tableBodyClass="table-body-myclass"
              bordered={false}
              search={true}
              height="400"
              scrollTop={"Bottom"}
            >
              <TableHeaderColumn
                dataField="number"
                dataFormat={this.idFormatter}
                isKey
                searchable={true}
                editable={false}
              >
                ID
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="name"
                dataSort
                editable={false}
                tdStyle={{ whiteSpace: "normal" }}
              >
                Invoice
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="totalSales"
                dataFormat={this.salesFormatter}
                dataSort
                searchable={false}
                editable={false}
                headerAlign="center"
                dataAlign="center"
                thStyle={{ whiteSpace: "normal" }}
                width="150"
              >
                Amount ($)
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="balance"
                dataFormat={this.salesFormatter}
                dataSort
                searchable={false}
                editable={false}
                headerAlign="center"
                dataAlign="center"
                thStyle={{ whiteSpace: "normal" }}
                width="150"
              >
                Balance ($)
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="id"
                dataFormat={this.dateInvoiceFormatter}
                dataSort
                editable={false}
                headerAlign="center"
                dataAlign="center"
                tdStyle={{ whiteSpace: "normal" }}
                width="150"
              >
                Date
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="id"
                dataFormat={this.statusFormatter}
                dataSort
                editable={false}
                tdStyle={{ whiteSpace: "normal" }}
                width="150"
              >
                Status
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="id"
                dataFormat={this.actionFormatter}
                editable={false}
                headerAlign="right"
                dataAlign="right"
                width="50"
              />
            </BootstrapTable>
          ) : (
            <div>Selected Year / Month must be earlier than current Date</div>
          )}
        </CardBody>
        <CardFooter className="card-footer-margin">
          {(this.props.filterYear < moment().year() ||
            (this.props.filterYear === moment().year() &&
              this.props.filterMonth <= moment().month() + 1)) && (
            <CardFooterStats>
              {[
                {
                  i: "now-ui-icons travel_info",
                  t: `Total: ${this.props.invoices.length} Invoices`,
                },
              ]}
            </CardFooterStats>
          )}
        </CardFooter>
      </Card>
    );
  }
}

export default connect(
  createStructuredSelector({
    invoices: getInvoices,
    menus: getMenus,
    hasGST: getUIAccountSelectedGST,
    filterYear: getUIFilterYear,
    filterMonth: getUIFilterMonth,
  }),
  {
    retrieveInvoice,
    retrieveInvoiceGST,
  }
)(InvoicesComponent);
