import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  FormGroup,
  Label,
  Input
} from "reactstrap";
import {
  getUIFilterSetMenuItem,
  onFilterSetMenuItem
} from '../../../../actions/ui_action';

import {
  getMenuItems,
} from '../../../../actions/menuitems_action';

const CustomDropdownSetMenuItem = (props) => (
  <FormGroup>
    <Input
      type="select"
      name="select"
      id="filterMenu"
      value={props.menuItem}
      onChange={event => props.onFilterSetMenuItem(parseInt(event.target.value))}
    >
      {
        props.menuItems.map(
          item => <option value={item.id} key={item.id}>{item.name}</option>
        )
      }
    </Input>
  </FormGroup>
);

export default connect(
  createStructuredSelector({
    menuItems: getMenuItems,
    menuItem: getUIFilterSetMenuItem,
  }),
  {
    onFilterSetMenuItem,
  })(CustomDropdownSetMenuItem);
