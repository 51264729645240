import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Label
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Button, FormInputs } from "../";

import { getDebit, createDebit, updateDebit } from "../../actions/debit_action";
import {
  getUIDebitIsNew,
  getUIDebitIsEdit,
  onCancelDebitForm
} from "../../actions/ui_action";
import { notifyError } from "../NotificationOptions";

class DebitFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.debitIsNew
      ? {
          date: null,
          month: null,
          year: null,
          description: "",
          amount: 0
        }
      : {
          ...this.props.debit,
          customer: this.props.debit.customer && this.props.debit.customer.id
        };
  }

  validateForm = () => {
    return !!(this.state.date && this.state.description && this.state.amount);
  };

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.debit.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">
            Debit Note Details
          </CardTitle>
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-round btn-simple btn-icon"
              color="default"
            >
              <i className="now-ui-icons arrows-1_minimal-down" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() =>
                  this.props.updateDebit(
                    Object.assign({}, this.state, { void: true }),
                    options => this.props.notifyFunc(options)
                  )
                }
              >
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </CardHeader>
        <CardBody>
          <form>
            <div className="row">
              <div className="col-md-12 pr-3">
                <Label for="date">Date</Label>
                <div className="datepicker-wrap-myclass">
                  <DatePicker
                    id="date"
                    dateFormat="dd/MM/yyyy"
                    disabled={this.props.debitIsEdit}
                    selected={
                      this.state.date
                        ? moment.unix(this.state.date).toDate()
                        : null
                    }
                    onChange={value => {
                      this.setState({
                        date: value ? moment(value).unix() : null
                      });
                      this.setState({
                        month: value ? moment(value).month() + 1 : null
                      });
                      this.setState({
                        year: value ? moment(value).year() : null
                      });
                    }}
                    isClearable={this.props.debitIsNew}
                  />
                </div>
              </div>
            </div>
            <FormInputs
              ncols={["col-md-12 pr-3"]}
              proprieties={[
                {
                  label: "Description",
                  inputProps: {
                    type: "textarea",
                    value: this.state.description,
                    placeholder: "Item Description",
                    onChange: event =>
                      this.setState({ description: event.target.value })
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-12 pr-3"]}
              proprieties={[
                {
                  label: "Amount ($)",
                  inputProps: {
                    type: "number",
                    step: "0.01",
                    placeholder: "Amount",
                    value: this.state.amount,
                    disabled: this.props.debitIsEdit,
                    onChange: event =>
                      this.setState({
                        amount: event.target.value
                          ? parseFloat(event.target.value)
                          : ""
                      })
                  }
                }
              ]}
            />
          </form>
        </CardBody>
        <CardFooter className="card-footer-buttons-padding">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Button
              color="primary"
              style={{ width: "20%" }}
              onClick={
                this.validateForm()
                  ? this.props.debitIsEdit
                    ? () =>
                        this.props.updateDebit(this.state, options =>
                          this.props.notifyFunc(options)
                        )
                    : () => {
                        this.props.createDebit(this.state, options =>
                          this.props.notifyFunc(options)
                        );
                      }
                  : () =>
                      this.props.notifyFunc(
                        notifyError(`Please fill up all fields`)
                      )
              }
            >
              Save
            </Button>
            <Button
              color="secondary"
              style={{ width: "75%" }}
              onClick={() => {
                this.props.onCancelDebitForm();
              }}
            >
              Cancel
            </Button>
          </div>
        </CardFooter>
      </Card>
    );
  }
}

export default connect(
  createStructuredSelector({
    debit: getDebit,
    debitIsNew: getUIDebitIsNew,
    debitIsEdit: getUIDebitIsEdit
  }),
  {
    createDebit,
    updateDebit,
    onCancelDebitForm
  }
)(DebitFormComponent);
