import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Label,
  Input,
  Table
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Button, CardFooterStats, FormInputs } from "../";

import { getOrder, createOrder, updateOrder } from "../../actions/order_action";

import { getCustomer } from "../../actions/customer_action";
import { getUsers } from "../../actions/users_action";
import { getMenus } from "../../actions/menus_action";
import { getMenuItems } from "../../actions/menuitems_action";
import { getTrips } from "../../actions/trips_action";
import { getRate } from "../../actions/rates_action";

import {
  getUIOrderSelectedID,
  getUIOrderIsNew,
  getUIOrderIsEdit,
  editOrder,
  onCancelOrderForm
} from "../../actions/ui_action";

import { notifyError } from "../../components/NotificationOptions";

class OrderFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.orderIsNew
      ? {
          trip: 1,
          name: "",
          identification: "",
          email: "",
          contact: "",
          hpContact: "",
          address: "",
          country: "Singapore",
          postal: "",
          remarks: "",
          menu: 1,
          rate: "",
          am_rate: parseFloat(this.props.rates.am_rate),
          pm_rate: parseFloat(this.props.rates.pm_rate),
          am_pax: "",
          pm_pax: "",
          free_pax: 0,
          distance_scale: 1,
          am_menu_A_monday: "",
          am_menu_B_monday: "",
          pm_menu_A_monday: "",
          pm_menu_B_monday: "",
          am_menu_A_tuesday: "",
          am_menu_B_tuesday: "",
          pm_menu_A_tuesday: "",
          pm_menu_B_tuesday: "",
          am_menu_A_wednesday: "",
          am_menu_B_wednesday: "",
          pm_menu_A_wednesday: "",
          pm_menu_B_wednesday: "",
          am_menu_A_thursday: "",
          am_menu_B_thursday: "",
          pm_menu_A_thursday: "",
          pm_menu_B_thursday: "",
          am_menu_A_friday: "",
          am_menu_B_friday: "",
          pm_menu_A_friday: "",
          pm_menu_B_friday: "",
          am_menu_A_saturday: "",
          am_menu_B_saturday: "",
          pm_menu_A_saturday: "",
          pm_menu_B_saturday: "",
          am_menu_A_sunday: "",
          am_menu_B_sunday: "",
          pm_menu_A_sunday: "",
          pm_menu_B_sunday: "",
          effectiveDate: "",
          expiryDate: "",
          terms: "Cash",
          allowPrint: "Y"
        }
      : {
          ...this.props.order,
          customer: this.props.order.customer && this.props.order.customer.id,
          trip: this.props.order.trip && this.props.order.trip.id,
          menu: this.props.order.menu && this.props.order.menu.id,
          user: this.props.order.user && this.props.order.user.id
        };
  }

  validateForm = () => {
    return !!(
      this.state.name &&
      this.state.identification &&
      this.state.hpContact &&
      this.state.address &&
      (this.state.am_pax || this.state.am_pax === 0) &&
      (this.state.pm_pax || this.state.pm_pax === 0) &&
      this.state.rate &&
      this.state.am_rate &&
      this.state.pm_rate &&
      this.state.effectiveDate &&
      this.validateMenuRow(
        this.state.am_menu_A_monday,
        this.state.am_menu_B_monday,
        this.state.am_pax
      ) &&
      this.validateMenuRow(
        this.state.pm_menu_A_monday,
        this.state.pm_menu_B_monday,
        this.state.pm_pax
      ) &&
      this.validateMenuRow(
        this.state.am_menu_A_tuesday,
        this.state.am_menu_B_tuesday,
        this.state.am_pax
      ) &&
      this.validateMenuRow(
        this.state.pm_menu_A_tuesday,
        this.state.pm_menu_B_tuesday,
        this.state.pm_pax
      ) &&
      this.validateMenuRow(
        this.state.am_menu_A_wednesday,
        this.state.am_menu_B_wednesday,
        this.state.am_pax
      ) &&
      this.validateMenuRow(
        this.state.pm_menu_A_wednesday,
        this.state.pm_menu_B_wednesday,
        this.state.pm_pax
      ) &&
      this.validateMenuRow(
        this.state.am_menu_A_thursday,
        this.state.am_menu_B_thursday,
        this.state.am_pax
      ) &&
      this.validateMenuRow(
        this.state.pm_menu_A_thursday,
        this.state.pm_menu_B_thursday,
        this.state.pm_pax
      ) &&
      this.validateMenuRow(
        this.state.am_menu_A_friday,
        this.state.am_menu_B_friday,
        this.state.am_pax
      ) &&
      this.validateMenuRow(
        this.state.pm_menu_A_friday,
        this.state.pm_menu_B_friday,
        this.state.pm_pax
      ) &&
      this.validateMenuRow(
        this.state.am_menu_A_saturday,
        this.state.am_menu_B_saturday,
        this.state.am_pax
      ) &&
      this.validateMenuRow(
        this.state.pm_menu_A_saturday,
        this.state.pm_menu_B_saturday,
        this.state.pm_pax
      ) &&
      this.validateMenuRow(
        this.state.am_menu_A_sunday,
        this.state.am_menu_B_sunday,
        this.state.am_pax
      ) &&
      this.validateMenuRow(
        this.state.pm_menu_A_sunday,
        this.state.pm_menu_B_sunday,
        this.state.pm_pax
      )
    );
  };

  validateMenuRow = (am, pm, total) => {
    return (am === 0 && pm === 0) || (am >= 0 && pm >= 0 && total === am + pm);
  };

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.order.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">Order</CardTitle>
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-round btn-simple btn-icon"
              color="default"
            >
              <i className="now-ui-icons arrows-1_minimal-down" />
            </DropdownToggle>
            <DropdownMenu right>
              {!(this.props.orderIsNew || this.props.orderIsEdit) ? (
                <DropdownItem onClick={() => this.props.editOrder()}>
                  Edit
                </DropdownItem>
              ) : (
                <DropdownItem
                  onClick={() =>
                    this.setState({
                      name: this.props.customer.name,
                      email: this.props.customer.email,
                      contact: this.props.customer.contact,
                      address: this.props.customer.address,
                      country: this.props.customer.country,
                      postal: this.props.customer.postal
                    })
                  }
                >
                  Copy from Customer Details
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </CardHeader>
        <CardBody>
          <form>
            <FormInputs
              ncols={["col-md-12 pr-3"]}
              proprieties={[
                {
                  label: "Order Name",
                  inputProps: {
                    type: "text",
                    disabled: !(
                      this.props.orderIsEdit || this.props.orderIsNew
                    ),
                    value: this.state.name,
                    onChange: event =>
                      this.setState({ name: event.target.value }),
                    style: this.state.name ? null : { border: "2px solid red" }
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-6 pr-3", "col-md-6 pr-3"]}
              proprieties={[
                {
                  label: "Email address",
                  inputProps: {
                    placeholder: "Email",
                    disabled: !(
                      this.props.orderIsEdit || this.props.orderIsNew
                    ),
                    value: this.state.email,
                    onChange: event =>
                      this.setState({ email: event.target.value })
                  }
                },
                {
                  label: "ID Number",
                  inputProps: {
                    placeholder: "NRIC / FIN / Work Permit",
                    disabled: !(
                      this.props.orderIsEdit || this.props.orderIsNew
                    ),
                    value: this.state.identification,
                    onChange: event =>
                      this.setState({ identification: event.target.value }),
                    style: this.state.identification
                      ? null
                      : { border: "2px solid red" }
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-6 pr-3", "col-md-6 pr-3"]}
              proprieties={[
                {
                  label: "Office Contact",
                  inputProps: {
                    type: "number",
                    placeholder: "Telephone Number",
                    disabled: !(
                      this.props.orderIsEdit || this.props.orderIsNew
                    ),
                    value: this.state.contact,
                    onChange: event =>
                      this.setState({ contact: event.target.value })
                  }
                },
                {
                  label: "HP. Contact",
                  inputProps: {
                    type: "number",
                    placeholder: "Handphone Number",
                    disabled: !(
                      this.props.orderIsEdit || this.props.orderIsNew
                    ),
                    value: this.state.hpContact,
                    onChange: event =>
                      this.setState({ hpContact: event.target.value }),
                    style: this.state.hpContact
                      ? null
                      : { border: "2px solid red" }
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-12 pr-3"]}
              proprieties={[
                {
                  label: "Address",
                  inputProps: {
                    type: "text",
                    placeholder: "Delivery Address",
                    disabled: !(
                      this.props.orderIsEdit || this.props.orderIsNew
                    ),
                    value: this.state.address,
                    onChange: event =>
                      this.setState({ address: event.target.value }),
                    style: this.state.address
                      ? null
                      : { border: "2px solid red" }
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-6 pr-3", "col-md-6 pr-3"]}
              proprieties={[
                {
                  label: "Country",
                  inputProps: {
                    type: "text",
                    placeholder: "Country",
                    disabled: !(
                      this.props.orderIsEdit || this.props.orderIsNew
                    ),
                    value: this.state.country,
                    onChange: event =>
                      this.setState({ country: event.target.value })
                  }
                },
                {
                  label: "Postal Code",
                  inputProps: {
                    type: "number",
                    placeholder: "Postal Code",
                    disabled: !(
                      this.props.orderIsEdit || this.props.orderIsNew
                    ),
                    value: this.state.postal,
                    onChange: event =>
                      this.setState({ postal: event.target.value })
                  }
                }
              ]}
            />
            <div className="row" style={{ marginBottom: "10px" }}>
              <div className="col-md-4 pr-3">
                <Label for="orderTypeSelect">Cuisine</Label>
                <Input
                  id="orderTypeSelect"
                  type="select"
                  name="select"
                  value={this.state.menu}
                  onChange={event =>
                    this.setState({ menu: parseInt(event.target.value) })
                  }
                  disabled={!(this.props.orderIsEdit || this.props.orderIsNew)}
                  style={{ height: "35px", textIndent: "10px" }}
                >
                  {this.props.menus.map(menu => (
                    <option value={menu.id} key={menu.id}>
                      {menu.name}
                    </option>
                  ))}
                </Input>
              </div>
              <div className="col-md-4 pr-3">
                <Label for="orderTypeSelect">Free Pax (Adds to Menu B)</Label>
                <Input
                  id="freeTypeSelect"
                  type="select"
                  name="select"
                  value={this.state.free_pax}
                  onChange={event =>
                    this.setState({ free_pax: event.target.value })
                  }
                  disabled={!(this.props.orderIsEdit || this.props.orderIsNew)}
                  style={{ height: "35px", textIndent: "10px" }}
                >
                  {[...Array(21)].map((element, index) => (
                    <option value={index} key={index}>
                      {index}
                    </option>
                  ))}
                </Input>
              </div>
              <div className="col-md-4 pr-3">
                <Label for="tripTypeSelect">Trip</Label>
                <Input
                  id="tripTypeSelect"
                  type="select"
                  name="select"
                  value={this.state.trip}
                  onChange={event =>
                    this.setState({ trip: parseInt(event.target.value) })
                  }
                  disabled={!(this.props.orderIsEdit || this.props.orderIsNew)}
                  style={{ height: "35px", textIndent: "10px" }}
                >
                  {this.props.trips.map(trip => (
                    <option value={trip.id} key={trip.id}>{`${trip.number} ( ${
                      trip.license
                    } )`}</option>
                  ))}
                </Input>
              </div>
            </div>
            <FormInputs
              ncols={["col-md-6 pr-3", "col-md-6 pr-3"]}
              proprieties={[
                {
                  label: "Pax (A.M.)",
                  inputProps: {
                    type: "number",
                    placeholder: "No. of People",
                    disabled: !(
                      this.props.orderIsEdit || this.props.orderIsNew
                    ),
                    value: this.state.am_pax,
                    onChange: event =>
                      this.setState({
                        am_pax: event.target.value
                          ? parseInt(event.target.value)
                          : ""
                      }),
                    style:
                      this.state.am_pax === "" || this.state.am_pax < 0
                        ? { border: "2px solid red" }
                        : null
                  }
                },
                {
                  label: "Pax (P.M.)",
                  inputProps: {
                    type: "number",
                    placeholder: "No. of People",
                    disabled: !(
                      this.props.orderIsEdit || this.props.orderIsNew
                    ),
                    value: this.state.pm_pax,
                    onChange: event =>
                      this.setState({
                        pm_pax: event.target.value
                          ? parseInt(event.target.value)
                          : ""
                      }),
                    style:
                      this.state.pm_pax === "" || this.state.pm_pax < 0
                        ? { border: "2px solid red" }
                        : null
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-4 pr-3", "col-md-4 pr-3", "col-md-4 pr-3"]}
              proprieties={[
                {
                  label: "Rate ($)",
                  inputProps: {
                    type: "number",
                    step: "0.01",
                    placeholder: "Meal Rate",
                    disabled: !(
                      this.props.orderIsEdit || this.props.orderIsNew
                    ),
                    value: this.state.rate,
                    onChange: event =>
                      this.setState({
                        rate: event.target.value
                          ? parseFloat(event.target.value)
                          : ""
                      }),
                    style: this.state.rate ? null : { border: "2px solid red" }
                  }
                },
                {
                  label: "A.M Rate ($)",
                  inputProps: {
                    type: "number",
                    step: "0.01",
                    placeholder: "AM Meal Rate",
                    disabled: !(
                      this.props.orderIsEdit || this.props.orderIsNew
                    ),
                    value: this.state.am_rate,
                    onChange: event =>
                      this.setState({
                        am_rate: event.target.value
                          ? parseFloat(event.target.value)
                          : ""
                      }),
                    style: this.state.am_rate
                      ? null
                      : { border: "2px solid red" }
                  }
                },
                {
                  label: "P.M Rate ($)",
                  inputProps: {
                    type: "number",
                    step: "0.01",
                    placeholder: "PM Meal Rate",
                    disabled: !(
                      this.props.orderIsEdit || this.props.orderIsNew
                    ),
                    value: this.state.pm_rate,
                    onChange: event =>
                      this.setState({
                        pm_rate: event.target.value
                          ? parseFloat(event.target.value)
                          : ""
                      }),
                    style: this.state.pm_rate
                      ? null
                      : { border: "2px solid red" }
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-12 pr-3"]}
              proprieties={[
                {
                  label: "Remarks",
                  inputProps: {
                    type: "textarea",
                    placeholder: "Order Description / Remarks",
                    disabled: !(
                      this.props.orderIsEdit || this.props.orderIsNew
                    ),
                    value: this.state.remarks,
                    onChange: event =>
                      this.setState({ remarks: event.target.value })
                  }
                }
              ]}
            />
            <div
              className="row"
              style={
                this.state.expiryDate &&
                this.state.effectiveDate > this.state.expiryDate
                  ? { border: "2px solid red" }
                  : null
              }
            >
              <div className="col-md-6 pr-3">
                <Label for="effectiveTypeDate">Effective Date</Label>
                <div className="datepicker-wrap-myclass">
                  <DatePicker
                    id="effectiveTypeDate"
                    dateFormat="dd/MM/yyyy"
                    minDate={
                      this.props.orderIsNew
                        ? moment()
                            .add(1, "days")
                            .toDate()
                        : null
                    }
                    selected={
                      this.state.effectiveDate
                        ? moment.unix(this.state.effectiveDate).toDate()
                        : null
                    }
                    onChange={value =>
                      this.setState({
                        effectiveDate: value ? moment(value).unix() : null,
                        expiryDate: null
                      })
                    }
                    isClearable={this.props.orderIsNew}
                    disabled={!this.props.orderIsNew}
                    className={
                      this.props.orderIsNew
                        ? this.state.effectiveDate
                          ? "datepicker-myclass"
                          : "datepicker-myclass-compulsory"
                        : "datepicker-myclass-disabled"
                    }
                    style={
                      this.state.effectiveDate
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 pr-3">
                <Label for="expiryTypeDate">Expiry Date</Label>
                <div className="datepicker-wrap-myclass">
                  <DatePicker
                    id="expiryTypeDate"
                    dateFormat="dd/MM/yyyy"
                    minDate={
                      moment().unix() < this.state.effectiveDate
                        ? moment.unix(this.state.effectiveDate).toDate()
                        : moment().toDate()
                    }
                    selected={
                      this.state.expiryDate
                        ? moment.unix(this.state.expiryDate).toDate()
                        : null
                    }
                    onChange={value =>
                      this.setState({
                        expiryDate: value ? moment(value).unix() : null
                      })
                    }
                    isClearable={
                      this.props.orderIsEdit || this.props.orderIsNew
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew) ||
                      !this.state.effectiveDate
                    }
                    className={
                      !(this.props.orderIsEdit || this.props.orderIsNew) ||
                      !this.state.effectiveDate
                        ? "datepicker-myclass-disabled"
                        : "datepicker-myclass"
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-md-4 pr-3">
                <Label for="distanceTypeSelect">
                  Distance Scale (1: Nearest - 10: Furthest)
                </Label>
                <Input
                  id="distanceTypeSelect"
                  type="select"
                  name="select"
                  value={this.state.distance_scale}
                  onChange={event =>
                    this.setState({ distance_scale: event.target.value })
                  }
                  disabled={!(this.props.orderIsEdit || this.props.orderIsNew)}
                  style={{ height: "35px", textIndent: "10px" }}
                >
                  {[...Array(10)].map((x, i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </Input>
              </div>
              <div className="col-md-4 pr-3">
                <Label for="termsTypeSelect">Payment Terms (Invoice)</Label>
                <Input
                  id="termsTypeSelect"
                  type="select"
                  name="select"
                  value={this.state.terms}
                  onChange={event =>
                    this.setState({ terms: event.target.value })
                  }
                  disabled={!(this.props.orderIsEdit || this.props.orderIsNew)}
                  style={{ height: "35px", textIndent: "10px" }}
                >
                  <option value="Cash">Cash</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Credit (30 Days)">Credit (30 Days)</option>
                  <option value="Credit (60 Days)">Credit (60 Days)</option>
                </Input>
              </div>
              <div className="col-md-4 pr-3">
                <Label for="allowPrintSelect">Allow Sticker Printing</Label>
                <Input
                  id="allowPrintSelect"
                  type="select"
                  name="select"
                  value={this.state.allowPrint}
                  onChange={event =>
                    this.setState({ allowPrint: event.target.value })
                  }
                  disabled={!(this.props.orderIsEdit || this.props.orderIsNew)}
                  style={{ height: "35px", textIndent: "10px" }}
                >
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </Input>
              </div>
            </div>
          </form>
        </CardBody>
        <CardHeader>
          <h5 className="title">Order Menu</h5>
        </CardHeader>
        <CardBody>
          <Table responsive>
            <thead className="text-primary" style={{ fontSize: "16px" }}>
              <tr>
                <th colSpan={2}>Day</th>
                <th>Pax A</th>
                <th>Menu A</th>
                <th>Pax B</th>
                <th>Menu B</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width={100}>Monday</td>
                <td width={100}>(A.M.)</td>
                <td width={120}>
                  <Input
                    type="number"
                    name="amPax"
                    id="amPax"
                    value={this.state.am_menu_A_monday}
                    onChange={event =>
                      this.setState({
                        am_menu_A_monday:
                          event.target.value && parseInt(event.target.value),
                        am_menu_B_monday:
                          event.target.value &&
                          this.state.am_pax - parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.am_menu_A_monday,
                        this.state.am_menu_B_monday,
                        this.state.am_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td width={150}>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .am_menu_A_monday.name}
                </td>
                <td width={120}>
                  <Input
                    type="number"
                    name="amPax"
                    id="amPax"
                    value={this.state.am_menu_B_monday}
                    onChange={event =>
                      this.setState({
                        am_menu_B_monday:
                          event.target.value && parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.am_menu_A_monday,
                        this.state.am_menu_B_monday,
                        this.state.am_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td width={150}>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .am_menu_B_monday.name}
                </td>
              </tr>
              <tr>
                <td />
                <td>(P.M.)</td>
                <td>
                  <Input
                    type="number"
                    name="pmPax"
                    id="pmPax"
                    value={this.state.pm_menu_A_monday}
                    onChange={event =>
                      this.setState({
                        pm_menu_A_monday:
                          event.target.value && parseInt(event.target.value),
                        pm_menu_B_monday:
                          event.target.value &&
                          this.state.pm_pax - parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.pm_menu_A_monday,
                        this.state.pm_menu_B_monday,
                        this.state.pm_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .pm_menu_A_monday.name}
                </td>
                <td>
                  <Input
                    type="number"
                    name="pmPax"
                    id="pmPax"
                    value={this.state.pm_menu_B_monday}
                    onChange={event =>
                      this.setState({
                        pm_menu_B_monday:
                          event.target.value && parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.pm_menu_A_monday,
                        this.state.pm_menu_B_monday,
                        this.state.pm_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .pm_menu_B_monday.name}
                </td>
              </tr>

              <tr>
                <td>Tuesday</td>
                <td>(A.M.)</td>
                <td>
                  <Input
                    type="number"
                    name="amPax"
                    id="amPax"
                    value={this.state.am_menu_A_tuesday}
                    onChange={event =>
                      this.setState({
                        am_menu_A_tuesday:
                          event.target.value && parseInt(event.target.value),
                        am_menu_B_tuesday:
                          event.target.value &&
                          this.state.am_pax - parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.am_menu_A_tuesday,
                        this.state.am_menu_B_tuesday,
                        this.state.am_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .am_menu_A_tuesday.name}
                </td>
                <td>
                  <Input
                    type="number"
                    name="amPax"
                    id="amPax"
                    value={this.state.am_menu_B_tuesday}
                    onChange={event =>
                      this.setState({
                        am_menu_B_tuesday:
                          event.target.value && parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.am_menu_A_tuesday,
                        this.state.am_menu_B_tuesday,
                        this.state.am_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .am_menu_B_tuesday.name}
                </td>
              </tr>
              <tr>
                <td />
                <td>(P.M.)</td>
                <td>
                  <Input
                    type="number"
                    name="pmPax"
                    id="pmPax"
                    value={this.state.pm_menu_A_tuesday}
                    onChange={event =>
                      this.setState({
                        pm_menu_A_tuesday:
                          event.target.value && parseInt(event.target.value),
                        pm_menu_B_tuesday:
                          event.target.value &&
                          this.state.pm_pax - parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.pm_menu_A_tuesday,
                        this.state.pm_menu_B_tuesday,
                        this.state.pm_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .pm_menu_A_tuesday.name}
                </td>
                <td>
                  <Input
                    type="number"
                    name="pmPax"
                    id="pmPax"
                    value={this.state.pm_menu_B_tuesday}
                    onChange={event =>
                      this.setState({
                        pm_menu_B_tuesday:
                          event.target.value && parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.pm_menu_A_tuesday,
                        this.state.pm_menu_B_tuesday,
                        this.state.pm_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .pm_menu_B_tuesday.name}
                </td>
              </tr>
              <tr>
                <td>Wednesday</td>
                <td>(A.M.)</td>
                <td>
                  <Input
                    type="number"
                    name="amPax"
                    id="amPax"
                    value={this.state.am_menu_A_wednesday}
                    onChange={event =>
                      this.setState({
                        am_menu_A_wednesday:
                          event.target.value && parseInt(event.target.value),
                        am_menu_B_wednesday:
                          event.target.value &&
                          this.state.am_pax - parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.am_menu_A_wednesday,
                        this.state.am_menu_B_wednesday,
                        this.state.am_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .am_menu_A_wednesday.name}
                </td>
                <td>
                  <Input
                    type="number"
                    name="amPax"
                    id="amPax"
                    value={this.state.am_menu_B_wednesday}
                    onChange={event =>
                      this.setState({
                        am_menu_B_wednesday:
                          event.target.value && parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.am_menu_A_wednesday,
                        this.state.am_menu_B_wednesday,
                        this.state.am_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .am_menu_B_wednesday.name}
                </td>
              </tr>
              <tr>
                <td />
                <td>(P.M.)</td>
                <td>
                  <Input
                    type="number"
                    name="pmPax"
                    id="pmPax"
                    value={this.state.pm_menu_A_wednesday}
                    onChange={event =>
                      this.setState({
                        pm_menu_A_wednesday:
                          event.target.value && parseInt(event.target.value),
                        pm_menu_B_wednesday:
                          event.target.value &&
                          this.state.pm_pax - parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.pm_menu_A_wednesday,
                        this.state.pm_menu_B_wednesday,
                        this.state.pm_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .pm_menu_A_wednesday.name}
                </td>
                <td>
                  <Input
                    type="number"
                    name="pmPax"
                    id="pmPax"
                    value={this.state.pm_menu_B_wednesday}
                    onChange={event =>
                      this.setState({
                        pm_menu_B_wednesday:
                          event.target.value && parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.pm_menu_A_wednesday,
                        this.state.pm_menu_B_wednesday,
                        this.state.pm_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .pm_menu_B_wednesday.name}
                </td>
              </tr>

              <tr>
                <td>Thursday</td>
                <td>(A.M.)</td>
                <td>
                  <Input
                    type="number"
                    name="amPax"
                    id="amPax"
                    value={this.state.am_menu_A_thursday}
                    onChange={event =>
                      this.setState({
                        am_menu_A_thursday:
                          event.target.value && parseInt(event.target.value),
                        am_menu_B_thursday:
                          event.target.value &&
                          this.state.am_pax - parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.am_menu_A_thursday,
                        this.state.am_menu_B_thursday,
                        this.state.am_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .am_menu_A_thursday.name}
                </td>
                <td>
                  <Input
                    type="number"
                    name="amPax"
                    id="amPax"
                    value={this.state.am_menu_B_thursday}
                    onChange={event =>
                      this.setState({
                        am_menu_B_thursday:
                          event.target.value && parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.am_menu_A_thursday,
                        this.state.am_menu_B_thursday,
                        this.state.am_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .am_menu_B_thursday.name}
                </td>
              </tr>
              <tr>
                <td />
                <td>(P.M.)</td>
                <td>
                  <Input
                    type="number"
                    name="pmPax"
                    id="pmPax"
                    value={this.state.pm_menu_A_thursday}
                    onChange={event =>
                      this.setState({
                        pm_menu_A_thursday:
                          event.target.value && parseInt(event.target.value),
                        pm_menu_B_thursday:
                          event.target.value &&
                          this.state.pm_pax - parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.pm_menu_A_thursday,
                        this.state.pm_menu_B_thursday,
                        this.state.pm_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .pm_menu_A_thursday.name}
                </td>
                <td>
                  <Input
                    type="number"
                    name="pmPax"
                    id="pmPax"
                    value={this.state.pm_menu_B_thursday}
                    onChange={event =>
                      this.setState({
                        pm_menu_B_thursday:
                          event.target.value && parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.pm_menu_A_thursday,
                        this.state.pm_menu_B_thursday,
                        this.state.pm_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .pm_menu_B_thursday.name}
                </td>
              </tr>

              <tr>
                <td>Friday</td>
                <td>(A.M.)</td>
                <td>
                  <Input
                    type="number"
                    name="amPax"
                    id="amPax"
                    value={this.state.am_menu_A_friday}
                    onChange={event =>
                      this.setState({
                        am_menu_A_friday:
                          event.target.value && parseInt(event.target.value),
                        am_menu_B_friday:
                          event.target.value &&
                          this.state.am_pax - parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.am_menu_A_friday,
                        this.state.am_menu_B_friday,
                        this.state.am_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .am_menu_A_friday.name}
                </td>
                <td>
                  <Input
                    type="number"
                    name="amPax"
                    id="amPax"
                    value={this.state.am_menu_B_friday}
                    onChange={event =>
                      this.setState({
                        am_menu_B_friday:
                          event.target.value && parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.am_menu_A_friday,
                        this.state.am_menu_B_friday,
                        this.state.am_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .am_menu_B_friday.name}
                </td>
              </tr>
              <tr>
                <td />
                <td>(P.M.)</td>
                <td>
                  <Input
                    type="number"
                    name="pmPax"
                    id="pmPax"
                    value={this.state.pm_menu_A_friday}
                    onChange={event =>
                      this.setState({
                        pm_menu_A_friday:
                          event.target.value && parseInt(event.target.value),
                        pm_menu_B_friday:
                          event.target.value &&
                          this.state.pm_pax - parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.pm_menu_A_friday,
                        this.state.pm_menu_B_friday,
                        this.state.pm_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .pm_menu_A_friday.name}
                </td>
                <td>
                  <Input
                    type="number"
                    name="pmPax"
                    id="pmPax"
                    value={this.state.pm_menu_B_friday}
                    onChange={event =>
                      this.setState({
                        pm_menu_B_friday:
                          event.target.value && parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.pm_menu_A_friday,
                        this.state.pm_menu_B_friday,
                        this.state.pm_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .pm_menu_B_friday.name}
                </td>
              </tr>

              <tr>
                <td>Saturday</td>
                <td>(A.M.)</td>
                <td>
                  <Input
                    type="number"
                    name="amPax"
                    id="amPax"
                    value={this.state.am_menu_A_saturday}
                    onChange={event =>
                      this.setState({
                        am_menu_A_saturday:
                          event.target.value && parseInt(event.target.value),
                        am_menu_B_saturday:
                          event.target.value &&
                          this.state.am_pax - parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.am_menu_A_saturday,
                        this.state.am_menu_B_saturday,
                        this.state.am_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .am_menu_A_saturday.name}
                </td>
                <td>
                  <Input
                    type="number"
                    name="amPax"
                    id="amPax"
                    value={this.state.am_menu_B_saturday}
                    onChange={event =>
                      this.setState({
                        am_menu_B_saturday:
                          event.target.value && parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.am_menu_A_saturday,
                        this.state.am_menu_B_saturday,
                        this.state.am_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .am_menu_B_saturday.name}
                </td>
              </tr>
              <tr>
                <td />
                <td>(P.M.)</td>
                <td>
                  <Input
                    type="number"
                    name="pmPax"
                    id="pmPax"
                    value={this.state.pm_menu_A_saturday}
                    onChange={event =>
                      this.setState({
                        pm_menu_A_saturday:
                          event.target.value && parseInt(event.target.value),
                        pm_menu_B_saturday:
                          event.target.value &&
                          this.state.pm_pax - parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.pm_menu_A_saturday,
                        this.state.pm_menu_B_saturday,
                        this.state.pm_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .pm_menu_A_saturday.name}
                </td>
                <td>
                  <Input
                    type="number"
                    name="pmPax"
                    id="pmPax"
                    value={this.state.pm_menu_B_saturday}
                    onChange={event =>
                      this.setState({
                        pm_menu_B_saturday:
                          event.target.value && parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.pm_menu_A_saturday,
                        this.state.pm_menu_B_saturday,
                        this.state.pm_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .pm_menu_B_saturday.name}
                </td>
              </tr>

              <tr>
                <td>Sunday</td>
                <td>(A.M.)</td>
                <td>
                  <Input
                    type="number"
                    name="amPax"
                    id="amPax"
                    value={this.state.am_menu_A_sunday}
                    onChange={event =>
                      this.setState({
                        am_menu_A_sunday:
                          event.target.value && parseInt(event.target.value),
                        am_menu_B_sunday:
                          event.target.value &&
                          this.state.am_pax - parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.am_menu_A_sunday,
                        this.state.am_menu_B_sunday,
                        this.state.am_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .am_menu_A_sunday.name}
                </td>
                <td>
                  <Input
                    type="number"
                    name="amPax"
                    id="amPax"
                    value={this.state.am_menu_B_sunday}
                    onChange={event =>
                      this.setState({
                        am_menu_B_sunday:
                          event.target.value && parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.am_menu_A_sunday,
                        this.state.am_menu_B_sunday,
                        this.state.am_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .am_menu_B_sunday.name}
                </td>
              </tr>
              <tr>
                <td />
                <td>(P.M.)</td>
                <td>
                  <Input
                    type="number"
                    name="pmPax"
                    id="pmPax"
                    value={this.state.pm_menu_A_sunday}
                    onChange={event =>
                      this.setState({
                        pm_menu_A_sunday:
                          event.target.value && parseInt(event.target.value),
                        pm_menu_B_sunday:
                          event.target.value &&
                          this.state.pm_pax - parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.pm_menu_A_sunday,
                        this.state.pm_menu_B_sunday,
                        this.state.pm_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .pm_menu_A_sunday.name}
                </td>
                <td>
                  <Input
                    type="number"
                    name="pmPax"
                    id="pmPax"
                    value={this.state.pm_menu_B_sunday}
                    onChange={event =>
                      this.setState({
                        pm_menu_B_sunday:
                          event.target.value && parseInt(event.target.value)
                      })
                    }
                    disabled={
                      !(this.props.orderIsEdit || this.props.orderIsNew)
                    }
                    style={
                      this.validateMenuRow(
                        this.state.pm_menu_A_sunday,
                        this.state.pm_menu_B_sunday,
                        this.state.pm_pax
                      )
                        ? null
                        : { border: "2px solid red" }
                    }
                  />
                </td>
                <td>
                  {this.state.menu &&
                    this.props.menus.find(menu => menu.id === this.state.menu)
                      .pm_menu_B_sunday.name}
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
        {this.props.orderIsEdit || this.props.orderIsNew ? (
          <CardFooter className="card-footer-buttons-padding">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Button
                color="primary"
                style={{ width: "20%" }}
                onClick={
                  this.validateForm()
                    ? this.props.orderIsEdit
                      ? () =>
                          this.props.updateOrder(this.state, options =>
                            this.props.notifyFunc(options)
                          )
                      : () => {
                          this.props.createOrder(this.state, options =>
                            this.props.notifyFunc(options)
                          );
                        }
                    : () =>
                        this.props.notifyFunc(
                          notifyError(`Please fill up all required fields`)
                        )
                }
              >
                Save
              </Button>
              <Button
                color="secondary"
                style={{ width: "75%" }}
                onClick={() => {
                  this.props.orderIsNew
                    ? this.props.history.push(`/customer-page`)
                    : this.props.onCancelOrderForm();
                }}
              >
                Cancel
              </Button>
            </div>
          </CardFooter>
        ) : (
          <CardFooter className="card-footer-margin">
            <CardFooterStats>
              {[
                {
                  i: "now-ui-icons travel_info",
                  t: `Last updated by ${
                    this.props.order.lastUpdateUser.username
                  } on ${moment
                    .unix(this.props.order.lastUpdateDate)
                    .format("DD/MM/YYYY hh:mm:ss A")}`
                }
              ]}
            </CardFooterStats>
          </CardFooter>
        )}
      </Card>
    );
  }
}

export default withRouter(
  connect(
    createStructuredSelector({
      customer: getCustomer,
      order: getOrder,
      orderSelectedID: getUIOrderSelectedID,
      orderIsNew: getUIOrderIsNew,
      orderIsEdit: getUIOrderIsEdit,
      users: getUsers,
      menus: getMenus,
      menuItems: getMenuItems,
      trips: getTrips,
      rates: getRate
    }),
    {
      editOrder,
      onCancelOrderForm,
      createOrder,
      updateOrder
    }
  )(OrderFormComponent)
);
