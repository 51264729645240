import React from "react";
import moment from 'moment';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {APPLICATION_FIRST_YEAR} from '../../../../config'
import {
  FormGroup,
  Label,
  Input
} from "reactstrap";
import {
  getUIFilterYear,
} from '../../../../actions/ui_action';

const CustomDropdownYear = (props) => {
  const years = [];
  for (let i = APPLICATION_FIRST_YEAR; i <= moment().year(); i++) {
    years.push(<option key={i}>{i}</option>)
  }
  return(
    <FormGroup>
      <Label for="filterYear">Year</Label>
      <Input
        type="select"
        name="select"
        id="filterYear"
        value={props.year}
        onChange={event => props.retrieveFunc(parseInt(event.target.value))}
      >
        {years}
      </Input>
    </FormGroup>
  );
};

export default connect(
  createStructuredSelector({
    year: getUIFilterYear
  }),
  {}
)(CustomDropdownYear);
