import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";
import { notifyError, notifySuccess } from "../components/NotificationOptions";
import { onCancelFoodPrepForm } from "./ui_action";

export const FETCH_FOODPREP = "foodprep/FETCH_FOODPREP";
export const SET_FOODPREP = "foodprep/SET_FOODPREP";
export const ERROR_FOODPREP = "foodprep/ERROR_FOODPREP";

export const getFoodPrep = state => state.foodprep.foodprep;
export const getFoodPrepLoading = state => state.foodprep.loading;
export const getFoodPrepError = state => state.foodprep.error;

export const fetchFoodPrep = () => ({
  type: FETCH_FOODPREP
});

export const setFoodPrep = value => ({
  type: SET_FOODPREP,
  value
});

export const errorFoodPrep = message => ({
  type: ERROR_FOODPREP,
  message
});

export const retrieveFoodPrep = () => async dispatch => {
  dispatch(fetchFoodPrep());
  try {
    const responsegetFoodPrep = await axios.get(
      `${DJANGO_CORE_ADDR}/foodpreptime/list/?id=1`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    dispatch(setFoodPrep(responsegetFoodPrep.data));
  } catch (error) {
    console.error(error);
    dispatch(errorFoodPrep("Some Error!"));
  }
};

export const updateFoodPrep = (foodprep, notifyFunc) => async dispatch => {
  try {
    const responsePutFoodPrep = await axios.patch(
      `${DJANGO_CORE_ADDR}/foodpreptime/update/`,
      Object.assign({}, foodprep),
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    console.log("Food Prep Time edited!");
    await notifyFunc(notifySuccess(`Food Prep Time update successful`));
    await dispatch(retrieveFoodPrep());
    await dispatch(onCancelFoodPrepForm());
  } catch (error) {
    console.error(error);
    dispatch(errorFoodPrep("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};
