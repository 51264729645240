import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
  CardFooter,
  CardTitle,
  Progress
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button, CardFooterStats } from "..";
import {
  getDailyStats,
  getDailyStatsLoading
} from "../../actions/dailystats_action";

class DailyStatsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.options = {
      sortIndicator: false
    };
  }

  amAFormatter = (cell, row) => `${cell} (${row.am_menu_A_item})`;
  amBFormatter = (cell, row) => `${cell} (${row.am_menu_B_item})`;
  amTotalFormatter = (cell, row) => row.am_menu_A_sum + row.am_menu_B_sum;
  pmAFormatter = (cell, row) => `${cell} (${row.pm_menu_A_item})`;
  pmBFormatter = (cell, row) => `${cell} (${row.pm_menu_B_item})`;
  pmTotalFormatter = (cell, row) => row.pm_menu_A_sum + row.pm_menu_B_sum;

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">
            Total Pax Summary
          </CardTitle>
          <div>
            {`Date: ${this.props.dailystats.date}, ${this.props.dailystats.day}`}
          </div>
        </CardHeader>
        <CardBody style={{ paddingBottom: 0 }}>
          <BootstrapTable
            data={this.props.dailystats.total}
            options={this.options}
            tableHeaderClass="table-column-header"
            tableBodyClass="table-body-myclass"
            bordered={false}
            height="400"
            scrollTop={"Bottom"}
          >
            <TableHeaderColumn dataField="menu" isKey hidden editable={false}>
              Menu ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="menuname"
              dataSort
              editable={false}
              tdStyle={{ whiteSpace: "normal" }}
              width="200"
            >
              Menu
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="am_menu_A_sum"
              dataFormat={this.amAFormatter}
              dataSort
              editable={false}
              tdStyle={{ whiteSpace: "normal" }}
              width="200"
            >
              A.M. Menu A
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="am_menu_B_sum"
              dataFormat={this.amBFormatter}
              dataSort
              editable={false}
              tdStyle={{ whiteSpace: "normal" }}
              width="200"
            >
              A.M. Menu B
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="id"
              dataFormat={this.amTotalFormatter}
              dataSort
              editable={false}
              tdStyle={{ whiteSpace: "normal" }}
              width="150"
            >
              A.M. Total
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="pm_menu_A_sum"
              dataFormat={this.pmAFormatter}
              dataSort
              editable={false}
              tdStyle={{ whiteSpace: "normal" }}
              width="200"
            >
              P.M. Menu A
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="pm_menu_B_sum"
              dataFormat={this.pmBFormatter}
              dataSort
              editable={false}
              tdStyle={{ whiteSpace: "normal" }}
              width="200"
            >
              P.M. Menu B
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="id"
              dataFormat={this.pmTotalFormatter}
              dataSort
              editable={false}
              tdStyle={{ whiteSpace: "normal" }}
              width="150"
            >
              P.M. Total
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="freepax_sum"
              dataSort
              editable={false}
              tdStyle={{ whiteSpace: "normal" }}
              width="150"
            >
              Free Pax
            </TableHeaderColumn>
          </BootstrapTable>
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(
  connect(
    createStructuredSelector({
      dailystats: getDailyStats,
      loading: getDailyStatsLoading
    }),
    {}
  )(DailyStatsComponent)
);
