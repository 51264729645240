import axios from "axios";
import { DJANGO_CORE_ADDR, DJANGO_TOKEN_ADDR } from "../config";
import history from "../history";

export const FETCH_USER = "user/FETCH_USER";
export const SET_USER = "user/SET_USER";
export const ERROR_USER = "user/ERROR_USER";

export const getUser = state => state.user;
export const getUserID = state => state.user.id;
export const getUserLoading = state => state.user.loading;
export const getUserError = state => state.user.error;

export const fetchUser = () => ({
  type: FETCH_USER
});

export const setUser = value => ({
  type: SET_USER,
  value
});

export const errorUser = message => ({
  type: ERROR_USER,
  message
});

export const retrieveUserFromToken = () => async dispatch => {
  try {
    const responseGetCurrentUser = await axios.get(
      `${DJANGO_CORE_ADDR}/users/current/`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );

    console.log("Current User: ", responseGetCurrentUser);
    const loggedUser = {
      id: responseGetCurrentUser.data.id,
      role:
        responseGetCurrentUser.data.username === "superadmin"
          ? "superadmin"
          : responseGetCurrentUser.data.groups[0].name,
      username: responseGetCurrentUser.data.username,
      active: responseGetCurrentUser.data.is_active
    };
    dispatch(setUser(loggedUser));
  } catch (error) {
    history.push("./login");
    console.error(error);
  }
};

export const loginUserGetToken = (username, password) => async dispatch => {
  if (!username || !password) {
    dispatch(errorUser("Username / Password required"));
  } else {
    try {
      const responseLoginUser = await axios.post(
        `${DJANGO_TOKEN_ADDR}`,
        { username, password },
        { headers: { "Content-Type": "application/json" } }
      );

      if ("token" in responseLoginUser.data) {
        const loggedUser = {
          id: responseLoginUser.data.user.id,
          role:
            username === "superadmin"
              ? "superadmin"
              : responseLoginUser.data.user.groups[0].name,
          username: responseLoginUser.data.user.username,
          active: responseLoginUser.data.user.is_active,
          logged_in: true
        };

        localStorage.setItem("token", responseLoginUser.data.token);
        dispatch(setUser(loggedUser));
        history.push("./dashboard");
      }
    } catch (error) {
      dispatch(errorUser(error.response.data.non_field_errors));
    }
  }
};

export const logoutUser = () => async dispatch => {
  localStorage.removeItem("token");
  history.push("./login");
};
