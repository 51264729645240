import React from "react";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Row, Col } from "reactstrap";
// react plugin used to create charts

import { PanelHeader, DailyStatsComponent } from "../../components";
import { retrieveDailyStats } from "../../actions/dailystats_action";
import { getUserID } from "../../actions/user_action";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.notifyRef = React.createRef();
  }

  componentDidMount() {
    this.props.retrieveDailyStats();
  }

  onNotification = options => this.notifyRef.current.notificationAlert(options);

  render() {
    return (
      <div>
        <NotificationAlert ref={this.notifyRef} />
        <PanelHeader size="lg" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <DailyStatsComponent />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
    userID: getUserID
  }),
  {
    retrieveDailyStats
  }
)(Dashboard);
