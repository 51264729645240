import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import history from "./history";
import root_reducer from "./reducers/root_reducer";
import "../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.css";
import "assets/css/demo.css";
import indexRoutes from "routes/index.jsx";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  root_reducer,
  composeEnhancer(applyMiddleware(thunk))
);

const AppContainer = () => (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        {indexRoutes.map((prop, key) => (
          <Route path={prop.path} key={key} component={prop.component} />
        ))}
      </Switch>
    </Router>
  </Provider>
);

ReactDOM.render(<AppContainer />, document.getElementById("root"));
