import React from "react";
import NotificationAlert from 'react-notification-alert';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Row, Col } from "reactstrap";

import { PanelHeader, SetMenuAdminComponent } from "../../components";

import {
  getUIFilterSetMenu,
  getUIMenuIsEdit,
  getUIMenuIsNew,
  onCancelMenuForm,
} from '../../actions/ui_action';

import { getMenu, retrieveMenus, retrieveMenu } from '../../actions/menus_action';
import { retrieveMenuItems } from '../../actions/menuitems_action';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.notifyRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(!this.props.menu)
    {
      return true;
    }
    return nextProps.menu.id !== this.props.menu.id ||
      nextProps.menuIsEdit !== this.props.menuIsEdit ||
      nextProps.menuIsNew !== this.props.menuIsNew ||
      nextProps.filterSetMenu !== this.props.filterSetMenu;
  }

  componentDidMount() {
    this.props.onCancelMenuForm();
    this.props.retrieveMenuItems();
    this.props.retrieveMenus();
    this.props.retrieveMenu();
  }

  onNotification = options => this.notifyRef.current.notificationAlert(options);

  render() {
    return (
      <div>
        <NotificationAlert ref={this.notifyRef} />
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <SetMenuAdminComponent
                key={this.props.menuIsNew || this.props.menuIsEdit ? 0 : this.props.menu.id}
                notifyFunc={(options) => this.onNotification(options)}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
    menu: getMenu,
    filterSetMenu: getUIFilterSetMenu,
    menuIsEdit: getUIMenuIsEdit,
    menuIsNew: getUIMenuIsNew,
  }),
  {
    retrieveMenuItems,
    retrieveMenus,
    retrieveMenu,
    onCancelMenuForm
  })(Menu);