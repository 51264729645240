import React from "react";
import moment from 'moment';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  FormGroup,
  Label,
  Input, Row, Col, CardFooter
} from "reactstrap";

import {
  Button,
  CardCategory, CustomDropdownMenuItem,
  CustomDropdownSetMenuItem, FormInputs
} from "../";

import {
  getMenuItem,
  createMenuItem,
  updateMenuItem,
  removeMenuItem
} from '../../actions/menuitems_action';

import {
  getUIFilterSetMenuItem,
  getUIMenuItemIsNew,
  getUIMenuItemIsEdit,
  editMenuItem,
  newMenuItem,
  onCancelMenuItemForm,
  onFilterSetMenuItem,
} from '../../actions/ui_action';

class MenuItemComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.menuItemIsNew ?
      {
        name: '',
      } :
      {
        ...this.props.menuItem,
      };
  }

  render() {
    return (
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="title">Menu Item</h5>
                  {!this.props.menuItemIsNew &&
                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn-round btn-simple btn-icon"
                        color="default"
                      >
                        <i className="now-ui-icons arrows-1_minimal-down"/>
                      </DropdownToggle>
                      {!this.props.menuItemIsEdit ?
                        <DropdownMenu right>
                          <DropdownItem onClick={() => this.props.editMenuItem()}>Edit</DropdownItem>
                          <DropdownItem onClick={() => this.props.newMenuItem()}>Add new Item</DropdownItem>
                        </DropdownMenu> :
                        <DropdownMenu right>
                          <DropdownItem onClick={() => this.props.removeMenuItem(this.state.id, (options) => this.props.notifyFunc(options))}>Delete</DropdownItem>
                        </DropdownMenu>
                      }
                    </UncontrolledDropdown>
                  }
                  {!(this.props.menuItemIsEdit || this.props.menuItemIsNew) &&
                    <CardCategory>
                      <Row>
                        <Col xs={12} md={2}>
                          <CustomDropdownSetMenuItem/>
                        </Col>
                      </Row>
                    </CardCategory>
                  }
                </CardHeader>
                <CardBody>
                    <form>
                      <FormInputs
                        ncols={["col-md-12 pr-3"]}
                        proprieties={[
                          {
                            label: "Item Name",
                            inputProps: {
                              type: "text",
                              value: this.state.name,
                              disabled: !(this.props.menuItemIsNew || this.props.menuItemIsEdit),
                              onChange: event =>
                                this.setState({ name: event.target.value }),
                              style: this.state.name ? null : {border: '2px solid red'}
                            }
                          }
                        ]}
                      />
                    </form>
                </CardBody>
                {(this.props.menuItemIsEdit || this.props.menuItemIsNew) &&
                <CardFooter className='card-footer-buttons-padding'>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      color="primary"
                      style={{ width: "20%" }}
                      onClick={this.props.menuItemIsEdit ? () => this.props.updateMenuItem(this.state, (options) => this.props.notifyFunc(options)) : () => this.props.createMenuItem(this.state, (options) => this.props.notifyFunc(options))}
                    >
                      Save
                    </Button>
                    <Button
                      color="secondary"
                      style={{ width: "75%" }}
                      onClick={() => this.props.onCancelMenuItemForm()}
                    >
                      Cancel
                    </Button>
                  </div>
                </CardFooter>
                }
              </Card>
    );
  }
}

export default withRouter(connect(
  createStructuredSelector({
    menuItem: getMenuItem,
    filterSetMenuItem: getUIFilterSetMenuItem,
    menuItemIsNew: getUIMenuItemIsNew,
    menuItemIsEdit: getUIMenuItemIsEdit,
  }),
  {
    onFilterSetMenuItem,
    onCancelMenuItemForm,
    editMenuItem,
    newMenuItem,
    createMenuItem,
    updateMenuItem,
    removeMenuItem
  })(MenuItemComponent));