import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { StickyTable, Row, Cell } from "react-sticky-table";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
  Row as CardRow,
  Col,
  Progress,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Table
} from "reactstrap";
import {
  Button,
  CardCategory,
  CardFooterStats,
  CustomDropdownMonth,
  CustomDropdownYear,
  CustomDropdownTrip,
  CustomDropdownMenu,
  ThisMonthButton
} from "..";
import {
  getUIFilterYear,
  getUIFilterMonth,
  onFilterMonth_sales,
  onFilterYear_sales,
  onFilterYearMonth_sales,
  onFilterMenu_sales,
  onFilterTrip_sales
} from "../../actions/ui_action";
import { 
  getSalesOrders,
  getSalesOrdersLoading,
} from "../../actions/salesorders_action";
class SalesOrdersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.options = {
      sortIndicator: false
    };
  }

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">
            Sales Orders
          </CardTitle>
          <CardCategory>
            <CardRow>
              <Col xs={12} md={2}>
                <CustomDropdownMonth
                  retrieveFunc={value => this.props.onFilterMonth_sales(value)}
                />
              </Col>
              <Col xs={12} md={2}>
                <CustomDropdownYear
                  retrieveFunc={value => this.props.onFilterYear_sales(value)}
                />
              </Col>
              <Col xs={12} md={2}>
                <CustomDropdownTrip
                  retrieveFunc={value => this.props.onFilterTrip_sales(value)}
                />
              </Col>
              <Col xs={12} md={2}>
                <CustomDropdownMenu
                  retrieveFunc={value => this.props.onFilterMenu_sales(value)}
                />
              </Col>
              {!(
                this.props.filterYear === moment().year() &&
                this.props.filterMonth === moment().month() + 1
              ) && (
                <Col xs={12} md={2}>
                  <ThisMonthButton
                    retrieveFunc={(year, month) =>
                      this.props.onFilterYearMonth_sales(year, month)
                    }
                  />
                </Col>
              )}
            </CardRow>
          </CardCategory>
        </CardHeader>
        <CardBody>
          {this.props.filterYear < moment().year() ||
          (this.props.filterYear === moment().year() &&
            this.props.filterMonth <= moment().month() + 1) ? (
            this.props.sales.length > 0 ? (
              <div style={{ width: "100%", height: "calc(100vh - 350px)" }}>
                <StickyTable>
                  <Row>
                    <Cell>
                      <div className="table-header-cell-left-style">
                        <span>
                          <b>Agent</b>
                        </span>
                        <br />
                        <span>
                          <b>Customer</b>
                        </span>
                        <br />
                        <span>
                          <b>Order</b>
                        </span>
                        <br />
                        <span>
                          <b>Address</b>
                        </span>
                        <br />
                        <span>
                          <b>Trip</b>
                        </span>
                      </div>
                    </Cell>
                    {this.props.sales.map(sale => (
                      <Cell key={sale.id}>
                        <div className="table-header-cell-style">
                          <span>{sale.user}</span>
                          <br />
                          <span>{sale.customer}</span>
                          <br />
                          <span>{sale.order}</span>
                          <br />
                          <span>{sale.address}</span>
                          <br />
                          <span>{sale.trip}</span>
                        </div>
                      </Cell>
                    ))}
                  </Row>
                  {Array.from(
                    Array(
                      moment(
                        `${
                          this.props.filterYear
                        }-${this.props.filterMonth
                          .toString()
                          .padStart(2, "0")}`,
                        "YYYY-MM"
                      ).daysInMonth()
                    ).keys()
                  ).map(day => (
                    <Row key={`day${day + 1}`}>
                      <Cell>
                        <div>
                          {`${(day + 1).toString().padStart(2, "0")} ${moment(
                            this.props.filterMonth.toString().padStart(2, "0"),
                            "MM"
                          ).format("MMM")}`}
                        </div>
                        <div>
                          {`${moment(
                            `${
                              this.props.filterYear
                            }-${this.props.filterMonth
                              .toString()
                              .padStart(2, "0")}-${parseInt(day + 1)
                              .toString()
                              .padStart(2, "0")}`,
                            "YYYY-MM-DD"
                          ).format("dddd")}`}
                        </div>
                      </Cell>
                      {this.props.sales.map(sale => {
                        const dayKey = `pax_${day + 1}`;

                        let am = "\u00A0";
                        let pm = "\u00A0";
                        let free = "\u00A0";

                        if (dayKey in sale) {
                          if (
                            sale[dayKey]["am_pax"] === 0 &&
                            sale[dayKey]["pm_pax"] === 0
                          ) {
                            am = "0";
                            pm = "0";
                          } else {
                            am = `${sale[dayKey]["am_pax"] +
                              sale[dayKey]["free_pax"]}`;
                            pm = `${sale[dayKey]["pm_pax"] +
                              sale[dayKey]["free_pax"]}`;
                            if (sale[dayKey]["free_pax"] !== 0) {
                              free = `Free Pax: ${sale[dayKey]["free_pax"]}`;
                            }
                          }
                        }

                        return (
                          <React.Fragment key={`${day + 1}_order${sale.id}`}>
                            <Cell>
                              {dayKey in sale ? (
                                <React.Fragment>
                                  <div
                                    style={{ fontSize: 10, textAlign: "right" }}
                                  >
                                    {free}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      minWidth: "150px"
                                    }}
                                  >
                                    <div>
                                      {am}{" "}
                                      <span style={{ fontSize: 10 }}>
                                        (a.m.)
                                      </span>
                                    </div>
                                    <div>
                                      {pm}{" "}
                                      <span style={{ fontSize: 10 }}>
                                        (p.m.)
                                      </span>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : (
                                <div>&nbsp;</div>
                              )}
                            </Cell>
                          </React.Fragment>
                        );
                      })}
                    </Row>
                  ))}
                </StickyTable>
              </div>
            ) : null
          ) : (
            <div>Selected Year / Month must be earlier than current Date</div>
          )}
        </CardBody>
        <CardFooter className="card-footer-margin">
          {this.props.filterYear < moment().year() ||
          (this.props.filterYear === moment().year() &&
            this.props.filterMonth <= moment().month() + 1) ? (
            <CardFooterStats>
              {[
                {
                  i: "now-ui-icons travel_info",
                  t: `Total: ${this.props.sales.length} Orders`
                }
              ]}
            </CardFooterStats>
          ) : null}
        </CardFooter>
      </Card>
    );
  }
}

export default withRouter(
  connect(
    createStructuredSelector({
      sales: getSalesOrders,
      loading: getSalesOrdersLoading,
      filterYear: getUIFilterYear,
      filterMonth: getUIFilterMonth
    }),
    {
      onFilterMonth_sales,
      onFilterYear_sales,
      onFilterYearMonth_sales,
      onFilterMenu_sales,
      onFilterTrip_sales
    }
  )(SalesOrdersComponent)
);
