import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { StickyTable, Row, Cell } from "react-sticky-table";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
  Row as CardRow,
  Col,
  Progress,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Table
} from "reactstrap";
import {
  Button,
  CardCategory,
  CardFooterStats,
  CustomDropdownMonth,
  CustomDropdownYear,
  CustomDropdownTrip,
  CustomDropdownMenu,
  ThisMonthButton,
  FormInputs
} from "..";
import {
  getUIFilterYear,
  getUIFilterMonth,
  onFilterMonth_sales,
  onFilterYear_sales,
  onFilterYearMonth_sales,
  onFilterMenu_sales,
  onFilterTrip_sales
} from "../../actions/ui_action";
import {
  getSalesOrders,
  getSalesEdits,
  getSalesOrdersLoading,
  editSaleOrdersState,
  updateSaleOrder
} from "../../actions/salesorders_action";

import "../../assets/css/react-sticky-table.css";

class SalesOrdersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      id: null,
      sale: {},
      edit_am_pax: "",
      edit_pm_pax: ""
    };

    this.options = {
      sortIndicator: false
    };
  }

  setEditState = (sale, dayKey) => {
    this.setState({
      isEditing: true,
      sale: sale[dayKey],
      edit_am_pax: sale[dayKey]["am_pax"],
      edit_pm_pax: sale[dayKey]["pm_pax"]
    });
  };

  clearEditState = () => {
    this.setState({
      isEditing: false,
      id: null,
      sale: {},
      edit_am_pax: "",
      edit_pm_pax: ""
    });
  };

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">
            Sales Orders
          </CardTitle>
          <CardCategory>
            <CardRow>
              <Col xs={12} md={2}>
                <CustomDropdownMonth
                  retrieveFunc={value => this.props.onFilterMonth_sales(value)}
                />
              </Col>
              <Col xs={12} md={2}>
                <CustomDropdownYear
                  retrieveFunc={value => this.props.onFilterYear_sales(value)}
                />
              </Col>
              <Col xs={12} md={2}>
                <CustomDropdownTrip
                  retrieveFunc={value => this.props.onFilterTrip_sales(value)}
                />
              </Col>
              <Col xs={12} md={2}>
                <CustomDropdownMenu
                  retrieveFunc={value => this.props.onFilterMenu_sales(value)}
                />
              </Col>
              {!(
                this.props.filterYear === moment().year() &&
                this.props.filterMonth === moment().month() + 1
              ) && (
                <Col xs={12} md={2}>
                  <ThisMonthButton
                    retrieveFunc={(year, month) =>
                      this.props.onFilterYearMonth_sales(year, month)
                    }
                  />
                </Col>
              )}
            </CardRow>
          </CardCategory>
        </CardHeader>
        <CardBody>
          {this.props.filterYear < moment().year() ||
          (this.props.filterYear === moment().year() &&
            this.props.filterMonth <= moment().month() + 1) ? (
            this.props.sales.length > 0 ? (
              <div style={{ width: "100%", height: "calc(100vh - 350px)" }}>
                <StickyTable>
                  <Row>
                    <Cell>
                      <div className="table-header-cell-left-style">
                        <span>
                          <b>Agent</b>
                        </span>
                        <br />
                        <span>
                          <b>Customer</b>
                        </span>
                        <br />
                        <span>
                          <b>Order</b>
                        </span>
                        <br />
                        <span>
                          <b>Address</b>
                        </span>
                        <br />
                        <span>
                          <b>Trip</b>
                        </span>
                      </div>
                    </Cell>
                    {this.props.sales.map(sale => (
                      <Cell key={sale.id}>
                        <div className="table-header-cell-style">
                          <span>{sale.user}</span>
                          <br />
                          <span>{sale.customer}</span>
                          <br />
                          <span>{sale.order}</span>
                          <br />
                          <span>{sale.address}</span>
                          <br />
                          <span>{sale.trip}</span>
                        </div>
                      </Cell>
                    ))}
                  </Row>
                  {Array.from(
                    Array(
                      moment(
                        `${
                          this.props.filterYear
                        }-${this.props.filterMonth
                          .toString()
                          .padStart(2, "0")}`,
                        "YYYY-MM"
                      ).daysInMonth()
                    ).keys()
                  ).map(day => (
                    <Row key={`day${day + 1}`}>
                      <Cell>
                        <div>
                          {`${(day + 1).toString().padStart(2, "0")} ${moment(
                            this.props.filterMonth.toString().padStart(2, "0"),
                            "MM"
                          ).format("MMM")}`}
                        </div>
                        <div>
                          {`${moment(
                            `${
                              this.props.filterYear
                            }-${this.props.filterMonth
                              .toString()
                              .padStart(2, "0")}-${parseInt(day + 1)
                              .toString()
                              .padStart(2, "0")}`,
                            "YYYY-MM-DD"
                          ).format("dddd")}`}
                        </div>
                      </Cell>
                      {this.props.sales.map(sale => {
                        const dayKey = `pax_${day + 1}`;
                        const editKey = `edit_day_${day + 1}`;

                        let am = 0;
                        let pm = 0;
                        let free = 0;

                        if (dayKey in sale) {
                          if (
                            sale[dayKey]["am_pax"] === 0 &&
                            sale[dayKey]["pm_pax"] === 0
                          ) {
                          } else {
                            am =
                              sale[dayKey]["am_pax"] + sale[dayKey]["free_pax"];
                            pm =
                              sale[dayKey]["pm_pax"] + sale[dayKey]["free_pax"];
                            free = sale[dayKey]["free_pax"];
                          }
                        }

                        return dayKey in sale ? (
                          <React.Fragment key={`${day + 1}_order${sale.id}`}>
                            <Cell
                              onClick={() => {
                                if (
                                  this.props.edits.id !== sale.id ||
                                  this.props.edits.key !== editKey
                                ) {
                                  this.props.editSaleOrdersState(
                                    sale.id,
                                    editKey
                                  );
                                  this.setEditState(sale, dayKey);
                                }
                              }}
                            >
                              {free !== 0 ? (
                                <div
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "right"
                                  }}
                                >
                                  Free Pax: {free}
                                </div>
                              ) : (
                                <div>&nbsp;</div>
                              )}
                              {/*
                                Display simple text instead of Form when viewing
                                */}
                              {this.props.edits.id !== sale.id ||
                              this.props.edits.key !== editKey ? (
                                <React.Fragment>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      minWidth: "150px"
                                    }}
                                  >
                                    <div>
                                      {am}{" "}
                                      <span style={{ fontSize: 10 }}>
                                        (a.m.)
                                      </span>
                                    </div>
                                    <div>
                                      {pm}{" "}
                                      <span style={{ fontSize: 10 }}>
                                        (p.m.)
                                      </span>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : (
                                <FormInputs
                                  ncols={["col-md-6", "col-md-6"]}
                                  proprieties={[
                                    {
                                      label: "(a.m.)",
                                      inputProps: {
                                        type: "number",
                                        disabled:
                                          this.props.edits.id !== sale.id ||
                                          this.props.edits.key !== editKey,
                                        value:
                                          this.props.edits.id === sale.id &&
                                          this.props.edits.key === editKey
                                            ? this.state.edit_am_pax
                                            : am,
                                        onChange: event =>
                                          this.setState({
                                            edit_am_pax: event.target.value
                                          })
                                      }
                                    },
                                    {
                                      label: "(p.m.)",
                                      inputProps: {
                                        type: "number",
                                        disabled:
                                          this.props.edits.id !== sale.id ||
                                          this.props.edits.key !== editKey,
                                        value:
                                          this.props.edits.id === sale.id &&
                                          this.props.edits.key === editKey
                                            ? this.state.edit_pm_pax
                                            : pm,
                                        onChange: event =>
                                          this.setState({
                                            edit_pm_pax: event.target.value
                                          })
                                      }
                                    }
                                  ]}
                                />
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  minWidth: "200px"
                                }}
                              >
                                {this.props.edits.id === sale.id &&
                                  this.props.edits.key === editKey && (
                                    <React.Fragment>
                                      <Button
                                        color="primary"
                                        style={{ width: "100%", padding: 0 }}
                                        onClick={() => {
                                          this.props.updateSaleOrder(
                                            Object.assign(this.state.sale, {
                                              am_pax: this.state.edit_am_pax,
                                              pm_pax: this.state.edit_pm_pax
                                            }),
                                            options =>
                                              this.props.notifyFunc(options)
                                          );
                                          this.clearEditState();
                                        }}
                                      >
                                        Save
                                      </Button>
                                      <Button
                                        color="secondary"
                                        style={{ width: "100%", padding: 0 }}
                                        onClick={event => {
                                          event.stopPropagation();
                                          this.props.editSaleOrdersState(0, 0);
                                          this.clearEditState();
                                          this.forceUpdate();
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    </React.Fragment>
                                  )}
                              </div>
                            </Cell>
                          </React.Fragment>
                        ) : (
                          <React.Fragment key={`${day + 1}_order${sale.id}`}>
                            <Cell>{"\u00A0"}</Cell>
                          </React.Fragment>
                        );
                      })}
                    </Row>
                  ))}
                </StickyTable>
              </div>
            ) : null
          ) : (
            <div>Selected Year / Month must be earlier than current Date</div>
          )}
        </CardBody>
        <CardFooter className="card-footer-margin">
          {this.props.filterYear < moment().year() ||
          (this.props.filterYear === moment().year() &&
            this.props.filterMonth <= moment().month() + 1) ? (
            <CardFooterStats>
              {[
                {
                  i: "now-ui-icons travel_info",
                  t: `Total: ${this.props.sales.length} Orders`
                }
              ]}
            </CardFooterStats>
          ) : null}
        </CardFooter>
      </Card>
    );
  }
}

export default withRouter(
  connect(
    createStructuredSelector({
      sales: getSalesOrders,
      edits: getSalesEdits,
      loading: getSalesOrdersLoading,
      filterYear: getUIFilterYear,
      filterMonth: getUIFilterMonth
    }),
    {
      onFilterMonth_sales,
      onFilterYear_sales,
      onFilterYearMonth_sales,
      onFilterMenu_sales,
      onFilterTrip_sales,
      editSaleOrdersState,
      updateSaleOrder
    }
  )(SalesOrdersComponent)
);
