import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
  Progress
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button, CardFooterStats } from "..";

import { getPayments } from "../../actions/payments_action";
import {
  getUIPaymentSelectedID,
  selectPayment,
  getUIAccountSelectedGST
} from "../../actions/ui_action";

class PaymentsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.options = {
      sortIndicator: false
    };
  }

  idFormatter = (cell, row) =>
    this.props.hasGST === "Y" ? `P/${row.year}/${cell}` : `${row.year}/${cell}`;

  dateFormatter = (cell, row) =>
    cell ? moment.unix(cell).format("DD/MM/YYYY") : null;

  salesFormatter = (cell, row) => (cell ? `$${cell.toFixed(2)}` : `$0.00`);

  actionEditFormatter = (cell, row) => (
    <div>
      {
        <React.Fragment>
          <Button
            id={`editPayment${cell}`}
            round
            icon
            iconMini
            neutral
            color="info"
            onClick={() => {
              console.log("Edit Payment: ", cell);
              this.props.selectPayment(cell);
            }}
          >
            <i className="now-ui-icons shopping_tag-content" />
          </Button>
          <UncontrolledTooltip
            placement="left"
            target={`editPayment${cell}`}
            delay={0}
          >
            Edit Payment
          </UncontrolledTooltip>
        </React.Fragment>
      }
    </div>
  );

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.payments.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">Payment</CardTitle>
        </CardHeader>
        <CardBody style={{ paddingBottom: 0 }}>
          <BootstrapTable
            data={this.props.payments}
            options={this.options}
            tableHeaderClass="table-column-header"
            tableBodyClass="table-body-myclass"
            bordered={false}
            search={true}
            height="250"
            scrollTop={"Top"}
          >
            <TableHeaderColumn
              dataField="id"
              dataFormat={this.idFormatter}
              isKey
              searchable={true}
              editable={false}
            >
              ID
            </TableHeaderColumn>
            <TableHeaderColumn dataField="description" editable={false}>
              Description
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="amount"
              dataFormat={this.salesFormatter}
              editable={false}
              width="200"
            >
              Amount ($)
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="date"
              dataFormat={this.dateFormatter}
              dataSort
              searchable={false}
              editable={false}
              headerAlign="center"
              dataAlign="center"
              thStyle={{ whiteSpace: "normal" }}
              width="200"
            >
              Date
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="id"
              dataFormat={this.actionEditFormatter}
              editable={false}
              headerAlign="right"
              dataAlign="right"
              width="50"
            />
          </BootstrapTable>
        </CardBody>
        <CardFooter className="card-footer-margin">
          <CardFooterStats>
            {[
              {
                i: "now-ui-icons travel_info",
                t: `Total: ${this.props.payments.length} Payment Notes`
              }
            ]}
          </CardFooterStats>
        </CardFooter>
      </Card>
    );
  }
}

export default connect(
  createStructuredSelector({
    payments: getPayments,
    paymentSelectedID: getUIPaymentSelectedID,
    hasGST: getUIAccountSelectedGST
  }),
  {
    selectPayment
  }
)(PaymentsComponent);
