import {
  FETCH_INVOICE,
  SET_INVOICE,
  ERROR_INVOICE,
} from "../actions/invoice_action";

const INITIAL_STATE = {
  loading: false,
  error: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_INVOICE:
      return {
        ...state,
        loading: true,
      };
    case SET_INVOICE:
      return {
        ...state,
        error: '',
        loading: false,
      };
    case ERROR_INVOICE:
      return {
        ...state,
        error: action.message,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;