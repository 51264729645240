import React from "react";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Row, Col, Modal, ModalBody } from "reactstrap";

import {
  PanelHeader,
  AccountsComponent,
  AccountSummaryComponent,
  DebitsComponent,
  DebitFormComponent,
  CreditsComponent,
  CreditFormComponent,
  PaymentsComponent,
  PaymentFormComponent,
  InvoicesAccountComponent
} from "../../components";

import {
  getUICustomerSelectedID,
  getUIAccountSelectedID,
  getUIDebitIsEdit,
  getUIDebitIsNew,
  getUIDebitSelectedID,
  getUICreditIsEdit,
  getUICreditIsNew,
  getUICreditSelectedID,
  getUIPaymentIsEdit,
  getUIPaymentIsNew,
  getUIPaymentSelectedID,
  getUIFilterAgent
} from "../../actions/ui_action";

import { retrieveAccounts } from "../../actions/customers_action";

import { retrieveUsers } from "../../actions/users_action";

import { getInvoiceLoading } from "../../actions/invoice_action";

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.notifyRef = React.createRef();
  }

  componentDidMount() {
    this.props.retrieveUsers();
    this.props.retrieveAccounts();
  }

  onNotification = options => this.notifyRef.current.notificationAlert(options);

  render() {
    return (
      <div>
        <NotificationAlert ref={this.notifyRef} />
        <PanelHeader size="sm" />
        <div className="content">
          {!this.props.debitIsEdit &&
            !this.props.debitIsNew &&
            !this.props.creditIsEdit &&
            !this.props.creditIsNew &&
            !this.props.paymentIsEdit &&
            !this.props.paymentIsNew && (
              <Row>
                <Col xs={12} md={12}>
                  <AccountsComponent
                    notifyFunc={options => this.onNotification(options)}
                  />
                </Col>
              </Row>
            )}

          {!this.props.debitIsEdit &&
            !this.props.debitIsNew &&
            !this.props.creditIsEdit &&
            !this.props.creditIsNew &&
            !this.props.paymentIsEdit &&
            !this.props.paymentIsNew &&
            this.props.accountSelectedID && (
              <Row>
                <Col xs={12} md={12}>
                  <AccountSummaryComponent />
                </Col>
              </Row>
            )}

          {!this.props.debitIsEdit &&
            !this.props.debitIsNew &&
            !this.props.creditIsEdit &&
            !this.props.creditIsNew &&
            !this.props.paymentIsEdit &&
            !this.props.paymentIsNew &&
            this.props.accountSelectedID && (
              <Row>
                <Col xs={12} md={12}>
                  <InvoicesAccountComponent
                    notifyFunc={options => this.onNotification(options)}
                  />
                </Col>
              </Row>
            )}

          {!this.props.debitIsEdit &&
            !this.props.debitIsNew &&
            !this.props.creditIsEdit &&
            !this.props.creditIsNew &&
            !this.props.paymentIsEdit &&
            !this.props.paymentIsNew &&
            this.props.accountSelectedID && (
              <Row>
                <Col xs={12} md={12}>
                  <DebitsComponent
                    notifyFunc={options => this.onNotification(options)}
                  />
                </Col>
              </Row>
            )}

          {(this.props.debitIsEdit || this.props.debitIsNew) && (
            <Row>
              <Col xs={12} md={12}>
                <DebitFormComponent
                  key={
                    this.props.debitIsNew || this.props.debitIsEdit
                      ? 0
                      : this.props.debitSelectedID
                  }
                  notifyFunc={options => this.onNotification(options)}
                />
              </Col>
            </Row>
          )}

          {!this.props.debitIsEdit &&
            !this.props.debitIsNew &&
            !this.props.creditIsEdit &&
            !this.props.creditIsNew &&
            !this.props.paymentIsEdit &&
            !this.props.paymentIsNew &&
            this.props.accountSelectedID && (
              <Row>
                <Col xs={12} md={12}>
                  <CreditsComponent
                    notifyFunc={options => this.onNotification(options)}
                  />
                </Col>
              </Row>
            )}

          {(this.props.creditIsEdit || this.props.creditIsNew) && (
            <Row>
              <Col xs={12} md={12}>
                <CreditFormComponent
                  key={
                    this.props.creditIsNew || this.props.creditIsEdit
                      ? 0
                      : this.props.creditSelectedID
                  }
                  notifyFunc={options => this.onNotification(options)}
                />
              </Col>
            </Row>
          )}

          {!this.props.debitIsEdit &&
            !this.props.debitIsNew &&
            !this.props.creditIsEdit &&
            !this.props.creditIsNew &&
            !this.props.paymentIsEdit &&
            !this.props.paymentIsNew &&
            this.props.accountSelectedID && (
              <Row>
                <Col xs={12} md={12}>
                  <PaymentsComponent
                    notifyFunc={options => this.onNotification(options)}
                  />
                </Col>
              </Row>
            )}

          {(this.props.paymentIsEdit || this.props.paymentIsNew) && (
            <Row>
              <Col xs={12} md={12}>
                <PaymentFormComponent
                  key={
                    this.props.creditIsNew || this.props.creditIsEdit
                      ? 0
                      : this.props.creditSelectedID
                  }
                  notifyFunc={options => this.onNotification(options)}
                />
              </Col>
            </Row>
          )}
        </div>
        <Modal isOpen={this.props.isLoading} centered>
          <ModalBody>Processing. This may take awhile... </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
    customerSelectedID: getUICustomerSelectedID,
    accountSelectedID: getUIAccountSelectedID,
    debitIsEdit: getUIDebitIsEdit,
    debitIsNew: getUIDebitIsNew,
    debitSelectedID: getUIDebitSelectedID,
    creditIsEdit: getUICreditIsEdit,
    creditIsNew: getUICreditIsNew,
    creditSelectedID: getUICreditSelectedID,
    paymentIsEdit: getUIPaymentIsEdit,
    paymentIsNew: getUIPaymentIsNew,
    paymentSelectedID: getUIPaymentSelectedID,
    agent: getUIFilterAgent,
    isLoading: getInvoiceLoading
  }),
  {
    retrieveUsers,
    retrieveAccounts
  }
)(Account);
