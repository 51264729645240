import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";
import { notifySuccess, notifyError } from "../components/NotificationOptions";
import { setUsers, retrieveUsers } from "./users_action";
import { getUIUserSelectedID, onCancelUserForm } from "./ui_action";

export const FETCH_USER = "selecteduser/FETCH_USER";
export const SET_USER = "selecteduser/SET_USER";
export const ERROR_USER = "selecteduser/ERROR_USER";

export const getUser = state => state.selecteduser;
export const getUserID = state => state.selecteduser.id;
export const getUserLoading = state => state.selecteduser.loading;
export const getUserError = state => state.selecteduser.error;

export const fetchUser = () => ({
  type: FETCH_USER
});

export const setUser = value => ({
  type: SET_USER,
  value
});

export const errorUser = message => ({
  type: ERROR_USER,
  message
});

export const createUser = (user, notifyFunc) => async dispatch => {
  dispatch(fetchUser());
  try {
    const responsePostUser = await axios.post(
      `${DJANGO_CORE_ADDR}/users/create/`,
      {
        username: user.username,
        password: user.password,
        role: user.groups[0].name,
        is_active: user.is_active
      }
    );

    if ("username" in responsePostUser.data) {
      dispatch(errorUser("Username already exists"));
      notifyFunc(notifyError("Username already exists."));
    } else if ("message" in responsePostUser.data) {
      await dispatch(retrieveUsers());
      await dispatch(onCancelUserForm());
    }
  } catch (error) {
    console.error(error);
    dispatch(errorUser("Some Error!"));
    if (error.response.status === 400) {
      notifyFunc(notifyError("System error. Please try again later."));
    }
  }
};

export const retrieveUser = () => async (dispatch, getState) => {
  if (getUIUserSelectedID(getState())) {
    dispatch(fetchUser());
    try {
      const responseGetUser = await axios.get(
        `${DJANGO_CORE_ADDR}/users/list/?id=${getUIUserSelectedID(getState())}`,
        { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
      );
      dispatch(setUser(responseGetUser.data));
    } catch (error) {
      console.error(error);
      dispatch(errorUser("Some Error!"));
    }
  }
};

export const updateUser = (user, notifyFunc) => async dispatch => {
  try {
    const responsePatchUser = await axios.patch(
      `${DJANGO_CORE_ADDR}/users/update/`,
      {
        id: user.id,
        role: user.groups[0].name,
        is_active: user.is_active,
        is_staff: user.groups[0].name === "admin"
      },
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    console.log("User edited!");
    await notifyFunc(notifySuccess(`${user.username}: User update successful`));
    await dispatch(retrieveUsers());
    await dispatch(retrieveUser(user.id));
    await dispatch(onCancelUserForm());
  } catch (error) {
    console.error(error);
    dispatch(errorUser("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};

export const changePassUser = (user, notifyFunc) => async dispatch => {
  console.log('Passed: ', user);
  try {
    const responsePatchUser = await axios.put(
      `${DJANGO_CORE_ADDR}/users/reset/`,
      {
        id: user.id,
        password: user.password
      },
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    console.log("User password updated!");
    await notifyFunc(
      notifySuccess(`${user.username}: User password update successful`)
    );
    await dispatch(retrieveUsers());
    await dispatch(retrieveUser(user.id));
    await dispatch(onCancelUserForm());
  } catch (error) {
    console.error(error);
    dispatch(errorUser("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};
