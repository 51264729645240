import { FETCH_DEBIT, SET_DEBIT, ERROR_DEBIT } from "../actions/debit_action";

const INITIAL_STATE = {
  id: null,
  order: null,
  customer: null,
  date: null,
  month: null,
  year: null,
  description: "",
  amount: 0.0,
  balance: 0.0,
  void: false,
  loading: false,
  error: ""
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DEBIT:
      return {
        ...state,
        loading: true
      };
    case SET_DEBIT:
      return {
        ...state,
        ...action.value,
        error: "",
        loading: false
      };
    case ERROR_DEBIT:
      return {
        ...state,
        error: action.message,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
