import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Card, CardHeader, CardTitle, CardBody, CardFooter } from "reactstrap";

import { getUIFilterYear, getUIFilterMonth } from "../../actions/ui_action";

import { getInvoicesSum } from "../../actions/invoices_action";
import { getDebitsSum } from "../../actions/debits_action";
import { getCreditsSum } from "../../actions/credits_action";
import { getPaymentsSum } from "../../actions/payments_action";
import { getOutstandingSum } from "../../actions/payment_action";

class AccountsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Card className="card-chart">
        <CardHeader>
          <CardTitle className="title card-header-margin">
            <span>Monthly Summary</span>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <React.Fragment>
            <div
              style={{
                paddingBottom: 0,
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div>
                <span style={{ color: "#f96332" }}>
                  Incoming Payments/Credits
                </span>{" "}
                : ${(this.props.payments + this.props.credits).toFixed(2)}
              </div>
              <div>
                <span style={{ color: "#f96332" }}>
                  Outgoing Invoices/Debits
                </span>{" "}
                : ${(this.props.invoices + this.props.debits).toFixed(2)}
              </div>
              {!(
                this.props.payments +
                  this.props.credits -
                  this.props.invoices -
                  this.props.debits ===
                0.0
              ) && (
                <div>
                  <span style={{ color: "#f96332" }}>Net</span> :
                  <span
                    style={
                      this.props.payments +
                        this.props.credits -
                        this.props.invoices -
                        this.props.debits >
                      0.0
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  >
                    {` $${(
                      this.props.invoices +
                      this.props.debits -
                      this.props.credits -
                      this.props.payments).toFixed(2)}`}
                  </span>
                </div>
              )}
            </div>
            {this.props.filterYear === moment().year() &&
              this.props.filterMonth === moment().month() + 1 &&
              this.props.outstandings > 0.0 && (
                <div style={{ textAlign: "right" }}>
                  <span style={{ color: "#f96332" }}>Total Outstanding</span> :{" "}
                  <span style={{ color: "red" }}>
                    {` $${this.props.outstandings.toFixed(2)}`}
                  </span>
                </div>
              )}
          </React.Fragment>
        </CardBody>
        <CardFooter className="card-footer-margin" />
      </Card>
    );
  }
}

export default connect(
  createStructuredSelector({
    filterYear: getUIFilterYear,
    filterMonth: getUIFilterMonth,
    invoices: getInvoicesSum,
    debits: getDebitsSum,
    credits: getCreditsSum,
    payments: getPaymentsSum,
    outstandings: getOutstandingSum
  }),
  {}
)(AccountsComponent);
