import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";
import { notifyError, notifySuccess } from "../components/NotificationOptions";
import {
  getUIFilterSetTrip,
  onCancelTripForm,
  setUIFilterSetTrip
} from "./ui_action";

export const FETCH_TRIPS = "trips/FETCH_TRIPS";
export const SET_TRIPS = "trips/SET_TRIPS";
export const SET_TRIP = "trips/SET_TRIP";
export const ERROR_TRIPS = "trips/ERROR_TRIPS";

export const getTrips = state => state.trips.trips;
export const getTrip = state => state.trips.trip;
export const getTripsLoading = state => state.trips.loading;
export const getTripsError = state => state.trips.error;

export const fetchTrips = () => ({
  type: FETCH_TRIPS
});

export const setTrips  = value => ({
  type: SET_TRIPS,
  value
});

export const setTrip  = value => ({
  type: SET_TRIP,
  value
});

export const errorTrips  = message => ({
  type: ERROR_TRIPS,
  message
});

export const retrieveTrips = () => async (dispatch) => {
  dispatch(fetchTrips());
  try {
    const responseGetTrips = await axios.get(`${DJANGO_CORE_ADDR}/trips/list/`, { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } });
    await dispatch(setTrips(responseGetTrips.data));
  } catch (error) {
    console.error(error);
    await dispatch(errorTrips("Some Error!"));
  }
};

export const createTrip = (trip, notifyFunc) => async (dispatch, getState) => {
  dispatch(fetchTrips());
  if (!trip.number || !trip.license) {
    dispatch(errorTrips("Trip number and vehicle license are required"));
    notifyFunc(notifyError("Trip number and vehicle license are required"));
  } else {

    try {
        const responsePostTrip = await axios.post(
          `${DJANGO_CORE_ADDR}/trips/create/`,
          Object.assign(
            {},
            trip
          ),
          { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } }
        );
        console.log("Trip created!");
        notifyFunc(notifySuccess(`${trip.number} | ${trip.license}: Trip creation successful`));
        await dispatch(retrieveTrips());
        await dispatch(onCancelTripForm());

    } catch (error) {
      console.log(error.response);
      console.error(error);
      dispatch(errorTrips("Some Error!"));
      notifyFunc(notifyError("System error. Please try again later."));
    }
  }
};

export const retrieveTrip = () => async (dispatch, getState) => {
  dispatch(fetchTrips());
  try {
    const responseGetTrip = await axios.get(`${DJANGO_CORE_ADDR}/trips/list/?id=${getUIFilterSetTrip(getState())}`,
      { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } });
    if(responseGetTrip.data.length === 0) {
      const responseGetTrips = getTrips(getState());
      dispatch(setTrip(responseGetTrips[0]));
    } else {
      dispatch(setTrip(responseGetTrip.data));
    }
  } catch (error) {
    console.error(error);
    dispatch(errorTrips("Some Error!"));
  }
};

export const updateTrip = (trip, notifyFunc) => async (dispatch) => {
  try {
    const responsePutTrip = await axios.patch(
      `${DJANGO_CORE_ADDR}/trips/update/`,
      Object.assign(
        {},
        trip
      ),
      { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } }
    );
    console.log("Trip edited!");
    await notifyFunc(notifySuccess(`${trip.number} | ${trip.license}: Trip update successful`));
    await dispatch(retrieveTrips());
    await dispatch(retrieveTrip());
    await dispatch(onCancelTripForm());
  } catch (error) {
    console.error(error);
    dispatch(errorTrips("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};

export const removeTrip = (tripId, notifyFunc) => async (dispatch, getState) => {
  try {
    const responseDeleteTrip = await axios.delete(
      `${DJANGO_CORE_ADDR}/trips/delete/?id=${tripId}`,
      { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } }
    );
    console.log("Trip deleted!");
    await notifyFunc(notifySuccess(`Trip deletion successful`));
    await dispatch(setUIFilterSetTrip(getTrips(getState())[0].id));
    await dispatch(retrieveTrips());
    await dispatch(retrieveTrip());
    await dispatch(onCancelTripForm());
  } catch (error) {
    console.error(error);
    dispatch(errorTrips("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};