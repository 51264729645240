import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";

export const FETCH_DAILYSTATS = "dailystats/FETCH_DAILYSTATS";
export const SET_DAILYSTATS = "dailystats/SET_DAILYSTATS";
export const ERROR_DAILYSTATS = "dailystats/ERROR_DAILYSTATS";

export const getDailyStats = state => state.dailystats.stats;
export const getDailyStatsLoading = state => state.dailystats.loading;
export const getDailyStatsError = state => state.dailystats.error;

export const fetchDailyStats = () => ({
  type: FETCH_DAILYSTATS
});

export const setDailyStats = value => ({
  type: SET_DAILYSTATS,
  value
});

export const errorDailyStats = message => ({
  type: ERROR_DAILYSTATS,
  message
});

export const retrieveDailyStats = () => async dispatch => {
  dispatch(fetchDailyStats());
  try {
    const responseGetDailyStats = await axios.get(
      `${DJANGO_CORE_ADDR}/dailystats/`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );

    const total = [];
    responseGetDailyStats.data.menu.forEach(menu => {
      const order = responseGetDailyStats.data.orders.find(
        ordermenu => ordermenu.menu === menu.id
      );

      const pending = responseGetDailyStats.data.pending.find(
        pendingmenu => pendingmenu.menu === menu.id
      );

      let am_menu_A_sum = 0;
      let am_menu_B_sum = 0;
      let pm_menu_A_sum = 0;
      let pm_menu_B_sum = 0;
      let freepax_sum = 0;

      if (order) {
        am_menu_A_sum += order.am_menu_A_sum;
        am_menu_B_sum += order.am_menu_B_sum;
        pm_menu_A_sum += order.pm_menu_A_sum;
        pm_menu_B_sum += order.pm_menu_B_sum;
        freepax_sum += order.freepax_sum;
      }

      if (pending) {
        am_menu_A_sum += pending.am_menu_A_sum;
        am_menu_B_sum += pending.am_menu_B_sum;
        pm_menu_A_sum += pending.pm_menu_A_sum;
        pm_menu_B_sum += pending.pm_menu_B_sum;
        freepax_sum += pending.freepax_sum;
      }

      total.push(
        Object.assign(
          {},
          {
            menu: menu.id,
            menuname: menu.name,
            am_menu_A_sum: am_menu_A_sum,
            am_menu_B_sum: am_menu_B_sum,
            pm_menu_A_sum: pm_menu_A_sum,
            pm_menu_B_sum: pm_menu_B_sum,
            am_menu_A_item:
              menu[`am_menu_A_${responseGetDailyStats.data.day}`]["name"],
            am_menu_B_item:
              menu[`am_menu_B_${responseGetDailyStats.data.day}`]["name"],
            pm_menu_A_item:
              menu[`pm_menu_A_${responseGetDailyStats.data.day}`]["name"],
            pm_menu_B_item:
              menu[`pm_menu_B_${responseGetDailyStats.data.day}`]["name"],
            freepax_sum: freepax_sum
          }
        )
      );
    });

    dispatch(
      setDailyStats({
        total,
        date: responseGetDailyStats.data.date,
        day: responseGetDailyStats.data.day
      })
    );
  } catch (error) {
    console.error(error);
    dispatch(errorDailyStats("Some Error!"));
  }
};
