import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Label,
  Input
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Button, FormInputs } from "../";

import {
  getCredit,
  createCredit,
  updateCredit,
  removeCredit,
  retrieveOutstandings
} from "../../actions/credit_action";
import {
  getUICreditIsNew,
  getUICreditIsEdit,
  onCancelCreditForm
} from "../../actions/ui_action";
import { notifyError } from "../NotificationOptions";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

class CreditFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.creditIsNew
      ? {
          date: null,
          month: null,
          year: null,
          description: "",
          amount: 0,
          remaining: 0.0,
          selectedInvoices: []
        }
      : {
          ...this.props.credit,
          customer: this.props.credit.customer && this.props.credit.customer.id
        };

    this.options = {
      sortIndicator: false
    };
  }

  componentDidMount() {
    if (this.props.creditIsNew) {
      this.props.retrieveOutstandings();
    }
  }

  validateForm = () => {
    if (this.props.creditIsNew) {
      return !!(
        this.state.date &&
        this.state.description &&
        this.state.amount &&
        this.state.remaining === 0 &&
        this.state.selectedInvoices.length === 1
      );
    }
    return !!this.state.description;
  };

  dateInvoiceFormatter = (cell, row) =>
    row.month && row.year ? `${row.month}/${row.year}` : null;

  salesFormatter = (cell, row) => (cell ? `$${cell.toFixed(2)}` : `$0.00`); 

  actionFormatterInvoice = (cell, row) => (
    <div>
      <React.Fragment>
        <Input
          type="checkbox"
          disabled={
            this.state.remaining === 0 ||
            this.state.selectedInvoices.length === 1
          }
          id={`creditInvoice${cell}`}
          onChange={event => {
            if (event.target.checked) {
              console.log("Credit Invoice: ", cell);
              this.setState(prevState => ({
                selectedInvoices: [
                  ...prevState.selectedInvoices,
                  {
                    id: cell,
                    name: row.name,
                    amount:
                      prevState.remaining >= row.balance
                        ? row.balance
                        : prevState.remaining
                  }
                ],
                remaining:
                  prevState.remaining >= row.balance
                    ? parseFloat((prevState.remaining - row.balance).toFixed(2))
                    : 0.0
              }));
            } else {
              console.log("uncredit Invoice: ", cell);

              const oldIndex = this.state.selectedInvoices.findIndex(
                invoice => invoice.id === cell
              );
              const oldAmount = this.state.selectedInvoices[oldIndex].amount;

              this.setState(prevState => ({
                selectedInvoices: prevState.selectedInvoices.filter(
                  (value, index) => index !== oldIndex
                ),
                remaining: parseFloat(
                  (prevState.remaining + oldAmount).toFixed(2)
                )
              }));
            }
          }}
        />
        <UncontrolledTooltip
          placement="left"
          target={`creditInvoice${cell}`}
          delay={0}
        >
          Credit Invoice
        </UncontrolledTooltip>
      </React.Fragment>
    </div>
  );

  calculateRemainingWithNewAmount = amount => {
    return amount;
  };

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.credit.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">
            Credit Note Details
          </CardTitle>
          {this.props.creditIsEdit && (
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-round btn-simple btn-icon"
                color="default"
              >
                <i className="now-ui-icons arrows-1_minimal-down" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() =>
                    this.props.removeCredit(this.state.id, options =>
                      this.props.notifyFunc(options)
                    )
                  }
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </CardHeader>
        <CardBody>
          <form>
            <div className="row">
              <div className="col-md-12 pr-3">
                <Label for="date">Date</Label>
                <div className="datepicker-wrap-myclass">
                  <DatePicker
                    id="date"
                    dateFormat="dd/MM/yyyy"
                    disabled={this.props.creditIsEdit}
                    selected={
                      this.state.date
                        ? moment.unix(this.state.date).toDate()
                        : null
                    }
                    onChange={value => {
                      this.setState({
                        date: value ? moment(value).unix() : null
                      });
                      this.setState({
                        month: value ? moment(value).month() + 1 : null
                      });
                      this.setState({
                        year: value ? moment(value).year() : null
                      });
                    }}
                    isClearable={this.props.creditIsNew}
                  />
                </div>
              </div>
            </div>
            <FormInputs
              ncols={["col-md-12 pr-3"]}
              proprieties={[
                {
                  label: "Description",
                  inputProps: {
                    type: "textarea",
                    value: this.state.description,
                    placeholder: "Item Description",
                    onChange: event =>
                      this.setState({ description: event.target.value })
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-6 pr-3", "col-md-6 pr-3"]}
              proprieties={[
                {
                  label: "Amount ($)",
                  inputProps: {
                    type: "number",
                    step: "0.01",
                    placeholder: "Amount",
                    value: this.state.amount,
                    disabled:
                      this.props.creditIsEdit ||
                      this.state.selectedInvoices.length > 0,
                    onChange: event =>
                      this.setState({
                        amount: event.target.value
                          ? parseFloat(event.target.value)
                          : "",
                        remaining: this.calculateRemainingWithNewAmount(
                          event.target.value
                            ? parseFloat(event.target.value)
                            : ""
                        )
                      })
                  }
                },
                {
                  label: "Remaining Amount($)",
                  inputProps: {
                    type: "number",
                    step: "0.01",
                    disabled: true,
                    value: this.state.remaining
                  }
                }
              ]}
            />
          </form>
          {this.props.creditIsNew &&
            this.state.amount > 0 && (
              <React.Fragment>
                <BootstrapTable
                  data={this.props.credit.outstandings.invoices}
                  options={this.options}
                  tableHeaderClass="table-column-header"
                  tableBodyClass="table-body-myclass"
                  bordered={false}
                  height="250"
                  scrollTop={"Top"}
                >
                  <TableHeaderColumn
                    dataField="id"
                    isKey
                    hidden
                    searchable={false}
                    editable={false}
                  >
                    Invoice ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="name"
                    dataSort
                    editable={false}
                    tdStyle={{ whiteSpace: "normal" }}
                  >
                    Outstanding Invoice
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="id"
                    dataFormat={this.dateInvoiceFormatter}
                    dataSort
                    editable={false}
                    tdStyle={{ whiteSpace: "normal" }}
                    width="150"
                  >
                    Date
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="totalSales"
                    dataFormat={this.salesFormatter}
                    dataSort
                    editable={false}
                    tdStyle={{ whiteSpace: "normal" }}
                    width="200"
                  >
                    Total ($)
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="balance"
                    dataFormat={this.salesFormatter}
                    dataSort
                    editable={false}
                    tdStyle={{ whiteSpace: "normal" }}
                    width="200"
                  >
                    Balance ($)
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="id"
                    dataFormat={this.actionFormatterInvoice}
                    editable={false}
                    headerAlign="right"
                    dataAlign="right"
                  >
                    Credit
                  </TableHeaderColumn>
                </BootstrapTable>
              </React.Fragment>
            )}
          {this.props.creditIsNew &&
            this.state.selectedInvoices.length > 0 && (
              <div style={{ border: "1px solid #ccc", paddingTop: "10px" }}>
                <div
                  style={{
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "10px",
                    paddingLeft: "10px",
                    color: "#f96332"
                  }}
                >
                  Credit Summary
                </div>
                <div style={{ padding: "10px" }}>
                  {this.state.selectedInvoices.map(invoice => (
                    <div
                      key={`invoice${invoice.id}`}
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>Invoice ( {invoice.name} )</span>
                      <span>${invoice.amount.toFixed(2)}</span>
                    </div>
                  ))}
                  <div style={{ textAlign: "right" }}>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          selectedInvoices: [],
                          amount: 0,
                          remaining: 0
                        });
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              </div>
            )}
          {this.props.creditIsEdit &&
            this.state.creditInvoices.length > 0 && (
              <div style={{ border: "1px solid #ccc", paddingTop: "10px" }}>
                <div
                  style={{
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "10px",
                    paddingLeft: "10px",
                    color: "#f96332"
                  }}
                >
                  Credit Summary
                </div>
                <div style={{ padding: "10px" }}>
                  {this.state.creditInvoices.map(invoice => (
                    <div
                      key={
                        invoice.invoice
                          ? `invoice${invoice.invoice.id}`
                          : `invoice${invoice.invoicegst.id}`
                      }
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>
                        {invoice.invoice
                          ? `Invoice: ${invoice.invoice.name} , Date: ${
                              invoice.invoice.month
                            } / ${invoice.invoice.year}`
                          : `Invoice: ${invoice.invoicegst.name} , Date: ${
                              invoice.invoicegst.month
                            } / ${invoice.invoicegst.year}`}
                      </span>
                      <span>${this.state.amount}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
        </CardBody>
        <CardFooter className="card-footer-buttons-padding">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Button
              color="primary"
              style={{ width: "20%" }}
              onClick={
                this.validateForm()
                  ? this.props.creditIsEdit
                    ? () =>
                        this.props.updateCredit(this.state, options =>
                          this.props.notifyFunc(options)
                        )
                    : () => {
                        this.props.createCredit(this.state, options =>
                          this.props.notifyFunc(options)
                        );
                      }
                  : () => {
                      this.state.remaining === 0
                        ? this.props.notifyFunc(
                            notifyError(`Please fill up all fields`)
                          )
                        : this.props.notifyFunc(
                            notifyError(
                              `Credit amount has to be spent. Remaining amount must be zero.`
                            )
                          );
                    }
              }
            >
              Save
            </Button>
            <Button
              color="secondary"
              style={{ width: "75%" }}
              onClick={() => {
                this.props.onCancelCreditForm();
              }}
            >
              Cancel
            </Button>
          </div>
        </CardFooter>
      </Card>
    );
  }
}

export default connect(
  createStructuredSelector({
    credit: getCredit,
    creditIsNew: getUICreditIsNew,
    creditIsEdit: getUICreditIsEdit
  }),
  {
    createCredit,
    updateCredit,
    removeCredit,
    retrieveOutstandings,
    onCancelCreditForm
  }
)(CreditFormComponent);
