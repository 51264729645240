import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getUserError, loginUserGetToken } from "../../actions/user_action";
import { Card, CardHeader, CardBody, Button, Progress } from "reactstrap";
import { FormInputs } from "../../components";

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
  }

  render() {
    return (
      <Card
        className="card-chart"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}
      >
        <CardHeader>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h6 className="title">ISO Delight Pte Ltd</h6>
            <span
              style={{
                fontSize: "0.7rem",
                color: "rgba(0, 0, 0, 0.2)"
              }}
            >
              v0.0.1
            </span>
          </div>
        </CardHeader>
        <CardBody>
          <form>
            <FormInputs
              ncols={["col-md-12 pr-3"]}
              proprieties={[
                {
                  label: "Username",
                  inputProps: {
                    type: "text",
                    value: this.state.username,
                    onChange: event =>
                      this.setState({ username: event.target.value })
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-12 pr-3"]}
              proprieties={[
                {
                  label: "Password",
                  inputProps: {
                    type: "password",
                    value: this.state.password,
                    onChange: event =>
                      this.setState({ password: event.target.value })
                  }
                }
              ]}
            />
            {this.props.userError && (
              <span
                style={{
                  width: "100%",
                  color: "red",
                  fontSize: "0.7rem"
                }}
              >
                {this.props.userError}
              </span>
            )}

            {/*<Progress animated value={100} />*/}
            <Button
              color="primary"
              style={{ width: "100%" }}
              onClick={() =>
                this.props.loginUserGetToken(
                  this.state.username,
                  this.state.password
                )
              }
            >
              Login
            </Button>
          </form>
        </CardBody>
      </Card>
    );
  }
}

export default connect(
  createStructuredSelector({
    userError: getUserError
  }),
  {
    loginUserGetToken
  }
)(LoginComponent);
