import React from "react";
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardFooter
} from "reactstrap";

import {
  Button,
  FormInputs,
} from "../";

import {
  getRate,
  updateRate,
} from '../../actions/rates_action';

import {
  getUIRateIsEdit,
  editRate,
  onCancelRateForm,
} from '../../actions/ui_action';

class RateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state =
      {
        ...this.props.rate,
      };
  }

  render() {
    return (
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="title">Rates</h5>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn-round btn-simple btn-icon"
                        color="default"
                      >
                        <i className="now-ui-icons arrows-1_minimal-down"/>
                      </DropdownToggle>
                      {!this.props.rateIsEdit &&
                        <DropdownMenu right>
                          <DropdownItem onClick={() => this.props.editRate()}>Edit</DropdownItem>
                        </DropdownMenu>
                      }
                    </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                    <form>
                      <FormInputs
                        ncols={["col-md-4 pr-3", "col-md-4 pr-3", "col-md-4 pr-3"]}
                        proprieties={[
                          {
                            label: "Rate (A.M.)",
                            inputProps: {
                              type: "number",
                              step: "0.01",
                              placeholder: "A.M. Meal Rate",
                              disabled: !this.props.rateIsEdit,
                              value: this.state.am_rate,
                              onChange: event =>
                                this.setState({ am_rate: event.target.value ? parseFloat(event.target.value) : '' }),
                              style: this.state.am_rate ? null : {border: '2px solid red'}
                            }
                          },
                          {
                            label: "Rate (P.M.)",
                            inputProps: {
                              type: "number",
                              step: "0.01",
                              placeholder: "P.M. Meal Rate",
                              disabled: !this.props.rateIsEdit,
                              value: this.state.pm_rate,
                              onChange: event =>
                                this.setState({ pm_rate: event.target.value ? parseFloat(event.target.value) : '' }),
                              style: this.state.pm_rate ? null : {border: '2px solid red'}
                            }
                          },
                          {
                            label: "GST",
                            inputProps: {
                              type: "number",
                              step: "0.01",
                              placeholder: "GST (2 Decimal)",
                              disabled: !this.props.rateIsEdit,
                              value: this.state.gst_rate,
                              onChange: event =>
                                this.setState({ gst_rate: event.target.value ? parseFloat(event.target.value) : '' }),
                              style: this.state.gst_rate ? null : {border: '2px solid red'}
                            }
                          },
                        ]}
                      />
                    </form>
                </CardBody>
                {this.props.rateIsEdit &&
                <CardFooter className='card-footer-buttons-padding'>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      color="primary"
                      style={{ width: "20%" }}
                      onClick={() => this.props.updateRate(this.state, (options) => this.props.notifyFunc(options))}
                    >
                      Save
                    </Button>
                    <Button
                      color="secondary"
                      style={{ width: "75%" }}
                      onClick={() => this.props.onCancelRateForm()}
                    >
                      Cancel
                    </Button>
                  </div>
                </CardFooter>
                }
              </Card>
    );
  }
}

export default withRouter(connect(
  createStructuredSelector({
    rate: getRate,
    rateIsEdit: getUIRateIsEdit,
  }),
  {
    onCancelRateForm,
    editRate,
    updateRate,
  })(RateComponent));