const notifySuccess = message => ({
  place: "bc",
  type: "info",
  icon: "now-ui-icons travel_info",
  autoDismiss: 5,
  message,
});

const notifyError = message => ({
  place: "bc",
  type: "danger",
  icon: "now-ui-icons health_ambulance",
  autoDismiss: 5,
  message,
});

export {
  notifySuccess,
  notifyError,
};