import React from "react";
import moment from "moment";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Row, Col } from "reactstrap";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
// function that returns a color based on an interval of numbers

import {
  PanelHeader,
  DashChartComponent,
  DashChartComponentOptions,
  PendingOrdersComponent,
  DailyStatsComponent,
  CustomerSummaryOrdersComponent,
  CustomerSummaryInvoicesComponent
} from "../../components";

import {
  getUIFilterYear,
  getUIFilterMonth,
  onCancelOrderForm
} from "../../actions/ui_action";
import {
  getChartData,
  retrieveChartSalesData
} from "../../actions/chartdata_action";
import { retrieveCustomers } from "../../actions/customers_action";
import { retrievePendingOrders } from "../../actions/orders_action";
import {
  retrieveCustomersSummaryOrders,
  retrieveCustomersSummaryInvoices
} from "../../actions/customers_summary_action";
import { retrieveDailyStats } from "../../actions/dailystats_action";
import { retrieveMenus } from "../../actions/menus_action";
import { retrieveMenuItems } from "../../actions/menuitems_action";
import { retrieveTrips } from "../../actions/trips_action";
import { getUserID } from "../../actions/user_action";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.notifyRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.userID) {
      this.props.onCancelOrderForm();
      this.props.retrieveChartSalesData();
      this.props.retrieveCustomers();
      this.props.retrievePendingOrders();
      this.props.retrieveDailyStats();
      this.props.retrieveMenus();
      this.props.retrieveMenuItems();
      this.props.retrieveTrips();

      if (
        this.props.filterYear === moment().year() &&
        this.props.filterMonth === moment().month() + 1
      ) {
        this.props.retrieveCustomersSummaryOrders();
      } else {
        this.props.retrieveCustomersSummaryInvoices();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userID !== prevProps.userID) {
      this.props.retrieveChartSalesData();
      this.props.retrieveCustomers();
      this.props.retrievePendingOrders();

      if (
        this.props.filterYear === moment().year() &&
        this.props.filterMonth === moment().month() + 1
      ) {
        this.props.retrieveCustomersSummaryOrders();
      } else {
        this.props.retrieveCustomersSummaryInvoices();
      }
    }
  }

  onNotification = options => this.notifyRef.current.notificationAlert(options);

  render() {
    return (
      <div>
        <NotificationAlert ref={this.notifyRef} />
        <PanelHeader
          size="lg"
          content={
            <Line
              data={DashChartComponent(this.props.chartData)}
              options={DashChartComponentOptions}
            />
          }
        />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              {this.props.filterYear === moment().year() &&
              this.props.filterMonth === moment().month() + 1 ? (
                <CustomerSummaryOrdersComponent />
              ) : (
                <CustomerSummaryInvoicesComponent />
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <DailyStatsComponent />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <PendingOrdersComponent
                notifyFunc={options => this.onNotification(options)}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
    chartData: getChartData,
    filterYear: getUIFilterYear,
    filterMonth: getUIFilterMonth,
    userID: getUserID
  }),
  {
    retrieveChartSalesData,
    retrieveCustomers,
    retrievePendingOrders,
    retrieveCustomersSummaryOrders,
    retrieveCustomersSummaryInvoices,
    retrieveDailyStats,
    retrieveMenus,
    retrieveMenuItems,
    retrieveTrips,
    onCancelOrderForm
  }
)(Dashboard);
