import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button, CardFooterStats } from "../";

import { getUser } from "../../actions/user_action";
import { getCustomers } from "../../actions/customers_action";
import {
  getUICustomerSelectedID,
  selectCustomer,
  newCustomer
} from "../../actions/ui_action";

class CustomersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.options = {
      sortIndicator: false
    };
  }

  actionFormatter = (cell, row) => (
    <div>
      {
        <React.Fragment>
          <Button
            id={`viewCustomer${cell}`}
            round
            icon
            iconMini
            neutral
            disabled={row.id === this.props.customerSelectedID}
            color="info"
            onClick={() => {
              console.log("View Customer: ", cell);
              this.props.selectCustomer(cell);
            }}
          >
            <i className="now-ui-icons files_paper" />
          </Button>
          <UncontrolledTooltip
            placement="left"
            target={`viewCustomer${cell}`}
            delay={0}
          >
            View Customer
          </UncontrolledTooltip>
        </React.Fragment>
      }
    </div>
  );

  activeFormatter = (cell, row) => (
    <div>
      {cell ? (
        <span style={{ color: "red" }}>Not Active</span>
      ) : (
        <span>Active</span>
      )}
    </div>
  );

  customerFormatter = (cell, row) => (
    <div>
      {row.inactive ? (
        <span style={{ color: "red" }}>{cell}</span>
      ) : (
        <span>{cell}</span>
      )}
    </div>
  );

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.customers.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">Customers</CardTitle>
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-round btn-simple btn-icon"
              color="default"
            >
              <i className="now-ui-icons arrows-1_minimal-down" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => this.props.newCustomer()}>
                Add new Customer
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </CardHeader>
        <CardBody style={{ paddingBottom: 0 }}>
          <BootstrapTable
            data={this.props.customers}
            options={this.options}
            tableHeaderClass="table-column-header"
            tableBodyClass="table-body-myclass"
            trClassName={(row, rowIdx) =>
              row.id === this.props.customerSelectedID
                ? "table-highlight-myclass"
                : null
            }
            bordered={false}
            search={true}
            height="250"
            scrollTop={"Top"}
          >
            <TableHeaderColumn
              dataField="id"
              isKey
              hidden
              searchable={false}
              editable={false}
            >
              Customer ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="name"
              dataFormat={this.customerFormatter}
              dataSort
              editable={false}
              tdStyle={{ whiteSpace: "normal" }}
            >
              Customer
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="inactive"
              dataFormat={this.activeFormatter}
              dataSort
              editable={false}
              width="100"
            >
              Active
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="id"
              dataFormat={this.actionFormatter}
              editable={false}
              headerAlign="right"
              dataAlign="right"
              width="90"
            />
          </BootstrapTable>
        </CardBody>
        <CardFooter className="card-footer-margin">
          <CardFooterStats>
            {[
              {
                i: "now-ui-icons travel_info",
                t: `Total: ${this.props.customers.length} Customers`
              }
            ]}
          </CardFooterStats>
        </CardFooter>
      </Card>
    );
  }
}

export default connect(
  createStructuredSelector({
    user: getUser,
    customers: getCustomers,
    customerSelectedID: getUICustomerSelectedID
  }),
  {
    selectCustomer,
    newCustomer
  }
)(CustomersComponent);
