import Dashboard from "../views/Dashboard/Dashboard.jsx";
import DeliveryOrderPage from "../views/Dashboard/DeliveryOrderPage";
import SetFoodPrepPage from "../views/Dashboard/SetFoodPrepPage.jsx";
import SetMenuPage from "../views/Dashboard/SetMenuPage.jsx";

const dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "design_app",
    component: Dashboard,
  },
  {
    path: "/deliveryorder-page",
    name: "Delivery Order",
    icon: "files_box",
    component: DeliveryOrderPage,
  },
  {
    path: "/setcuisine-page",
    name: "Cuisine Management",
    icon: "loader_gear",
    component: SetMenuPage,
  },
  {
    path: "/setfoodprep-page",
    name: "Food Prep. Time",
    icon: "loader_gear",
    component: SetFoodPrepPage,
  },
  { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" },
];
export default dashRoutes;
