import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";

import {
  getUIFilterMonth,
  getUIFilterYear,
  getUICustomerSelectedID,
  getUIAccountSelectedID
} from "./ui_action";

export const FETCH_INVOICES = "invoices/FETCH_INVOICES";
export const SET_INVOICES = "invoices/SET_INVOICES";
export const ERROR_INVOICES = "invoices/ERROR_INVOICES";

export const getInvoices = state => state.invoices.invoices;
export const getInvoicesSum = state =>
  state.invoices.invoices.reduce(
    (accumulator, currentValue) => accumulator + currentValue.totalSales,
    0.0
  );
export const getInvoicesLoading = state => state.invoices.loading;
export const getInvoicesError = state => state.invoices.error;

export const fetchInvoices = () => ({
  type: FETCH_INVOICES
});

export const setInvoices = value => ({
  type: SET_INVOICES,
  value
});

export const errorInvoices = message => ({
  type: ERROR_INVOICES,
  message
});

export const retrieveInvoices = () => async (dispatch, getState) => {
  dispatch(fetchInvoices());
  try {
    const responseGetMode = await axios.get(`${DJANGO_CORE_ADDR}/mode/list/`, {
      headers: { Authorization: `JWT ${localStorage.getItem("token")}` }
    });
    const totalInvoices = [];

    if (responseGetMode.data.non_gst) {
      const responseGetInvoices = await axios.get(
        `${DJANGO_CORE_ADDR}/invoices/list/current/?id=${getUICustomerSelectedID(
          getState()
        )}&month=${getUIFilterMonth(getState())}&year=${getUIFilterYear(
          getState()
        )}`,
        { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
      );
      const responseGetInvoicesGST = await axios.get(
        `${DJANGO_CORE_ADDR}/invoices/list/gst/current/?id=${getUICustomerSelectedID(
          getState()
        )}&month=${getUIFilterMonth(getState())}&year=${getUIFilterYear(
          getState()
        )}`,
        { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
      );
      responseGetInvoices.data.forEach(invoice => {
        totalInvoices.push(Object.assign({}, invoice, { hasGST: false }));
      });
      responseGetInvoicesGST.data.forEach(invoice => {
        totalInvoices.push(Object.assign({}, invoice, { hasGST: true }));
      });
    } else {
      const responseGetInvoicesGST = await axios.get(
        `${DJANGO_CORE_ADDR}/invoices/list/gst/current/?id=${getUICustomerSelectedID(
          getState()
        )}&month=${getUIFilterMonth(getState())}&year=${getUIFilterYear(
          getState()
        )}`,
        { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
      );
      responseGetInvoicesGST.data.forEach(invoice => {
        totalInvoices.push(Object.assign({}, invoice, { hasGST: true }));
      });
    }
    dispatch(setInvoices(totalInvoices));
  } catch (error) {
    console.error(error);
    dispatch(errorInvoices("Some Error!"));
  }
};

export const retrieveAccountInvoices = () => async (dispatch, getState) => {
  if (getUIAccountSelectedID(getState())) {
    const showMonth =
      getUIFilterMonth(getState()) === 1
        ? 12
        : getUIFilterMonth(getState()) - 1;
    const showYear =
      getUIFilterMonth(getState()) === 1
        ? getUIFilterYear(getState()) - 1
        : getUIFilterYear(getState());

    dispatch(fetchInvoices());
    try {
      const responseGetMode = await axios.get(
        `${DJANGO_CORE_ADDR}/mode/list/`,
        {
          headers: { Authorization: `JWT ${localStorage.getItem("token")}` }
        }
      );
      const totalInvoices = [];

      if (responseGetMode.data.non_gst) {
        const responseGetInvoices = await axios.get(
          `${DJANGO_CORE_ADDR}/invoices/list/current/?id=${getUIAccountSelectedID(
            getState()
          )}&month=${showMonth}&year=${showYear}`,
          { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
        );
        const responseGetInvoicesGST = await axios.get(
          `${DJANGO_CORE_ADDR}/invoices/list/gst/current/?id=${getUIAccountSelectedID(
            getState()
          )}&month=${showMonth}&year=${showYear}`,
          { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
        );
        responseGetInvoices.data.forEach(invoice => {
          totalInvoices.push(Object.assign({}, invoice, { hasGST: false }));
        });
        responseGetInvoicesGST.data.forEach(invoice => {
          totalInvoices.push(Object.assign({}, invoice, { hasGST: true }));
        });
      } else {
        const responseGetInvoicesGST = await axios.get(
          `${DJANGO_CORE_ADDR}/invoices/list/gst/current/?id=${getUIAccountSelectedID(
            getState()
          )}&month=${showMonth}&year=${showYear}`,
          { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
        );
        responseGetInvoicesGST.data.forEach(invoice => {
          totalInvoices.push(Object.assign({}, invoice, { hasGST: true }));
        });
      }
      dispatch(setInvoices(totalInvoices));
    } catch (error) {
      console.error(error);
      dispatch(errorInvoices("Some Error!"));
    }
  }
};
