 import {
  FETCH_ORDERS,
  SET_ORDERS,
  SET_ACCOUNTS,
  SET_PENDING_ORDERS,
  SET_DELIVERY_ORDERS,
  ERROR_ORDERS,
} from "../actions/orders_action";

const INITIAL_STATE = {
  pendingOrders: [],
  orders: [],
  accounts: [],
  deliveryOrders: [],
  loading: false,
  error: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case SET_ORDERS:
      return {
        ...state,
        orders: action.value,
        error: '',
        loading: false,
      };
    case SET_ACCOUNTS:
      return {
        ...state,
        accounts: action.value,
        error: '',
        loading: false,
      };
    case SET_PENDING_ORDERS:
      return {
        ...state,
        pendingOrders: action.value,
        error: '',
        loading: false,
      };
    case SET_DELIVERY_ORDERS:
      return {
        ...state,
        deliveryOrders: action.value,
        error: '',
        loading: false,
      };
    case ERROR_ORDERS:
      return {
        ...state,
        error: action.message,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;