import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress
} from "reactstrap";
import { FormInputs } from "../";

import { getUser } from "../../actions/user_action";
import { getCustomer } from "../../actions/customer_action";

class CustomerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.customer
    };
  }

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.customer.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">Customer</CardTitle>
          {this.props.user.username !== "superadmin" && (
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-round btn-simple btn-icon"
                color="default"
              >
                <i className="now-ui-icons arrows-1_minimal-down" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => this.props.history.push(`/customer-page`)}
                >
                  Go to Customer
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </CardHeader>
        <CardBody>
          <form>
            <FormInputs
              ncols={["col-md-6 pr-3", "col-md-6 pr-3"]}
              proprieties={[
                {
                  label: "Company",
                  inputProps: {
                    type: "text",
                    disabled: true,
                    placeholder: "Company Name",
                    value: this.state.name,
                    onChange: event =>
                      this.setState({ name: event.target.value })
                  }
                },
                {
                  label: "Email address",
                  inputProps: {
                    type: "email",
                    disabled: true,
                    placeholder: "Email",
                    value: this.state.email,
                    onChange: event =>
                      this.setState({ email: event.target.value })
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-6 pr-3", "col-md-6 pr-3"]}
              proprieties={[
                {
                  label: "Office Contact",
                  inputProps: {
                    type: "number",
                    disabled: true,
                    placeholder: "Telephone Number",
                    value: this.state.contact,
                    onChange: event =>
                      this.setState({ contact: event.target.value })
                  }
                },
                {
                  label: "HP Contact",
                  inputProps: {
                    type: "number",
                    disabled: true,
                    placeholder: "Telephone Number",
                    value: this.state.altContact,
                    onChange: event =>
                      this.setState({
                        altContact: event.target.value
                      })
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-12 pr-3"]}
              proprieties={[
                {
                  label: "Address",
                  inputProps: {
                    type: "text",
                    disabled: true,
                    placeholder: "Office Address",
                    value: this.state.address,
                    onChange: event =>
                      this.setState({ address: event.target.value })
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-6 pr-3", "col-md-6 pr-3"]}
              proprieties={[
                {
                  label: "Country",
                  inputProps: {
                    type: "text",
                    disabled: true,
                    placeholder: "Country",
                    value: this.state.country,
                    onChange: event =>
                      this.setState({ country: event.target.value })
                  }
                },
                {
                  label: "Postal Code",
                  inputProps: {
                    type: "number",
                    disabled: true,
                    value: this.state.postal,
                    placeholder: "Postal Code",
                    onChange: event =>
                      this.setState({ postal: event.target.value })
                  }
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-12 pr-3"]}
              proprieties={[
                {
                  label: "Remarks",
                  inputProps: {
                    type: "textarea",
                    disabled: true,
                    value: this.state.remarks,
                    placeholder: "Customer Description / Remarks",
                    onChange: event =>
                      this.setState({ remarks: event.target.value })
                  }
                }
              ]}
            />
          </form>
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(
  connect(
    createStructuredSelector({
      user: getUser,
      customer: getCustomer
    }),
    {}
  )(CustomerComponent)
);
