import React from "react";
import moment from "moment";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Row, Col } from "reactstrap";

import {
  Button,
  PanelHeader,
  FormInputs,
  CustomersComponent,
  CustomerFormComponent,
  OrdersComponent,
  InvoicesComponent
} from "../../components";

import {
  getUIFilterMonth,
  getUIFilterYear,
  getUICustomerSelectedID,
  getUICustomerIsEdit,
  getUICustomerIsNew,
  onCancelOrderForm
} from "../../actions/ui_action";

import { retrieveCustomers } from "../../actions/customers_action";
import { getCustomer } from "../../actions/customer_action";
import { retrieveCurrentOrders } from "../../actions/orders_action";
import { retrieveInvoices } from "../../actions/invoices_action";
import { retrieveRate } from "../../actions/rates_action";
import { retrieveUsers } from "../../actions/users_action";

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.notifyRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.customer.id !== this.props.customer.id ||
      nextProps.customerIsEdit !== this.props.customerIsEdit ||
      nextProps.customerIsNew !== this.props.customerIsNew ||
      nextProps.filterMonth !== this.props.filterMonth ||
      nextProps.filterYear !== this.props.filterYear
    );
  }

  componentDidMount() {
    this.props.onCancelOrderForm();
    this.props.retrieveCustomers();
    this.props.retrieveRate();
    this.props.retrieveUsers();

    if (this.props.customerSelectedID !== null) {
      if (
        this.props.filterYear === moment().year() &&
        this.props.filterMonth === moment().month() + 1
      ) {
        this.props.retrieveCurrentOrders();
      } else {
        this.props.retrieveInvoices();
      }
    }
  }

  onNotification = options => this.notifyRef.current.notificationAlert(options);

  render() {
    return (
      <div>
        <NotificationAlert ref={this.notifyRef} />
        <PanelHeader size="sm" />
        <div className="content">
          {!(this.props.customerIsEdit || this.props.customerIsNew) && (
            <Row>
              <Col xs={12} md={12}>
                <CustomersComponent />
              </Col>
            </Row>
          )}

          {((this.props.customerSelectedID &&
            this.props.customer.id === this.props.customerSelectedID) ||
            this.props.customerIsNew) && (
            <Row>
              <Col xs={12} md={12}>
                <CustomerFormComponent
                  key={
                    this.props.customerIsNew || this.props.customerIsEdit
                      ? 0
                      : this.props.customerSelectedID
                  }
                  notifyFunc={options => this.onNotification(options)}
                />
              </Col>
            </Row>
          )}

          {this.props.customerSelectedID &&
            this.props.customer.id === this.props.customerSelectedID &&
            !(this.props.customerIsEdit || this.props.customerIsNew) && (
              <Row>
                <Col xs={12} md={12}>
                  {this.props.filterYear === moment().year() &&
                  this.props.filterMonth === moment().month() + 1 ? (
                    <OrdersComponent />
                  ) : (
                    <InvoicesComponent
                      notifyFunc={options => this.onNotification(options)}
                    />
                  )}
                </Col>
              </Row>
            )}
        </div>
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
    customerSelectedID: getUICustomerSelectedID,
    customerIsEdit: getUICustomerIsEdit,
    customerIsNew: getUICustomerIsNew,
    customer: getCustomer,
    filterMonth: getUIFilterMonth,
    filterYear: getUIFilterYear
  }),
  {
    retrieveCustomers,
    retrieveCurrentOrders,
    retrieveInvoices,
    retrieveRate,
    retrieveUsers,
    onCancelOrderForm
  }
)(Customer);
