import axios from "axios";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { notifySuccess, notifyError } from "../components/NotificationOptions";

import { DJANGO_CORE_ADDR } from "../config";

import {
  getUICustomerSelectedID,
  getUIFilterMenu,
  getUIFilterTrip,
  getUIAccountSelectedID,
} from "./ui_action";

import { getCustomer } from "./customer_action";

export const FETCH_ORDERS = "orders/FETCH_ORDERS";
export const SET_ORDERS = "orders/SET_ORDERS";
export const SET_ACCOUNTS = "orders/SET_ACCOUNTS";
export const SET_PENDING_ORDERS = "orders/SET_PENDING_ORDERS";
export const SET_DELIVERY_ORDERS = "orders/SET_DELIVERY_ORDERS";
export const ERROR_ORDERS = "orders/ERROR_ORDERS";

export const getOrders = (state) => state.orders.orders;
export const getAccounts = (state) => state.orders.accounts;
export const getPendingOrders = (state) => state.orders.pendingOrders;
export const getDeliveryOrders = (state) => state.orders.deliveryOrders;
export const getOrdersLoading = (state) => state.orders.loading;
export const getOrdersError = (state) => state.orders.error;

export const fetchOrders = () => ({
  type: FETCH_ORDERS,
});

export const setOrders = (value) => ({
  type: SET_ORDERS,
  value,
});

export const setAccounts = (value) => ({
  type: SET_ACCOUNTS,
  value,
});

export const setPendingOrders = (value) => ({
  type: SET_PENDING_ORDERS,
  value,
});

export const setDeliveryOrders = (value) => ({
  type: SET_DELIVERY_ORDERS,
  value,
});

export const errorOrders = (message) => ({
  type: ERROR_ORDERS,
  message,
});

export const retrievePendingOrders = () => async (dispatch) => {
  dispatch(fetchOrders());
  try {
    const responseGetOrders = await axios.get(
      `${DJANGO_CORE_ADDR}/orders/list/pending/`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    dispatch(setPendingOrders(responseGetOrders.data));
  } catch (error) {
    console.error(error);
    dispatch(errorOrders("Some Error!"));
  }
};

export const retrieveCurrentOrders = () => async (dispatch, getState) => {
  dispatch(fetchOrders());
  try {
    const responseGetOrders = await axios.get(
      `${DJANGO_CORE_ADDR}/orders/list/current/?id=${getUICustomerSelectedID(
        getState()
      )}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    dispatch(setOrders(responseGetOrders.data));
  } catch (error) {
    console.error(error);
    dispatch(errorOrders("Some Error!"));
  }
};

export const retrieveDeliveryOrders = () => async (dispatch, getState) => {
  dispatch(fetchOrders());
  try {
    const tripParameter = getUIFilterTrip(getState())
      ? `&trip=${getUIFilterTrip(getState())}`
      : "";
    const menuParameter = getUIFilterMenu(getState())
      ? `&menu=${getUIFilterMenu(getState())}`
      : "";
    const responseGetOrders = await axios.get(
      `${DJANGO_CORE_ADDR}/orders/list/delivery/?${tripParameter}${menuParameter}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );

    const deliveryOrders = [];

    let getMoment;

    if (moment().format("H") < 17) {
      getMoment = moment();
    } else {
      getMoment = moment().add(1, "days");
    }

    responseGetOrders.data.forEach((order) => {
      if (
        (order.active === "N" &&
          !moment.unix(order.effectiveDate).isAfter(getMoment, "day")) ||
        (order.active === "Y" &&
          (!order.expiryDate ||
            !moment.unix(order.expiryDate).isBefore(getMoment, "day")))
      ) {
        if (order.active === "N") {
          console.log("Pending Order Found: ", order);
          console.log(
            "Effective Date: ",
            order.effectiveDate,
            moment.unix(order.effectiveDate).format("DD/MM/YYYY")
          );
          console.log("Today Date: ", getMoment.format("DD/MM/YYYY"));
          console.log(
            "To Display? ",
            !moment.unix(order.effectiveDate).isAfter(getMoment, "day")
          );
        } else {
          console.log("Current Order Found: ", order);
          console.log(
            "Expiry Date: ",
            order.expiryDate,
            moment.unix(order.expiryDate).format("DD/MM/YYYY")
          );
          console.log("Today Date: ", getMoment.format("DD/MM/YYYY"));
          console.log("Has Expiry? ", order.expiryDate);
          console.log(
            "To Display? ",
            !order.expiryDate ||
              !moment.unix(order.expiryDate).isBefore(getMoment, "day")
          );
        }

        deliveryOrders.push(
          Object.assign(
            {},
            {
              id: order.id,
              customer: order.customer.name,
              name: order.name,
              address: order.address + (order.postal && ` S${order.postal}`),
              user: order.customer.user.username,
              menu: order.menu.name,
              trip: order.trip.number,
              free_pax: order.free_pax,
              distance_scale: order.distance_scale,
              allowPrint: order.allowPrint,
              expiryDate: order.expiryDate,
              am_menu_A_monday: {
                pax: order.am_menu_A_monday,
                menuitem: order.menu.am_menu_A_monday.name,
              },
              am_menu_B_monday: {
                pax: order.am_menu_B_monday,
                menuitem: order.menu.am_menu_B_monday.name,
              },
              pm_menu_A_monday: {
                pax: order.pm_menu_A_monday,
                menuitem: order.menu.pm_menu_A_monday.name,
              },
              pm_menu_B_monday: {
                pax: order.pm_menu_B_monday,
                menuitem: order.menu.pm_menu_B_monday.name,
              },
              am_menu_A_tuesday: {
                pax: order.am_menu_A_tuesday,
                menuitem: order.menu.am_menu_A_tuesday.name,
              },
              am_menu_B_tuesday: {
                pax: order.am_menu_B_tuesday,
                menuitem: order.menu.am_menu_B_tuesday.name,
              },
              pm_menu_A_tuesday: {
                pax: order.pm_menu_A_tuesday,
                menuitem: order.menu.pm_menu_A_tuesday.name,
              },
              pm_menu_B_tuesday: {
                pax: order.pm_menu_B_tuesday,
                menuitem: order.menu.pm_menu_B_tuesday.name,
              },
              am_menu_A_wednesday: {
                pax: order.am_menu_A_wednesday,
                menuitem: order.menu.am_menu_A_wednesday.name,
              },
              am_menu_B_wednesday: {
                pax: order.am_menu_B_wednesday,
                menuitem: order.menu.am_menu_B_wednesday.name,
              },
              pm_menu_A_wednesday: {
                pax: order.pm_menu_A_wednesday,
                menuitem: order.menu.pm_menu_A_wednesday.name,
              },
              pm_menu_B_wednesday: {
                pax: order.pm_menu_B_wednesday,
                menuitem: order.menu.pm_menu_B_wednesday.name,
              },
              am_menu_A_thursday: {
                pax: order.am_menu_A_thursday,
                menuitem: order.menu.am_menu_A_thursday.name,
              },
              am_menu_B_thursday: {
                pax: order.am_menu_B_thursday,
                menuitem: order.menu.am_menu_B_thursday.name,
              },
              pm_menu_A_thursday: {
                pax: order.pm_menu_A_thursday,
                menuitem: order.menu.pm_menu_A_thursday.name,
              },
              pm_menu_B_thursday: {
                pax: order.pm_menu_B_thursday,
                menuitem: order.menu.pm_menu_B_thursday.name,
              },
              am_menu_A_friday: {
                pax: order.am_menu_A_friday,
                menuitem: order.menu.am_menu_A_friday.name,
              },
              am_menu_B_friday: {
                pax: order.am_menu_B_friday,
                menuitem: order.menu.am_menu_B_friday.name,
              },
              pm_menu_A_friday: {
                pax: order.pm_menu_A_friday,
                menuitem: order.menu.pm_menu_A_friday.name,
              },
              pm_menu_B_friday: {
                pax: order.pm_menu_B_friday,
                menuitem: order.menu.pm_menu_B_friday.name,
              },
              am_menu_A_saturday: {
                pax: order.am_menu_A_saturday,
                menuitem: order.menu.am_menu_A_saturday.name,
              },
              am_menu_B_saturday: {
                pax: order.am_menu_B_saturday,
                menuitem: order.menu.am_menu_B_saturday.name,
              },
              pm_menu_A_saturday: {
                pax: order.pm_menu_A_saturday,
                menuitem: order.menu.pm_menu_A_saturday.name,
              },
              pm_menu_B_saturday: {
                pax: order.pm_menu_B_saturday,
                menuitem: order.menu.pm_menu_B_saturday.name,
              },
              am_menu_A_sunday: {
                pax: order.am_menu_A_sunday,
                menuitem: order.menu.am_menu_A_sunday.name,
              },
              am_menu_B_sunday: {
                pax: order.am_menu_B_sunday,
                menuitem: order.menu.am_menu_B_sunday.name,
              },
              pm_menu_A_sunday: {
                pax: order.pm_menu_A_sunday,
                menuitem: order.menu.pm_menu_A_sunday.name,
              },
              pm_menu_B_sunday: {
                pax: order.pm_menu_B_sunday,
                menuitem: order.menu.pm_menu_B_sunday.name,
              },
              am_size: order.menu.am_size,
              pm_size: order.menu.pm_size,
            }
          )
        );
      }
    });

    dispatch(setDeliveryOrders(deliveryOrders));
  } catch (error) {
    console.error(error);
    dispatch(errorOrders("Some Error!"));
  }
};

export const retrieveStatementOfAccount = (notifyFunc) => async (
  dispatch,
  getState
) => {
  try {
    await notifyFunc(notifySuccess(`Generating Statement...`));
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const customer = getCustomer(getState());

    const responseGetStatement = await axios.get(
      `${DJANGO_CORE_ADDR}/outstandings/liststatement/?id=${customer.id}&gst=${
        customer.hasGST
      }`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );

    console.log("Received Records: ", responseGetStatement.data);

    let pdf = {};

    if (customer.hasGST === "Y") {
      let customerInfo = [
        {
          text: `Company : ${customer.name}`,
        },
        {
          text: `Address : ${customer.address}`,
        },
        {
          text: `Singapore ${customer.postal}`,
        },
        {
          text: `E-Mail : ${customer.email}`,
        },
        {
          text: `Telephone : ${customer.contact}`,
        },
        {
          text: `HP : ${customer.altContact}`,
          margin: [0, 0, 0, 5],
        },
      ];

      let info = [];

      info.push(customerInfo);

      let pdfTable = [
        [
          {
            text: "Date",
            alignment: "center",
            style: "tableHeader",
            border: [false, true, false, true],
          },
          {
            text: "Ref",
            alignment: "center",
            style: "tableHeader",
            border: [false, true, false, true],
          },
          {
            text: "Description",
            alignment: "center",
            style: "tableHeader",
            border: [false, true, false, true],
          },
          {
            text: "Debit",
            alignment: "center",
            style: "tableHeader",
            border: [false, true, false, true],
          },
          {
            text: "Credit",
            alignment: "center",
            style: "tableHeader",
            border: [false, true, false, true],
          },
          {
            text: "Balance",
            alignment: "center",
            style: "tableHeader",
            border: [false, true, false, true],
          },
        ],
      ];

      responseGetStatement.data.statements.forEach((statement) => {
        pdfTable.push([
          {
            text: moment.unix(statement.date).format("DD/MM/YYYY"),
            alignment: "center",
            border: [false, false, false, false],
          },
          {
            text: statement.ref,
            alignment: "center",
            border: [false, false, false, false],
          },
          {
            text: statement.description,
            alignment: "left",
            border: [false, false, false, false],
          },
          {
            text: statement.debit ? `$${statement.debit.toFixed(2)}` : "",
            alignment: "center",
            border: [false, false, false, false],
          },
          {
            text: statement.credit ? `$${statement.credit.toFixed(2)}` : "",
            alignment: "center",
            border: [false, false, false, false],
          },
          {
            text: `$${statement.balance.toFixed(2)}`,
            alignment: "right",
            border: [false, false, false, false],
          },
        ]);
      });

      pdfTable.push([
        {
          text: " ",
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: " ",
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: " ",
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: " ",
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: " ",
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: " ",
          alignment: "center",
          border: [false, false, false, false],
        },
      ]);

      let pdfFooter = [
        [
          {
            text: "Current",
            alignment: "center",
            border: [true, true, true, true],
          },
          {
            text: "1 Month",
            alignment: "center",
            border: [true, true, true, true],
          },
          {
            text: "2 Months",
            alignment: "center",
            border: [true, true, true, true],
          },
          {
            text: "3 Months",
            alignment: "center",
            border: [true, true, true, true],
          },
          {
            text: "4 Months",
            alignment: "center",
            border: [true, true, true, true],
          },
          {
            text: "5 & Over",
            alignment: "center",
            border: [true, true, true, true],
          },
        ],
      ];

      pdfFooter.push([
        {
          text: `$${responseGetStatement.data.dues[0].toFixed(2)}`,
          alignment: "center",
          border: [true, true, true, true],
        },
        {
          text: `$${responseGetStatement.data.dues[1].toFixed(2)}`,
          alignment: "center",
          border: [true, true, true, true],
        },
        {
          text: `$${responseGetStatement.data.dues[2].toFixed(2)}`,
          alignment: "center",
          border: [true, true, true, true],
        },
        {
          text: `$${responseGetStatement.data.dues[3].toFixed(2)}`,
          alignment: "center",
          border: [true, true, true, true],
        },
        {
          text: `$${responseGetStatement.data.dues[4].toFixed(2)}`,
          alignment: "center",
          border: [true, true, true, true],
        },
        {
          text: `$${responseGetStatement.data.dues[5].toFixed(2)}`,
          alignment: "center",
          border: [true, true, true, true],
        },
      ]);

      pdf = {
        content: [
          {
            text: "ISO DELIGHT PTE LTD",
            style: "header",
            alignment: "center",
          },
          {
            text: "Company / GST Registration Number: 200918960G",
            alignment: "center",
          },
          {
            text: "1002 Tai Seng Ave, #01-2550 Singapore 534409",
            alignment: "center",
          },
          {
            text: "Telephone : 64876387       Facsimile : 62823655",
            alignment: "center",
          },
          {
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    text: "STATEMENT OF ACCOUNT",
                    style: "subheader",
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    alignment: "justify",
                    columns: [
                      info,
                      [
                        {
                          text: `Statement Date : ${moment(new Date()).format(
                            "DD/MM/YYYY"
                          )}`,
                          margin: [0, 0, 0, 5],
                          alignment: "right",
                        },
                        {
                          text: `Agent : ${customer.user.username}`,
                          margin: [0, 0, 0, 5],
                          alignment: "right",
                        },
                      ],
                    ],
                    border: [false, false, false, false],
                  },
                ],
              ],
            },
            margin: [0, 5, 0, 5],
          },
          {
            table: {
              widths: [50, 100, "*", 50, 50, 50],
              headerRows: 1,
              body: pdfTable,
            },
          },
          {
            table: {
              widths: ["*", "*", "*", "*", "*", "*"],
              headerRows: 1,
              body: pdfFooter,
            },
          },
        ],
        styles: {
          header: {
            fontSize: 16,
            bold: false,
            alignment: "justify",
            margin: [0, 0, 0, 5],
          },
          subheader: {
            fontSize: 12,
            bold: false,
            alignment: "justify",
            margin: [0, 0, 0, 5],
          },
          tableHeader: {
            bold: true,
            fontSize: 10,
          },
        },
        defaultStyle: { fontSize: 9 },
      };
    } else {
      let customerInfo = [
        {
          text: `Company : ${customer.name}`,
        },
        {
          text: `Address : ${customer.address}`,
        },
        {
          text: `Singapore ${customer.postal}`,
        },
        {
          text: `E-Mail : ${customer.email}`,
        },
        {
          text: `Telephone : ${customer.contact}`,
        },
        {
          text: `HP : ${customer.altContact}`,
          margin: [0, 0, 0, 5],
        },
      ];

      let info = [];

      info.push(customerInfo);

      let pdfTable = [
        [
          {
            text: "Date",
            alignment: "center",
            style: "tableHeader",
            border: [false, true, false, true],
          },
          {
            text: "Ref",
            alignment: "center",
            style: "tableHeader",
            border: [false, true, false, true],
          },
          {
            text: "Description",
            alignment: "center",
            style: "tableHeader",
            border: [false, true, false, true],
          },
          {
            text: "Debit",
            alignment: "center",
            style: "tableHeader",
            border: [false, true, false, true],
          },
          {
            text: "Credit",
            alignment: "center",
            style: "tableHeader",
            border: [false, true, false, true],
          },
          {
            text: "Balance",
            alignment: "center",
            style: "tableHeader",
            border: [false, true, false, true],
          },
        ],
      ];

      responseGetStatement.data.statements.forEach((statement) => {
        pdfTable.push([
          {
            text: moment.unix(statement.date).format("DD/MM/YYYY"),
            alignment: "center",
            border: [false, false, false, false],
          },
          {
            text: statement.ref,
            alignment: "center",
            border: [false, false, false, false],
          },
          {
            text: statement.description,
            alignment: "left",
            border: [false, false, false, false],
          },
          {
            text: statement.debit ? `$${statement.debit.toFixed(2)}` : "",
            alignment: "center",
            border: [false, false, false, false],
          },
          {
            text: statement.credit ? `$${statement.credit.toFixed(2)}` : "",
            alignment: "center",
            border: [false, false, false, false],
          },
          {
            text: `$${statement.balance.toFixed(2)}`,
            alignment: "right",
            border: [false, false, false, false],
          },
        ]);
      });

      pdfTable.push([
        {
          text: " ",
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: " ",
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: " ",
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: " ",
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: " ",
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: " ",
          alignment: "center",
          border: [false, false, false, false],
        },
      ]);

      let pdfFooter = [
        [
          {
            text: "Current",
            alignment: "center",
            border: [true, true, true, true],
          },
          {
            text: "1 Month",
            alignment: "center",
            border: [true, true, true, true],
          },
          {
            text: "2 Months",
            alignment: "center",
            border: [true, true, true, true],
          },
          {
            text: "3 Months",
            alignment: "center",
            border: [true, true, true, true],
          },
          {
            text: "4 Months",
            alignment: "center",
            border: [true, true, true, true],
          },
          {
            text: "5 & Over",
            alignment: "center",
            border: [true, true, true, true],
          },
        ],
      ];

      pdfFooter.push([
        {
          text: `$${responseGetStatement.data.dues[0].toFixed(2)}`,
          alignment: "center",
          border: [true, true, true, true],
        },
        {
          text: `$${responseGetStatement.data.dues[1].toFixed(2)}`,
          alignment: "center",
          border: [true, true, true, true],
        },
        {
          text: `$${responseGetStatement.data.dues[2].toFixed(2)}`,
          alignment: "center",
          border: [true, true, true, true],
        },
        {
          text: `$${responseGetStatement.data.dues[3].toFixed(2)}`,
          alignment: "center",
          border: [true, true, true, true],
        },
        {
          text: `$${responseGetStatement.data.dues[4].toFixed(2)}`,
          alignment: "center",
          border: [true, true, true, true],
        },
        {
          text: `$${responseGetStatement.data.dues[5].toFixed(2)}`,
          alignment: "center",
          border: [true, true, true, true],
        },
      ]);

      pdf = {
        content: [
          {
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    text: "STATEMENT OF ACCOUNT",
                    style: "subheader",
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    alignment: "justify",
                    columns: [
                      info,
                      [
                        {
                          text: `Statement Date : ${moment(new Date()).format(
                            "DD/MM/YYYY"
                          )}`,
                          margin: [0, 0, 0, 5],
                          alignment: "right",
                        },
                        {
                          text: `Agent : ${customer.user.username}`,
                          margin: [0, 0, 0, 5],
                          alignment: "right",
                        },
                      ],
                    ],
                    border: [false, false, false, false],
                  },
                ],
              ],
            },
            margin: [0, 5, 0, 5],
          },
          {
            table: {
              widths: [50, 50, "*", 50, 50, 50],
              headerRows: 1,
              body: pdfTable,
            },
          },
          {
            table: {
              widths: ["*", "*", "*", "*", "*", "*"],
              headerRows: 1,
              body: pdfFooter,
            },
          },
        ],
        styles: {
          header: {
            fontSize: 16,
            bold: false,
            alignment: "justify",
            margin: [0, 0, 0, 5],
          },
          subheader: {
            fontSize: 12,
            bold: false,
            alignment: "justify",
            margin: [0, 0, 0, 5],
          },
          tableHeader: {
            bold: true,
            fontSize: 10,
          },
        },
        defaultStyle: { fontSize: 9 },
      };
    }

    pdfMake.createPdf(pdf).open();
  } catch (error) {
    console.error(error);
    dispatch(errorOrders("Some Error!"));
  }
};
