import React from "react";
import NotificationAlert from 'react-notification-alert';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Row, Col } from "reactstrap";

import { PanelHeader, SetMenuItemAdminComponent } from "../../components";

import {
  getUIFilterSetMenuItem,
  getUIMenuItemIsEdit,
  getUIMenuItemIsNew,
  onCancelMenuItemForm,
} from '../../actions/ui_action';

import { getMenuItem, retrieveMenuItems, retrieveMenuItem } from '../../actions/menuitems_action';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.notifyRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(!this.props.menuItem)
    {
      return true;
    }
    return nextProps.menuItem.id !== this.props.menuItem.id ||
      nextProps.menuItemIsEdit !== this.props.menuItemIsEdit ||
      nextProps.menuItemIsNew !== this.props.menuItemIsNew ||
      nextProps.filterSetMenuItem !== this.props.filterSetMenuItem;
  }

  componentDidMount() {
    this.props.onCancelMenuItemForm();
    this.props.retrieveMenuItems();
    this.props.retrieveMenuItem();
  }

  onNotification = options => this.notifyRef.current.notificationAlert(options);

  render() {
    return (
      <div>
        <NotificationAlert ref={this.notifyRef} />
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <SetMenuItemAdminComponent
                key={this.props.menuItemIsNew || this.props.menuItemIsEdit ? 0 : this.props.menuItem.id}
                notifyFunc={(options) => this.onNotification(options)}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
    menuItem: getMenuItem,
    filterSetMenuItem: getUIFilterSetMenuItem,
    menuItemIsEdit: getUIMenuItemIsEdit,
    menuItemIsNew: getUIMenuItemIsNew,
  }),
  {
    retrieveMenuItems,
    retrieveMenuItem,
    onCancelMenuItemForm
  })(Menu);