import React from "react";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Row, Col } from "reactstrap";

import { PanelHeader, SetFoodPrepAdminComponent } from "../../components";

import {
  getUIFoodPrepIsEdit,
  onCancelFoodPrepForm
} from "../../actions/ui_action";

import { getFoodPrep, retrieveFoodPrep } from "../../actions/foodprep_action";

class FoodPrep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.notifyRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (Object.keys(this.props.foodPrep).length === 0) {
      return true;
    }

    return nextProps.foodPrepIsEdit !== this.props.foodPrepIsEdit;
  }

  componentDidMount() {
    this.props.onCancelFoodPrepForm();
    this.props.retrieveFoodPrep();
  }

  onNotification = options => this.notifyRef.current.notificationAlert(options);

  render() {
    return (
      <div>
        <NotificationAlert ref={this.notifyRef} />
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <SetFoodPrepAdminComponent
                key={this.props.foodPrepIsEdit ? 0 : this.props.foodPrep.id}
                notifyFunc={options => this.onNotification(options)}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
    foodPrep: getFoodPrep,
    foodPrepIsEdit: getUIFoodPrepIsEdit
  }),
  {
    retrieveFoodPrep,
    onCancelFoodPrepForm
  }
)(FoodPrep);
