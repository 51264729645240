import axios from "axios";
import moment from 'moment';
import { DJANGO_CORE_ADDR } from "../config";

import { getUIFilterSetMenu, setUIFilterSetMenu, onCancelMenuForm } from "./ui_action";
import { getUser } from "./user_action";
import { notifyError, notifySuccess } from "../components/NotificationOptions";

export const FETCH_MENUS = "menus/FETCH_MENUS";
export const SET_MENUS = "menus/SET_MENUS";
export const SET_MENU = "menus/SET_MENU";
export const ERROR_MENUS = "menus/ERROR_MENUS";

export const getMenus = state => state.menus.menus;
export const getMenu = state => state.menus.menu;
export const getMenusLoading = state => state.menus.loading;
export const getMenusError = state => state.menus.error;

export const fetchMenus = () => ({
  type: FETCH_MENUS
});

export const setMenus  = value => ({
  type: SET_MENUS,
  value
});

export const setMenu  = value => ({
  type: SET_MENU,
  value
});

export const errorMenus  = message => ({
  type: ERROR_MENUS,
  message
});

export const retrieveMenus = () => async (dispatch) => {
  dispatch(fetchMenus());
  try {
    const responseGetMenus = await axios.get(`${DJANGO_CORE_ADDR}/menus/list/`, { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } });
    await dispatch(setMenus(responseGetMenus.data));
  } catch (error) {
    console.error(error);
    await dispatch(errorMenus("Some Error!"));
  }
};

export const createMenu = (menu, notifyFunc) => async (dispatch, getState) => {
  dispatch(fetchMenus());
  if (!menu.name) {
    dispatch(errorMenus("Cuisine Name is required"));
  } else {
    try {

        const responsePostMenu = await axios.post(
          `${DJANGO_CORE_ADDR}/menus/create/`,
          Object.assign(
            {},
            menu,
            {
              lastUpdateUser: getUser(getState()).id,
              lastUpdateDate: moment().unix()
            }
          ),
          { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } }
        );
        console.log("Cuisine created!");
        notifyFunc(notifySuccess(`${menu.name}: Cuisine creation successful`));
        await dispatch(retrieveMenus());
        await dispatch(onCancelMenuForm());

    } catch (error) {
      console.error(error);
      dispatch(errorMenus("Some Error!"));
      notifyFunc(notifyError("System error. Please try again later."));
    }
  }
};

export const retrieveMenu = () => async (dispatch, getState) => {
  dispatch(fetchMenus());
  try {
    const responseGetMenu = await axios.get(`${DJANGO_CORE_ADDR}/menus/list/?id=${getUIFilterSetMenu(getState())}`,
      { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } });
    if(responseGetMenu.data.length === 0) {
      const responseGetMenus = getMenus(getState());
      dispatch(setMenu(responseGetMenus[0]));
    } else {
      dispatch(setMenu(responseGetMenu.data));
    }
  } catch (error) {
    console.error(error);
    dispatch(errorMenus("Some Error!"));
  }
};

export const updateMenu = (menu, notifyFunc) => async (dispatch, getState) => {
  try {
    const responsePutMenu = await axios.patch(
      `${DJANGO_CORE_ADDR}/menus/update/`,
      Object.assign(
        {},
        menu,
        {
          lastUpdateUser: getUser(getState()).id,
          lastUpdateDate: moment().unix()
        }
      ),
      { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } }
    );
    console.log("Cuisine edited!");
    await notifyFunc(notifySuccess(`${menu.name}: Cuisine update successful`));
    await dispatch(retrieveMenus());
    await dispatch(retrieveMenu());
    await dispatch(onCancelMenuForm());
  } catch (error) {
    console.error(error);
    dispatch(errorMenus("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};

export const removeMenu = (menuId, notifyFunc) => async (dispatch, getState) => {
  try {
    const responseDeleteMenu = await axios.delete(
      `${DJANGO_CORE_ADDR}/menus/delete/?id=${menuId}`,
      { headers: { Authorization: `JWT ${localStorage.getItem('token')}` } }
    );
    console.log("Cuisine deleted!");
    await notifyFunc(notifySuccess(`Cuisine deletion successful`));
    await dispatch(retrieveMenus());
    await dispatch(setUIFilterSetMenu(getMenus(getState())[0].id));
    await dispatch(onCancelMenuForm());
  } catch (error) {
    console.error(error);
    dispatch(errorMenus("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};