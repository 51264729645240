import axios from "axios";
import backendAddr, { DJANGO_CORE_ADDR } from "../config";
import { getUser } from "./user_action";
import {
  getUICustomerSelectedID,
  getUIFilterMonth,
  getUIFilterYear
} from "./ui_action";

export const FETCH_CUSTOMERS_SUMMARY =
  "customers_summary/FETCH_CUSTOMERS_SUMMARY";
export const SET_CUSTOMERS_SUMMARY = "customers_summary/SET_CUSTOMERS_SUMMARY";
export const SET_CUSTOMERS_SUMMARY_LENGTH =
  "customers_summary/SET_CUSTOMERS_SUMMARY_LENGTH";
export const ERROR_CUSTOMERS_SUMMARY =
  "customers_summary/ERROR_CUSTOMERS_SUMMARY";

export const getSummary = state => state.customers_summary.summary;
export const getSummaryLength = state => state.customers_summary.length;
export const getSummaryLoading = state => state.customers_summary.loading;
export const getSummaryError = state => state.customers_summary.error;

export const fetchSummary = () => ({
  type: FETCH_CUSTOMERS_SUMMARY
});

export const setSummary = value => ({
  type: SET_CUSTOMERS_SUMMARY,
  value
});

export const setSummaryLength = value => ({
  type: SET_CUSTOMERS_SUMMARY_LENGTH,
  value
});

export const errorSummary = message => ({
  type: ERROR_CUSTOMERS_SUMMARY,
  message
});

export const retrieveCustomersSummaryOrders = () => async dispatch => {
  dispatch(fetchSummary());
  try {
    const responseGetOrders = await axios.get(
      `${DJANGO_CORE_ADDR}/orders/list/summary/`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    dispatch(setSummaryLength(responseGetOrders.data.length));
    const current_customers_summary = [];
    let customerKey = 0;
    responseGetOrders.data.forEach(order => {
      if (customerKey !== order.customer.id) {
        customerKey = order.customer.id;
        current_customers_summary.push({
          id: order.customer.id,
          customer: order.customer.name,
          inactive: order.customer.inactive,
          orders: 1
        });
      } else {
        const summaryObject =
          current_customers_summary[current_customers_summary.length - 1];
        current_customers_summary[current_customers_summary.length - 1] = {
          id: summaryObject.id,
          customer: summaryObject.customer,
          inactive: order.customer.inactive,
          orders: summaryObject.orders + 1
        };
      }
    });
    dispatch(setSummary(current_customers_summary));
  } catch (error) {
    console.error(error);
    dispatch(errorSummary("Some Error!"));
  }
};

export const retrieveCustomersSummaryInvoices = () => async (
  dispatch,
  getState
) => {
  dispatch(fetchSummary());
  try {
    const responseGetMode = await axios.get(`${DJANGO_CORE_ADDR}/mode/list/`, {
      headers: { Authorization: `JWT ${localStorage.getItem("token")}` }
    });
    const current_customers_summary = [];
    let customerKey = 0;

    if (responseGetMode.data.non_gst) {
      const responseGetInvoices = await axios.get(
        `${DJANGO_CORE_ADDR}/invoices/list/summary/?month=${getUIFilterMonth(
          getState()
        )}&year=${getUIFilterYear(getState())}`,
        { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
      );
      const responseGetInvoicesGST = await axios.get(
        `${DJANGO_CORE_ADDR}/invoices/list/gst/summary/?month=${getUIFilterMonth(
          getState()
        )}&year=${getUIFilterYear(getState())}`,
        { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
      );
      const totalInvoices = responseGetInvoices.data.concat(
        responseGetInvoicesGST.data
      );
      console.log(totalInvoices);
      dispatch(setSummaryLength(totalInvoices.length));
      totalInvoices.sort(
        (a, b) => parseInt(a.customer.id) - parseInt(b.customer.id)
      );
      totalInvoices.forEach(invoice => {
        if (customerKey !== invoice.customer.id) {
          customerKey = invoice.customer.id;
          current_customers_summary.push({
            id: invoice.customer.id,
            customer: invoice.customer.name,
            invoices: 1,
            am_packets: invoice.am_packets,
            pm_packets: invoice.pm_packets,
            totalSales: invoice.totalSales
          });
        } else {
          const summaryObject =
            current_customers_summary[current_customers_summary.length - 1];
          current_customers_summary[current_customers_summary.length - 1] = {
            id: summaryObject.id,
            customer: summaryObject.customer,
            invoices: summaryObject.invoices + 1,
            am_packets: summaryObject.am_packets + invoice.am_packets,
            pm_packets: summaryObject.pm_packets + invoice.pm_packets,
            totalSales: summaryObject.totalSales + invoice.totalSales
          };
        }
      });
    } else {
      const responseGetInvoicesGST = await axios.get(
        `${DJANGO_CORE_ADDR}/invoices/list/gst/summary/?month=${getUIFilterMonth(
          getState()
        )}&year=${getUIFilterYear(getState())}`,
        { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
      );
      dispatch(setSummaryLength(responseGetInvoicesGST.data.length));
      responseGetInvoicesGST.data.forEach(invoice => {
        if (customerKey !== invoice.customer.id) {
          customerKey = invoice.customer.id;
          current_customers_summary.push({
            id: invoice.customer.id,
            customer: invoice.customer.name,
            invoices: 1,
            am_packets: invoice.am_packets,
            pm_packets: invoice.pm_packets,
            totalSales: invoice.totalSales
          });
        } else {
          const summaryObject =
            current_customers_summary[current_customers_summary.length - 1];
          current_customers_summary[current_customers_summary.length - 1] = {
            id: summaryObject.id,
            customer: summaryObject.customer,
            invoices: summaryObject.invoices + 1,
            am_packets: summaryObject.am_packets + invoice.am_packets,
            pm_packets: summaryObject.pm_packets + invoice.pm_packets,
            totalSales: summaryObject.totalSales + invoice.totalSales
          };
        }
      });
    }

    dispatch(setSummary(current_customers_summary));
  } catch (error) {
    console.error(error);
    dispatch(errorSummary("Some Error!"));
  }
};
