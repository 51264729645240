import {
  FETCH_USER,
  SET_USER,
  ERROR_USER
} from "../actions/selecteduser_action";

const INITIAL_STATE = {
  id: null,
  password: "",
  username: "",
  role: "",
  lastLogin: "",
  is_active: "",
  loading: false,
  error: ""
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        loading: true
      };
    case SET_USER:
      return {
        ...state,
        ...action.value,
        error: "",
        loading: false
      };
    case ERROR_USER:
      return {
        ...state,
        error: action.message,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
