import {
  FETCH_CUSTOMER,
  SET_CUSTOMER,
  ERROR_CUSTOMER,
} from "../actions/customer_action";

const INITIAL_STATE = {
  id: null,
  name: '',
  email: '',
  contact: '',
  altContact: '',
  address: '',
  country: '',
  postal: '',
  remarks: '',
  loading: false,
  error: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER:
      return {
        ...state,
        loading: true,
      };
    case SET_CUSTOMER:
      return {
        ...state,
        ...action.value,
        error: '',
        loading: false,
      };
    case ERROR_CUSTOMER:
      return {
        ...state,
        error: action.message,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;