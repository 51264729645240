import {
  FETCH_CHART_DATA,
  SET_CHART_DATA,
  ERROR_CHART_DATA,
} from "../actions/chartdata_action";

const INITIAL_STATE = {
  chartdata: [],
  loading: false,
  error: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CHART_DATA:
      return {
        ...state,
        loading: true,
      };
    case SET_CHART_DATA:
      return {
        chartdata: action.value,
        error: '',
        loading: false,
      };
    case ERROR_CHART_DATA:
      return {
        ...state,
        error: action.message,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;