import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
  Progress,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
  Button,
  CardCategory,
  CardFooterStats,
  CustomDropdownMonth,
  CustomDropdownYear,
  CustomDropdownAgent,
  ThisMonthButton
} from "../";

import {
  getAccounts,
  retrieveStatementOfAccount
} from "../../actions/orders_action";
import {
  retrieveAllInvoices,
  retrieveTotalPaxSummary,
  retrieveMonthlyInvoicesSummary
} from "../../actions/invoice_action";
import {
  getUIAccountSelectedID,
  selectAccount,
  getUIFilterAgent,
  getUIFilterYear,
  getUIFilterMonth,
  onFilterMonth_accounts,
  onFilterYear_accounts,
  onFilterYearMonth_accounts,
  onFilterAgent,
  newCredit,
  newDebit,
  newPayment
} from "../../actions/ui_action";

class AccountsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.options = {
      sortIndicator: false
    };
  }

  customerFormatter = (cell, row) => cell.name;

  dateFormatter = (cell, row) =>
    cell ? moment.unix(cell).format("DD/MM/YYYY") : null;

  actionFormatter = (cell, row) => (
    <div>
      {
        <React.Fragment>
          <Button
            id={`viewAccount${cell}`}
            round
            icon
            iconMini
            neutral
            disabled={row.id === this.props.accountSelectedID}
            color="info"
            onClick={() => {
              console.log("View Account: ", cell, row.hasGST);
              this.props.selectAccount(cell, row.hasGST);
            }}
          >
            <i className="now-ui-icons files_paper" />
          </Button>
          <UncontrolledTooltip
            placement="left"
            target={`viewAccount${cell}`}
            delay={0}
          >
            View Account
          </UncontrolledTooltip>
        </React.Fragment>
      }
    </div>
  );

  activeFormatter = (cell, row) => (
    <div>
      {cell ? (
        <span style={{ color: "red" }}>Not Active</span>
      ) : (
        <span>Active</span>
      )}
    </div>
  );

  customerFormatter = (cell, row) => (
    <div>
      {row.inactive ? (
        <span style={{ color: "red" }}>{cell}</span>
      ) : (
        <span>{cell}</span>
      )}
    </div>
  );

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.accounts.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">Accounts</CardTitle>
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-round btn-simple btn-icon"
              color="default"
            >
              <i className="now-ui-icons arrows-1_minimal-down" />
            </DropdownToggle>
            <DropdownMenu right>
              {this.props.accountSelectedID && (
                <React.Fragment>
                  <DropdownItem
                    onClick={() => {
                      this.props.newDebit();
                    }}
                  >
                    Add new Debit Note
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      this.props.newCredit();
                    }}
                  >
                    Add new Credit Note
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      this.props.newPayment();
                    }}
                  >
                    Add new Payment
                  </DropdownItem>
                  {this.props.filterYear === moment().year() &&
                    this.props.filterMonth === moment().month() + 1 && (
                      <DropdownItem
                        onClick={() => {
                          this.props.retrieveStatementOfAccount(options =>
                            this.props.notifyFunc(options)
                          );
                        }}
                      >
                        View Statement of Account
                      </DropdownItem>
                    )}
                  <DropdownItem divider />
                </React.Fragment>
              )}
              <DropdownItem
                onClick={() => {
                  this.props.retrieveTotalPaxSummary(options =>
                    this.props.notifyFunc(options)
                  );
                }}
              >
                View Total Pax Monthly Summary
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  this.props.retrieveMonthlyInvoicesSummary(options =>
                    this.props.notifyFunc(options)
                  );
                }}
              >
                View Invoices Monthly Summary
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  this.props.retrieveAllInvoices(options =>
                    this.props.notifyFunc(options)
                  );
                }}
              >
                Download All Invoices
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <CardCategory>
            <Row>
              <Col xs={12} md={2}>
                <CustomDropdownAgent
                  retrieveFunc={value => this.props.onFilterAgent(value)}
                />
              </Col>
              <Col xs={12} md={2}>
                <CustomDropdownMonth
                  retrieveFunc={value =>
                    this.props.onFilterMonth_accounts(value)
                  }
                />
              </Col>
              <Col xs={12} md={2}>
                <CustomDropdownYear
                  retrieveFunc={value =>
                    this.props.onFilterYear_accounts(value)
                  }
                />
              </Col>
              {!(
                this.props.filterYear === moment().year() &&
                this.props.filterMonth === moment().month() + 1
              ) && (
                <Col xs={12} md={2}>
                  <ThisMonthButton
                    retrieveFunc={(year, month) =>
                      this.props.onFilterYearMonth_accounts(year, month)
                    }
                  />
                </Col>
              )}
            </Row>
          </CardCategory>
        </CardHeader>
        <CardBody style={{ paddingBottom: 0 }}>
          <BootstrapTable
            data={this.props.accounts}
            options={this.options}
            tableHeaderClass="table-column-header"
            tableBodyClass="table-body-myclass"
            trClassName={(row, rowIdx) =>
              row.id === this.props.accountSelectedID
                ? "table-highlight-myclass"
                : null
            }
            bordered={false}
            search={true}
            height="250"
            scrollTop={"Top"}
          >
            <TableHeaderColumn
              dataField="id"
              isKey
              hidden
              searchable={false}
              editable={false}
            >
              Customer ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="name"
              dataFormat={this.customerFormatter}
              dataSort
              editable={false}
              tdStyle={{ whiteSpace: "normal" }}
            >
              Customer
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="inactive"
              dataFormat={this.activeFormatter}
              dataSort
              editable={false}
              width="100"
            >
              Active
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="id"
              dataFormat={this.actionFormatter}
              editable={false}
              headerAlign="right"
              dataAlign="right"
              width="90"
            />
          </BootstrapTable>
        </CardBody>
        <CardFooter className="card-footer-margin">
          <CardFooterStats>
            {[
              {
                i: "now-ui-icons travel_info",
                t: `Total: ${this.props.accounts.length} Accounts`
              }
            ]}
          </CardFooterStats>
        </CardFooter>
      </Card>
    );
  }
}

export default connect(
  createStructuredSelector({
    accounts: getAccounts,
    accountSelectedID: getUIAccountSelectedID,
    filterYear: getUIFilterYear,
    filterMonth: getUIFilterMonth,
    filterAgent: getUIFilterAgent
  }),
  {
    selectAccount,
    onFilterMonth_accounts,
    onFilterYear_accounts,
    onFilterYearMonth_accounts,
    onFilterAgent,
    newDebit,
    newCredit,
    newPayment,
    retrieveStatementOfAccount,
    retrieveAllInvoices,
    retrieveTotalPaxSummary,
    retrieveMonthlyInvoicesSummary
  }
)(AccountsComponent);
