import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";
import { errorOrders, fetchOrders, setAccounts } from "./orders_action";
import { getUIFilterAgent } from "./ui_action";

export const FETCH_CUSTOMERS = "customers/FETCH_CUSTOMERS";
export const SET_CUSTOMERS = "customers/SET_CUSTOMERS";
export const ERROR_CUSTOMERS = "customers/ERROR_CUSTOMERS";

export const getCustomers = state => state.customers.customers;
export const getCustomersLoading = state => state.customers.loading;
export const getCustomersError = state => state.customers.error;

export const fetchCustomers = () => ({
  type: FETCH_CUSTOMERS
});

export const setCustomers = value => ({
  type: SET_CUSTOMERS,
  value
});

export const errorCustomers = message => ({
  type: ERROR_CUSTOMERS,
  message
});

export const retrieveCustomers = () => async dispatch => {
  dispatch(fetchCustomers());
  try {
    const responseGetCustomers = await axios.get(
      `${DJANGO_CORE_ADDR}/customers/list/`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    dispatch(setCustomers(responseGetCustomers.data));
  } catch (error) {
    console.error(error);
    dispatch(errorCustomers("Some Error!"));
  }
};

export const retrieveAccounts = () => async (dispatch, getState) => {
  dispatch(fetchOrders());
  try {
    const responseGetAccounts = await axios.get(
      `${DJANGO_CORE_ADDR}/customers/list/accounts/?id=${getUIFilterAgent(
        getState()
      )}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    dispatch(setAccounts(responseGetAccounts.data));
  } catch (error) {
    console.error(error);
    dispatch(errorOrders("Some Error!"));
  }
};
