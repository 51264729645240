import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import JSZip from "jszip";
import filesaver from "file-saver";
import { notifySuccess, notifyError } from "../components/NotificationOptions";

import { getUIFilterMonth, getUIFilterYear } from "./ui_action";

export const FETCH_INVOICE = "invoice/FETCH_INVOICE";
export const SET_INVOICE = "invoice/SET_INVOICE";
export const ERROR_INVOICE = "invoice/ERROR_INVOICE";

export const getInvoice = state => state.invoice;
export const getInvoiceLoading = state => state.invoice.loading;
export const getInvoiceError = state => state.invoice.error;

export const fetchInvoice = () => ({
  type: FETCH_INVOICE
});

export const setInvoice = value => ({
  type: SET_INVOICE,
  value
});

export const errorInvoice = message => ({
  type: ERROR_INVOICE,
  message
});

const createInvoicePDF = data => {
  const shortMonth = moment(data.invoice.month, "MM").format("MMM");

  const pdfTable = [
    [
      {
        text: "Date",
        alignment: "center",
        style: "tableHeader",
        border: [true, true, true, false]
      },
      {
        text: " ",
        alignment: "center",
        style: "tableHeader",
        border: [false, true, false, true]
      },
      {
        text: "No of Packets",
        alignment: "center",
        style: "tableHeader",
        border: [false, true, false, true]
      },
      {
        text: " ",
        alignment: "center",
        style: "tableHeader",
        border: [false, true, false, true]
      },
      {
        text: "Amount",
        alignment: "center",
        style: "tableHeader",
        border: [true, true, true, false]
      }
    ],
    [
      {
        text: " ",
        alignment: "center",
        style: "tableHeader",
        border: [true, false, true, true]
      },
      { text: "AM", alignment: "center" },
      { text: "Daily", alignment: "center" },
      { text: "PM", alignment: "center" },
      {
        text: " ",
        alignment: "center",
        style: "tableHeader",
        border: [true, false, true, true]
      }
    ]
  ];

  data.sales.forEach(sale => {
    let amount = 0.0;

    if (sale.am_pax === sale.pm_pax) {
      amount = (
        sale.am_pax *
        (data.invoice.rate /
          moment(
            `${data.invoice.year}-${String(data.invoice.month).padStart(
              2,
              "0"
            )}`,
            "YYYY-MM"
          ).daysInMonth())
      ).toFixed(2);
      pdfTable.push([
        {
          text: `${String(sale.day).padStart(2, "0")} ${shortMonth}`,
          alignment: "center"
        },
        { text: " ", alignment: "center" },
        { text: sale.am_pax, alignment: "center" },
        { text: " ", alignment: "center" },
        { text: `$${amount}`, alignment: "center" }
      ]);
    } else if (sale.am_pax > sale.pm_pax) {
      amount = (
        sale.pm_pax *
          (data.invoice.rate /
            moment(
              `${data.invoice.year}-${String(data.invoice.month).padStart(
                2,
                "0"
              )}`,
              "YYYY-MM"
            ).daysInMonth()) +
        (sale.am_pax - sale.pm_pax) * data.invoice.am_rate
      ).toFixed(2);
      pdfTable.push([
        {
          text: `${String(sale.day).padStart(2, "0")} ${shortMonth}`,
          alignment: "center"
        },
        { text: sale.am_pax - sale.pm_pax, alignment: "center" },
        { text: sale.pm_pax, alignment: "center" },
        { text: " ", alignment: "center" },
        { text: `$${amount}`, alignment: "center" }
      ]);
    } else {
      amount = (
        sale.am_pax *
          (data.invoice.rate /
            moment(
              `${data.invoice.year}-${String(data.invoice.month).padStart(
                2,
                "0"
              )}`,
              "YYYY-MM"
            ).daysInMonth()) +
        (sale.pm_pax - sale.am_pax) * data.invoice.pm_rate
      ).toFixed(2);
      pdfTable.push([
        {
          text: `${String(sale.day).padStart(2, "0")} ${shortMonth}`,
          alignment: "center"
        },
        { text: " ", alignment: "center" },
        { text: sale.am_pax, alignment: "center" },
        { text: sale.pm_pax - sale.am_pax, alignment: "center" },
        { text: `$${amount}`, alignment: "center" }
      ]);
    }
  });

  pdfTable.push([
    { text: `Total`, alignment: "center", colSpan: 4 },
    { text: "" },
    { text: "" },
    { text: "" },
    {
      text: `$${data.invoice.totalSales.toFixed(2)}`,
      alignment: "center"
    }
  ]);

  const pdf = {
    content: [
      {
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: "INVOICE",
                style: "header",
                alignment: "center",
                border: [false, false, false, false]
              }
            ],
            [
              {
                table: {
                  widths: [45, 225, 65, "*"],
                  heights: 1,
                  headerRows: 0,
                  border: [false, false, false, false],
                  body: [
                    [
                      {
                        text: "Company",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ${data.invoice.customer.name}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: "Invoice No",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: INV/${data.invoice.year}/${
                          data.invoice.number
                        }`,
                        alignment: "left",
                        border: [false, false, false, false]
                      }
                    ],
                    [
                      {
                        text: "Address",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ${data.invoice.customer.address}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: "Date",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text:
                          `: ${moment(
                            `${data.invoice.year}-${String(
                              data.invoice.month
                            ).padStart(2, "0")}`,
                            "YYYY-MM"
                          ).daysInMonth()} ` +
                          `${moment(data.invoice.month, "MM").format("MMM")} ${
                            data.invoice.year
                          }`,
                        alignment: "left",
                        border: [false, false, false, false]
                      }
                    ],
                    [
                      {
                        text: "",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `Singapore ${data.invoice.customer.postal}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: "Agent",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ${data.invoice.customer.user.username}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      }
                    ],
                    [
                      {
                        text: "Telephone",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ${data.invoice.customer.contact}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: "Order",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ${data.invoice.order.name}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      }
                    ],
                    [
                      {
                        text: "HP",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ${data.invoice.customer.altContact}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: "Payment Terms",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ${data.invoice.order.terms}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      }
                    ],
                    [
                      {
                        text: "Email",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ${data.invoice.customer.email}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: "",
                        alignment: "left",
                        style: "tableHeader",
                        border: [false, false, false, false]
                      },
                      {
                        text: "",
                        alignment: "left",
                        border: [false, false, false, false]
                      }
                    ]
                  ]
                },
                border: [false, false, false, true]
              }
            ]
          ]
        },
        margin: [0, 5, 0, 5]
      },
      {
        alignment: "justify",
        columns: [
          [
            {
              text: `${data.invoice.menu.name} Food catered for the month of`
            },
            {
              text: `${moment(data.invoice.month, "MM").format("MMMM")} ${
                data.invoice.year
              }`
            }
          ],
          [
            {
              text: `Monthly Rate : $${data.invoice.rate.toFixed(2)}`,
              alignment: "right"
            },
            {
              text: `Daily Rate : $${(
                data.invoice.rate /
                moment(
                  `${data.invoice.year}-${String(data.invoice.month).padStart(
                    2,
                    "0"
                  )}`,
                  "YYYY-MM"
                ).daysInMonth()
              ).toFixed(2)}`,
              alignment: "right"
            },
            {
              text: `AM Rate : $${data.invoice.am_rate.toFixed(
                2
              )} , PM Rate : $${data.invoice.pm_rate.toFixed(2)}`,
              margin: [0, 0, 0, 5],
              alignment: "right"
            }
          ]
        ],
        margin: [5, 0, 5, 0]
      },
      {
        table: {
          widths: [100, "*", "*", "*", 100],
          headerRows: 1,
          body: pdfTable
        }
      }
    ],
    styles: {
      header: {
        fontSize: 14,
        bold: false,
        alignment: "justify"
      },
      subheader: {
        fontSize: 10,
        bold: true,
        alignment: "justify"
      },
      tableHeader: {
        bold: true,
        fontSize: 10
      },
      footer: {
        fontSize: 6
      }
    },
    defaultStyle: { fontSize: 9 },
    pageSize: "A4",
    pageMargins: [20, 40, 20, 10]
  };

  return pdf;
};

export const retrieveInvoice = (invoiceID, notifyFunc) => async dispatch => {
  // dispatch(fetchInvoice());
  try {
    await notifyFunc(notifySuccess(`Generating Invoice...`));
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const responseGetInvoice = await axios.get(
      `${DJANGO_CORE_ADDR}/invoices/list/?id=${invoiceID}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    console.log("Received Invoice Generation: ", responseGetInvoice.data);
    dispatch(setInvoice());
    pdfMake.createPdf(createInvoicePDF(responseGetInvoice.data)).open();
  } catch (error) {
    console.error(error);
    dispatch(errorInvoice("Some Error!"));
  }
};

const createInvoiceGSTPDF = data => {
  const shortMonth = moment(data.invoice.month, "MM").format("MMM");

  const pdfTable = [
    [
      {
        text: "Date",
        alignment: "center",
        style: "tableHeader",
        border: [true, true, true, false]
      },
      {
        text: " ",
        alignment: "center",
        style: "tableHeader",
        border: [false, true, false, true]
      },
      {
        text: "No of Packets",
        alignment: "center",
        style: "tableHeader",
        border: [false, true, false, true]
      },
      {
        text: " ",
        alignment: "center",
        style: "tableHeader",
        border: [false, true, false, true]
      },
      {
        text: "Amount",
        alignment: "center",
        style: "tableHeader",
        border: [true, true, true, false]
      }
    ],
    [
      {
        text: " ",
        alignment: "center",
        style: "tableHeader",
        border: [true, false, true, true]
      },
      { text: "AM", alignment: "center" },
      { text: "Daily", alignment: "center" },
      { text: "PM", alignment: "center" },
      {
        text: " ",
        alignment: "center",
        style: "tableHeader",
        border: [true, false, true, true]
      }
    ]
  ];

  data.sales.forEach(sale => {
    let amount = 0.0;

    if (sale.am_pax === sale.pm_pax) {
      amount = (
        sale.am_pax *
        (data.invoice.rate /
          moment(
            `${data.invoice.year}-${String(data.invoice.month).padStart(
              2,
              "0"
            )}`,
            "YYYY-MM"
          ).daysInMonth())
      ).toFixed(2);
      pdfTable.push([
        {
          text: `${String(sale.day).padStart(2, "0")} ${shortMonth}`,
          alignment: "center"
        },
        { text: " ", alignment: "center" },
        { text: sale.am_pax, alignment: "center" },
        { text: " ", alignment: "center" },
        { text: `$${amount}`, alignment: "center" }
      ]);
    } else if (sale.am_pax > sale.pm_pax) {
      amount = (
        sale.pm_pax *
          (data.invoice.rate /
            moment(
              `${data.invoice.year}-${String(data.invoice.month).padStart(
                2,
                "0"
              )}`,
              "YYYY-MM"
            ).daysInMonth()) +
        (sale.am_pax - sale.pm_pax) * data.invoice.am_rate
      ).toFixed(2);
      pdfTable.push([
        {
          text: `${String(sale.day).padStart(2, "0")} ${shortMonth}`,
          alignment: "center"
        },
        { text: sale.am_pax - sale.pm_pax, alignment: "center" },
        { text: sale.pm_pax, alignment: "center" },
        { text: " ", alignment: "center" },
        { text: `$${amount}`, alignment: "center" }
      ]);
    } else {
      amount = (
        sale.am_pax *
          (data.invoice.rate /
            moment(
              `${data.invoice.year}-${String(data.invoice.month).padStart(
                2,
                "0"
              )}`,
              "YYYY-MM"
            ).daysInMonth()) +
        (sale.pm_pax - sale.am_pax) * data.invoice.pm_rate
      ).toFixed(2);
      pdfTable.push([
        {
          text: `${String(sale.day).padStart(2, "0")} ${shortMonth}`,
          alignment: "center"
        },
        { text: " ", alignment: "center" },
        { text: sale.am_pax, alignment: "center" },
        { text: sale.pm_pax - sale.am_pax, alignment: "center" },
        { text: `$${amount}`, alignment: "center" }
      ]);
    }
  });

  const gstInPercent = (data.gst * 100).toFixed(0);
  const gstFormula = total =>
    (total * gstInPercent) / (100 + parseInt(gstInPercent));

  pdfTable.push([
    {
      text: `Total (inclusive of ${gstInPercent}% GST $${gstFormula(
        data.invoice.totalSales
      ).toFixed(2)})`,
      alignment: "center",
      colSpan: 4
    },
    { text: "" },
    { text: "" },
    { text: "" },
    {
      text: `$${data.invoice.totalSales.toFixed(2)}`,
      alignment: "center"
    }
  ]);

  const pdf = {
    content: [
      {
        text: "ISO DELIGHT PTE LTD",
        style: "header",
        alignment: "center"
      },
      {
        text: "Company / GST Registration Number: 200918960G",
        alignment: "center"
      },
      {
        text: "1002 Tai Seng Ave, #01-2550 Singapore 534409",
        alignment: "center"
      },
      {
        text: "Telephone : 64876387       Facsimile : 62823655",
        alignment: "center"
      },
      {
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: "TAX INVOICE",
                style: "subheader",
                alignment: "center",
                border: [false, true, false, false]
              }
            ],
            [
              {
                table: {
                  widths: [45, 225, 65, "*"],
                  heights: 1,
                  headerRows: 0,
                  border: [false, false, false, false],
                  body: [
                    [
                      {
                        text: "Company",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ${data.invoice.customer.name}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: "Tax Invoice No",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ISO/INV/${data.invoice.year}/${
                          data.invoice.number
                        }`,
                        alignment: "left",
                        border: [false, false, false, false]
                      }
                    ],
                    [
                      {
                        text: "Address",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ${data.invoice.customer.address}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: "Date",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text:
                          `: ${moment(
                            `${data.invoice.year}-${String(
                              data.invoice.month
                            ).padStart(2, "0")}`,
                            "YYYY-MM"
                          ).daysInMonth()} ` +
                          `${moment(data.invoice.month, "MM").format("MMM")} ${
                            data.invoice.year
                          }`,
                        alignment: "left",
                        border: [false, false, false, false]
                      }
                    ],
                    [
                      {
                        text: "",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `Singapore ${data.invoice.customer.postal}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: "Agent",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ${data.invoice.customer.user.username}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      }
                    ],
                    [
                      {
                        text: "Telephone",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ${data.invoice.customer.contact}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: "Order",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ${data.invoice.order.name}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      }
                    ],
                    [
                      {
                        text: "HP",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ${data.invoice.customer.altContact}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: "Payment Terms",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ${data.invoice.order.terms}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      }
                    ],
                    [
                      {
                        text: "Email",
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: `: ${data.invoice.customer.email}`,
                        alignment: "left",
                        border: [false, false, false, false]
                      },
                      {
                        text: "",
                        alignment: "left",
                        style: "tableHeader",
                        border: [false, false, false, false]
                      },
                      {
                        text: "",
                        alignment: "left",
                        border: [false, false, false, false]
                      }
                    ]
                  ]
                },
                border: [false, false, false, true]
              }
            ]
          ]
        },
        margin: [0, 5, 0, 5]
      },
      {
        alignment: "justify",
        columns: [
          [
            {
              text: `${data.invoice.menu.name} Food catered for the month of`
            },
            {
              text: `${moment(data.invoice.month, "MM").format("MMMM")} ${
                data.invoice.year
              }`
            }
          ],
          [
            {
              text: `Monthly Rate (Incl GST) : $${data.invoice.rate.toFixed(
                2
              )}`,
              alignment: "right"
            },
            {
              text: `Daily Rate (Incl GST) : $${(
                data.invoice.rate /
                moment(
                  `${data.invoice.year}-${String(data.invoice.month).padStart(
                    2,
                    "0"
                  )}`,
                  "YYYY-MM"
                ).daysInMonth()
              ).toFixed(2)}`,
              alignment: "right"
            },
            {
              text: `AM Rate (Incl GST) : $${data.invoice.am_rate.toFixed(
                2
              )} , PM Rate (Incl GST) : $${data.invoice.pm_rate.toFixed(2)}`,
              margin: [0, 0, 0, 5],
              alignment: "right"
            }
          ]
        ],
        margin: [5, 0, 5, 0]
      },
      {
        table: {
          widths: [100, "*", "*", "*", 100],
          headerRows: 1,
          body: pdfTable
        }
      },
      {
        text: "This is a computer generated invoice. No signature is required.",
        style: "footer",
        alignment: "center"
      }
    ],
    styles: {
      header: {
        fontSize: 14,
        bold: false,
        alignment: "justify"
      },
      subheader: {
        fontSize: 10,
        bold: true,
        alignment: "justify"
      },
      tableHeader: {
        bold: true,
        fontSize: 10
      },
      footer: {
        fontSize: 6
      }
    },
    defaultStyle: { fontSize: 9 },
    pageSize: "A4",
    pageMargins: [20, 40, 20, 10]
  };

  return pdf;
};

export const retrieveInvoiceGST = (invoiceID, notifyFunc) => async dispatch => {
  // dispatch(fetchInvoice());
  try {
    await notifyFunc(notifySuccess(`Generating Invoice...`));
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const responseGetInvoice = await axios.get(
      `${DJANGO_CORE_ADDR}/invoices/list/gst/?id=${invoiceID}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    console.log("Received GST Invoice Generation: ", responseGetInvoice.data);
    dispatch(setInvoice());
    pdfMake.createPdf(createInvoiceGSTPDF(responseGetInvoice.data)).open();
  } catch (error) {
    console.error(error);
    dispatch(errorInvoice("Some Error!"));
  }
};

export const retrieveAllInvoices = notifyFunc => async (dispatch, getState) => {
  dispatch(fetchInvoice());

  const showMonth =
    getUIFilterMonth(getState()) === 1 ? 12 : getUIFilterMonth(getState()) - 1;
  const showYear =
    getUIFilterMonth(getState()) === 1
      ? getUIFilterYear(getState()) - 1
      : getUIFilterYear(getState());

  try {
    // await notifyFunc(notifySuccess(`Please wait! Generating Invoices...`));
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const responseGetInvoice = await axios.get(
      `${DJANGO_CORE_ADDR}/invoices/list/all/?month=${showMonth}&year=${showYear}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );

    const payload = [];
    const zip = new JSZip();

    responseGetInvoice.data.invoices.forEach(data => {
      payload.push({
        invoice: data,
        gst: responseGetInvoice.data.gst,
        sales: responseGetInvoice.data.allsales.filter(
          sale => sale.order.id === data.order.id
        )
      });
    });

    responseGetInvoice.data.invoicesnon.forEach(data => {
      payload.push({
        invoice: data,
        sales: responseGetInvoice.data.allsales.filter(
          sale => sale.order.id === data.order.id
        )
      });
    });

    payload.forEach((data, index) => {
      const pdf = data.hasOwnProperty("gst")
        ? createInvoiceGSTPDF(data)
        : createInvoicePDF(data);
      const pdfname = data.hasOwnProperty("gst")
        ? `ISO-INV-${data.invoice.year}-${data.invoice.number}.pdf`
        : `INV-${data.invoice.year}-${data.invoice.number}.pdf`;

      pdfMake.createPdf(pdf).getBlob(async blob => {
        await zip.file(pdfname, blob, {
          binary: true
        });

        if (index === payload.length - 1) {
          zip
            .generateAsync({ type: "blob" }, function updateCallback(metadata) {
              console.log("progression: " + metadata.percent.toFixed(2) + " %");
              if (metadata.currentFile) {
                console.log("current file = " + metadata.currentFile);
              }
            })
            .then(content => {
              filesaver.saveAs(
                content,
                `invoices_${showMonth}_${showYear}.zip`
              );
              dispatch(setInvoice());
            });
        }
      });
    });

    if (payload.length === 0) {
      await notifyFunc(
        notifySuccess(`No Invoices found for selected month & year.`)
      );
      dispatch(setInvoice());
    }
  } catch (error) {
    console.error(error);
    dispatch(errorInvoice("Some Error!"));
  }
};

const createTotalPaxPDF = (data, month, year) => {
  const numberOfDays = moment(month, "MM").daysInMonth();
  const shortMonth = moment(month, "MM").format("MMM");

  let sumAM = 0;
  let sumPM = 0;
  let sumTotal = 0;
  let sumFree = 0;

  const pdfTable = [
    [
      {
        text: "Date",
        alignment: "center",
        style: "tableHeader",
        border: [true, true, true, false]
      },
      {
        text: "Total A.M. ",
        alignment: "center",
        style: "tableHeader",
        border: [false, true, false, true]
      },
      {
        text: "Total P.M",
        alignment: "center",
        style: "tableHeader",
        border: [false, true, false, true]
      },
      {
        text: "Total Pax",
        alignment: "center",
        style: "tableHeader",
        border: [false, true, false, true]
      },
      {
        text: "Total Free Pax",
        alignment: "center",
        style: "tableHeader",
        border: [true, true, true, false]
      }
    ]
  ];

  data.forEach(sale => {
    pdfTable.push([
      {
        text: `${String(sale.day).padStart(2, "0")} ${shortMonth}`,
        alignment: "center"
      },
      { text: sale.am_pax_sum, alignment: "center" },
      { text: sale.pm_pax_sum, alignment: "center" },
      { text: sale.am_pax_sum + sale.pm_pax_sum, alignment: "center" },
      { text: sale.freepax_sum, alignment: "center" }
    ]);

    sumAM += sale.am_pax_sum;
    sumPM += sale.pm_pax_sum;
    sumTotal += sale.am_pax_sum + sale.pm_pax_sum;
    sumFree += sale.freepax_sum;
  });

  pdfTable.push([
    {
      text: `Average`,
      alignment: "center",
      style: "tableHeader"
    },
    {
      text: Math.round(sumAM / numberOfDays),
      alignment: "center",
      style: "tableHeader"
    },
    {
      text: Math.round(sumPM / numberOfDays),
      alignment: "center",
      style: "tableHeader"
    },
    {
      text: Math.round(sumTotal / numberOfDays),
      alignment: "center",
      style: "tableHeader"
    },
    {
      text: Math.round(sumFree / numberOfDays),
      alignment: "center",
      style: "tableHeader"
    }
  ]);

  const pdf = {
    content: [
      {
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: "Total Pax Summary",
                style: "header",
                alignment: "center",
                border: [false, false, false, false]
              }
            ]
          ]
        },
        margin: [0, 5, 0, 5]
      },
      {
        alignment: "justify",
        columns: [
          [
            {
              text: `Food catered for the month of ${moment(month, "MM").format(
                "MMMM"
              )} ${year}`
            }
          ]
        ],
        margin: [0, 0, 0, 10]
      },
      {
        table: {
          widths: [100, "*", "*", "*", 100],
          headerRows: 1,
          body: pdfTable
        }
      }
    ],
    styles: {
      header: {
        fontSize: 14,
        bold: false,
        alignment: "justify"
      },
      subheader: {
        fontSize: 10,
        bold: true,
        alignment: "justify"
      },
      tableHeader: {
        bold: true,
        fontSize: 10
      },
      footer: {
        fontSize: 6
      }
    },
    defaultStyle: { fontSize: 9 },
    pageSize: "A4",
    pageMargins: [20, 40, 20, 10]
  };
  return pdf;
};

export const retrieveTotalPaxSummary = notifyFunc => async (
  dispatch,
  getState
) => {
  dispatch(fetchInvoice());

  const showMonth = getUIFilterMonth(getState());
  const showYear = getUIFilterYear(getState());

  try {
    await notifyFunc(notifySuccess(`Generating Total Pax Summary...`));
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const responseGetTotal = await axios.get(
      `${DJANGO_CORE_ADDR}/totalpax/?month=${showMonth}&year=${showYear}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );

    console.log("Received Total Pax Summary: ", responseGetTotal.data);

    if (responseGetTotal.data.length === 0) {
      await notifyFunc(
        notifySuccess(`No sales found for selected month & year.`)
      );
    } else {
      pdfMake
        .createPdf(
          createTotalPaxPDF(responseGetTotal.data, showMonth, showYear)
        )
        .open();
    }

    dispatch(setInvoice());
  } catch (error) {
    console.error(error);
    dispatch(errorInvoice("Some Error!"));
  }
};

const createMonthlyInvoiceSummaryPDF = (data, month, year) => {
  let total_net = 0;
  let total_tax = 0;
  let total_total = 0;

  const pdfTable = [
    [
      {
        text: "Customer",
        alignment: "center",
        style: "tableHeader",
        border: [true, true, false, false]
      },
      {
        text: "Order",
        alignment: "center",
        style: "tableHeader",
        border: [false, true, false, true]
      },
      {
        text: "Invoice",
        alignment: "center",
        style: "tableHeader",
        border: [false, true, false, true]
      },
      {
        text: "Net",
        alignment: "center",
        style: "tableHeader",
        border: [false, true, false, true]
      },
      {
        text: "Tax",
        alignment: "center",
        style: "tableHeader",
        border: [false, true, false, true]
      },
      {
        text: "Total",
        alignment: "center",
        style: "tableHeader",
        border: [false, true, true, false]
      }
    ]
  ];

  data.invoices.forEach(invoice => {
    const tax = data.gst * (invoice.totalSales / (1 + data.gst));
    total_tax += tax;
    total_net += invoice.totalSales - tax;
    total_total += invoice.totalSales;

    pdfTable.push([
      {
        text: invoice.customer.name,
        alignment: "center"
      },
      { text: invoice.name, alignment: "center" },
      { text: `ISO/${year}/${invoice.number}`, alignment: "center" },
      {
        text: `$${(invoice.totalSales - tax).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`,
        alignment: "center"
      },
      {
        text: `$${tax.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`,
        alignment: "center"
      },
      {
        text: `$${invoice.totalSales.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`,
        alignment: "center"
      }
    ]);
  });

  data.invoicesnon.forEach(invoice => {
    total_net += invoice.totalSales;
    total_total += invoice.totalSales;

    pdfTable.push([
      {
        text: invoice.customer.name,
        alignment: "center"
      },
      { text: invoice.name, alignment: "center" },
      { text: `${year}/${invoice.number}`, alignment: "center" },
      {
        text: `$${invoice.totalSales.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`,
        alignment: "center"
      },
      {
        text: `$0.00`,
        alignment: "center"
      },
      {
        text: `$${invoice.totalSales.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`,
        alignment: "center"
      }
    ]);
  });

  pdfTable.push([
    {
      text: "",
      alignment: "center"
    },
    { text: "", alignment: "center" },
    { text: "", alignment: "center" },
    {
      text: `$${total_net.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`,
      alignment: "center"
    },
    {
      text: `$${total_tax.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`,
      alignment: "center"
    },
    {
      text: `$${total_total.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`,
      alignment: "center"
    }
  ]);

  const pdf = {
    content: [
      {
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: "Monthly Invoices Summary ",
                style: "header",
                alignment: "center",
                border: [false, false, false, false]
              }
            ]
          ]
        },
        margin: [0, 5, 0, 5]
      },
      {
        alignment: "justify",
        columns: [
          [
            {
              text: `Invoices for the month of ${moment(month, "MM").format(
                "MMMM"
              )} ${year}`
            }
          ]
        ],
        margin: [0, 0, 0, 10]
      },
      {
        table: {
          widths: ["*", "*", 100, 50, 50, 50],
          headerRows: 1,
          body: pdfTable
        }
      }
    ],
    styles: {
      header: {
        fontSize: 14,
        bold: false,
        alignment: "justify"
      },
      subheader: {
        fontSize: 10,
        bold: true,
        alignment: "justify"
      },
      tableHeader: {
        bold: true,
        fontSize: 10
      },
      footer: {
        fontSize: 6
      }
    },
    defaultStyle: { fontSize: 9 },
    pageSize: "A4",
    pageMargins: [20, 40, 20, 10]
  };
  return pdf;
};

export const retrieveMonthlyInvoicesSummary = notifyFunc => async (
  dispatch,
  getState
) => {
  dispatch(fetchInvoice());

  const showMonth =
    getUIFilterMonth(getState()) === 1 ? 12 : getUIFilterMonth(getState()) - 1;
  const showYear =
    getUIFilterMonth(getState()) === 1
      ? getUIFilterYear(getState()) - 1
      : getUIFilterYear(getState());

  try {
    await notifyFunc(notifySuccess(`Generating Monthly Invoices Summary...`));
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const responseGetInvoiceSummary = await axios.get(
      `${DJANGO_CORE_ADDR}/monthlyinvoices/?month=${showMonth}&year=${showYear}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );

    console.log(
      "Received Monthly Invoices Summary: ",
      responseGetInvoiceSummary.data
    );

    if (
      responseGetInvoiceSummary.data.invoices.length === 0 &&
      responseGetInvoiceSummary.data.invoicesnon.length === 0
    ) {
      await notifyFunc(
        notifySuccess(`No invoices found for selected month & year.`)
      );
    } else {
      pdfMake
        .createPdf(
          createMonthlyInvoiceSummaryPDF(
            responseGetInvoiceSummary.data,
            showMonth,
            showYear
          )
        )
        .open();
    }

    dispatch(setInvoice());
  } catch (error) {
    console.error(error);
    dispatch(errorInvoice("Some Error!"));
  }
};
