import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";
import { getUIFilterMonth, getUIFilterYear } from "./ui_action";

export const FETCH_PAYMENTS = "payments/FETCH_PAYMENTS";
export const SET_PAYMENTS = "payments/SET_PAYMENTS";
export const ERROR_PAYMENTS = "payments/ERROR_PAYMENTS";

export const getPayments = state => state.payments.payments;
export const getPaymentsSum = state =>
  state.payments.payments.reduce(
    (accumulator, currentValue) => accumulator + currentValue.amount,
    0.0
  );
export const getPaymentsLoading = state => state.payments.loading;
export const getPaymentsError = state => state.payments.error;

export const fetchPayments = () => ({
  type: FETCH_PAYMENTS
});

export const setPayments = value => ({
  type: SET_PAYMENTS,
  value
});

export const errorPayments = message => ({
  type: ERROR_PAYMENTS,
  message
});

export const retrievePayments = (accountID, isGST) => async (
  dispatch,
  getState
) => {
  if (accountID && isGST) {
    dispatch(fetchPayments());
    try {
      const responseGetPayments = await axios.get(
        `${DJANGO_CORE_ADDR}/payments/list/summary/?id=${accountID}&gst=${isGST}&year=${getUIFilterYear(
          getState()
        )}&month=${getUIFilterMonth(getState())}`,
        { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
      );
      dispatch(setPayments(responseGetPayments.data));
    } catch (error) {
      console.error(error);
      dispatch(errorPayments("Some Error!"));
    }
  }
};
