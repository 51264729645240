import React from "react";
import NotificationAlert from 'react-notification-alert';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  Row,
  Col,
} from "reactstrap";

import {
  PanelHeader,
  SalesOrdersComponent,
} from "../../components";

import { retrieveMenus } from '../../actions/menus_action';
import { retrieveTrips } from '../../actions/trips_action';
import { retrieveSalesOrders } from '../../actions/salesorders_action';

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.notifyRef = React.createRef();
  }

  componentDidMount() {
    this.props.retrieveMenus();
    this.props.retrieveTrips();
    this.props.retrieveSalesOrders();
  }

  onNotification = options => this.notifyRef.current.notificationAlert(options);

  render() {
    return (
      <div>
        <NotificationAlert ref={this.notifyRef} />
        <PanelHeader size="sm" />
        <div className="content">
              <Row>
                <Col xs={12} md={12}>
                  <SalesOrdersComponent
                    notifyFunc={(options) => this.onNotification(options)}
                  />
                </Col>
              </Row>
        </div>
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
  }),
  {
    retrieveMenus,
    retrieveTrips,
    retrieveSalesOrders,
  })(Order);