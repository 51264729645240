import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";
import moment from "moment";
import { notifySuccess, notifyError } from "../components/NotificationOptions";

import { getCustomers, retrieveCustomers } from "./customers_action";

import { getUser } from "./user_action";

import { getUICustomerSelectedID, onCancelCustomerForm } from "./ui_action";

export const FETCH_CUSTOMER = "customer/FETCH_CUSTOMER";
export const SET_CUSTOMER = "customer/SET_CUSTOMER";
export const ERROR_CUSTOMER = "customer/ERROR_CUSTOMER";

export const getCustomer = state => state.customer;
export const getCustomerLoading = state => state.customer.loading;
export const getCustomerError = state => state.customer.error;

export const fetchCustomer = () => ({
  type: FETCH_CUSTOMER
});

export const setCustomer = value => ({
  type: SET_CUSTOMER,
  value
});

export const errorCustomer = message => ({
  type: ERROR_CUSTOMER,
  message
});

export const createCustomer = (customer, notifyFunc) => async (
  dispatch,
  getState
) => {
  dispatch(fetchCustomer());
  if (!customer.name) {
    dispatch(errorCustomer("Customer Name is required"));
  } else {
    try {
      const responsePostCustomer = await axios.post(
        `${DJANGO_CORE_ADDR}/customers/create/`,
        Object.assign({}, customer, {
          user: customer.user ? customer.user : getUser(getState()).id,
          hasGST: "Y",
          lastUpdateUser: getUser(getState()).id,
          lastUpdateDate: moment().unix()
        }),
        { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
      );
      console.log(responsePostCustomer);
      // notifyFunc(notifySuccess(`${customer.name}: Customer creation successful`));
      await dispatch(retrieveCustomers());
      await dispatch(onCancelCustomerForm());
    } catch (error) {
      console.log(error.response);
      dispatch(errorCustomer("Some Error!"));
      if (error.response.status === 400 && "name" in error.response.data) {
        notifyFunc(notifyError(error.response.data.name[0]));
      } else {
        notifyFunc(notifyError("System error. Please try again later."));
      }
    }
  }
};

export const retrieveCustomer = () => async (dispatch, getState) => {
  if (getUICustomerSelectedID(getState())) {
    dispatch(fetchCustomer());
    try {
      const responseGetCustomer = await axios.get(
        `${DJANGO_CORE_ADDR}/customers/list/?id=${getUICustomerSelectedID(
          getState()
        )}`,
        { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
      );
      dispatch(setCustomer(responseGetCustomer.data));
    } catch (error) {
      console.error(error);
      dispatch(errorCustomer("Some Error!"));
    }
  }
};

export const updateCustomer = (customer, notifyFunc) => async (
  dispatch,
  getState
) => {
  try {
    const responsePutCustomer = await axios.patch(
      `${DJANGO_CORE_ADDR}/customers/update/`,
      Object.assign({}, customer, {
        lastUpdateUser: getUser(getState()).id,
        lastUpdateDate: moment().unix()
      }),
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    console.log("Customer edited!");
    await notifyFunc(
      notifySuccess(`${customer.name}: Customer update successful`)
    );
    await dispatch(retrieveCustomer(customer.id));
    await dispatch(onCancelCustomerForm());
  } catch (error) {
    console.error(error);
    dispatch(errorCustomer("Some Error!"));
    if (error.response.status === 400 && "name" in error.response.data) {
      notifyFunc(notifyError(error.response.data.name[0]));
    } else {
      notifyFunc(notifyError("System error. Please try again later."));
    }
  }
};
