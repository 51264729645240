import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Label,
  Input
} from "reactstrap";
import { Button, FormInputs } from "..";

import {
  getUser,
  createUser,
  updateUser
} from "../../actions/selecteduser_action";
import { getUsers } from "../../actions/users_action";
import {
  getUIUserSelectedID,
  getUIUserIsNew,
  getUIUserIsEdit,
  editUser,
  editUserPass,
  onCancelUserForm
} from "../../actions/ui_action";

class UserFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.userIsNew
      ? {
          username: "",
          password: "",
          groups: [{ name: "sales" }],
          is_active: "Y"
        }
      : {
          ...this.props.user,
          is_active: this.props.user.is_active ? "Y" : "N"
        };
  }

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.user.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">
            User Details
          </CardTitle>
          {!(this.props.userIsNew || this.props.userIsEdit) && (
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-round btn-simple btn-icon"
                color="default"
              >
                <i className="now-ui-icons arrows-1_minimal-down" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => this.props.editUser()}>
                  Edit
                </DropdownItem>
                <DropdownItem onClick={() => this.props.editUserPass()}>
                  Change Password
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </CardHeader>
        <CardBody>
          <form>
            <FormInputs
              ncols={["col-md-6 pr-3", "col-md-6 pr-3"]}
              proprieties={[
                {
                  label: "Username",
                  inputProps: {
                    type: "text",
                    disabled: !this.props.userIsNew,
                    placeholder: "User Name",
                    value: this.state.username,
                    onChange: event =>
                      this.setState({ username: event.target.value }),
                    style: this.state.username
                      ? null
                      : { border: "2px solid red" }
                  }
                },
                {
                  label: "Password",
                  inputProps: {
                    type: "password",
                    disabled: !this.props.userIsNew,
                    placeholder: "Password",
                    value: this.state.password,
                    onChange: event =>
                      this.setState({ password: event.target.value })
                  }
                }
              ]}
            />
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-md-6 pr-3">
                <Label for="roleSelect">Role</Label>
                <Input
                  id="roleSelect"
                  type="select"
                  name="select"
                  value={this.state.groups[0].name}
                  onChange={event =>
                    this.setState({ groups: [{ name: event.target.value }] })
                  }
                  disabled={!(this.props.userIsEdit || this.props.userIsNew)}
                  style={{ height: "35px", textIndent: "10px" }}
                >
                  <option value="sales">Sales</option>
                  <option value="operation">Operation</option>
                  <option value="admin">Administrator</option>
                </Input>
              </div>
              <div className="col-md-6 pr-3">
                <Label for="activeSelect">Set Active</Label>
                <Input
                  id="activeSelect"
                  type="select"
                  name="select"
                  value={this.state.is_active}
                  onChange={event =>
                    this.setState({ is_active: event.target.value })
                  }
                  disabled={!(this.props.userIsEdit || this.props.userIsNew)}
                  style={{ height: "35px", textIndent: "10px" }}
                >
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </Input>
              </div>
            </div>
          </form>
        </CardBody>
        {(this.props.userIsEdit || this.props.userIsNew) && (
          <CardFooter className="card-footer-buttons-padding">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Button
                color="primary"
                style={{ width: "20%" }}
                onClick={
                  this.props.userIsEdit
                    ? () =>
                        this.props.updateUser(this.state, options =>
                          this.props.notifyFunc(options)
                        )
                    : () =>
                        this.props.createUser(this.state, options =>
                          this.props.notifyFunc(options)
                        )
                }
              >
                Save
              </Button>
              <Button
                color="secondary"
                style={{ width: "75%" }}
                onClick={() => this.props.onCancelUserForm()}
              >
                Cancel
              </Button>
            </div>
          </CardFooter>
        )}
      </Card>
    );
  }
}

export default connect(
  createStructuredSelector({
    user: getUser,
    users: getUsers,
    userSelectedID: getUIUserSelectedID,
    userIsNew: getUIUserIsNew,
    userIsEdit: getUIUserIsEdit
  }),
  {
    createUser,
    updateUser,
    editUser,
    editUserPass,
    onCancelUserForm
  }
)(UserFormComponent);
