import {
  FETCH_SALESORDERS,
  SET_SALESORDERS,
  ERROR_SALESORDERS,
  SET_SALESORDERS_EDIT
} from "../actions/salesorders_action";

const INITIAL_STATE = {
  sales: {},
  edits: {},
  editsreset: {},
  loading: false,
  error: ""
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SALESORDERS:
      return {
        ...state,
        loading: true
      };
    case SET_SALESORDERS:
      return {
        ...state,
        sales: action.sales,
        edits: action.edits,
        error: "",
        loading: false
      };
    case SET_SALESORDERS_EDIT:
      return {
        ...state,
        edits: { id: action.orderID, key: action.dayKey }
      };
    case ERROR_SALESORDERS:
      return {
        ...state,
        error: action.message,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
