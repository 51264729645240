import defaultRoutes from "./dashboard_default";
import operationsRoutes from "./dashboard_operations";
import salesRoutes from "./dashboard_sales";
import adminRoutes from "./dashboard_admin";
import superadminRoutes from "./dashboard_superadmin";

import Login from "../layouts/Login/Login.jsx";
import Dashboard from "../layouts/Dashboard/Dashboard.jsx";

const indexRoutes = [
  { path: "/login", name: "Login", component: Login },
  { path: "/", name: "Home", component: Dashboard }
];

const getRoutes = role => {
  switch (role) {
    case "operation":
      return operationsRoutes;
    case "sales":
      return salesRoutes;
    case "admin":
      return adminRoutes;
    case "superadmin":
      return superadminRoutes;
    default:
      return defaultRoutes;
  }
};

export default indexRoutes;
export { operationsRoutes, salesRoutes, adminRoutes, getRoutes };
