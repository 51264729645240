import {
  FETCH_FOODPREP,
  SET_FOODPREP,
  ERROR_FOODPREP
} from "../actions/foodprep_action";

const INITIAL_STATE = {
  foodprep: {},
  loading: false,
  error: ""
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FOODPREP:
      return {
        ...state,
        loading: true
      };
    case SET_FOODPREP:
      return {
        ...state,
        foodprep: action.value,
        error: "",
        loading: false
      };
    case ERROR_FOODPREP:
      return {
        ...state,
        error: action.message,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
