import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  FormGroup,
  Label,
  Input
} from "reactstrap";
import {
  getUIFilterSetTrip,
  onFilterSetTrip
} from '../../../../actions/ui_action';

import {
  getTrips,
} from '../../../../actions/trips_action';

const CustomDropdownTrip = (props) => (
  <FormGroup>
    <Label for="filterTrip">Trip</Label>
    <Input
      type="select"
      name="select"
      id="filterTrip"
      value={props.trip}
      onChange={event => props.onFilterSetTrip(parseInt(event.target.value))}
    >
      {
        props.trips.map(
          trip => <option value={trip.id} key={trip.id}>{`${trip.number} ( ${trip.license} )`}</option>
        )
      }
    </Input>
  </FormGroup>
);

export default connect(
  createStructuredSelector({
    trips: getTrips,
    trip: getUIFilterSetTrip,
  }),
  {
    onFilterSetTrip
  })(CustomDropdownTrip);
