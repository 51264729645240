import React from "react";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Row, Col } from "reactstrap";
import {
  PanelHeader,
  UserManagementComponent,
  UserFormComponent,
  PassFormComponent
} from "../../components";

import {
  getUIUserSelectedID,
  getUIUserIsEditPass,
  getUIUserIsEdit,
  getUIUserIsNew,
  onCancelUserForm
} from "../../actions/ui_action";

import { retrieveUsers } from "../../actions/users_action";
import { getUser } from "../../actions/selecteduser_action";
class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.notifyRef = React.createRef();
  }


  componentDidMount() {
    this.props.onCancelUserForm();
    this.props.retrieveUsers();
  }

  onNotification = options => this.notifyRef.current.notificationAlert(options);

  render() {
    return (
      <div>
        <NotificationAlert ref={this.notifyRef} />
        <PanelHeader size="sm" />
        <div className="content">
          {!(
            this.props.userIsEdit ||
            this.props.userIsNew ||
            this.props.userIsEditPass
          ) && (
            <Row>
              <Col xs={12} md={12}>
                <UserManagementComponent />
              </Col>
            </Row>
          )}

          {((!this.props.userIsEditPass &&
            this.props.userSelectedID &&
            this.props.user.id === this.props.userSelectedID) ||
            this.props.userIsNew) && (
            <Row>
              <Col xs={12} md={12}>
                <UserFormComponent
                  key={
                    this.props.userIsNew || this.props.userIsEdit
                      ? 0
                      : this.props.userSelectedID
                  }
                  notifyFunc={options => this.onNotification(options)}
                />
              </Col>
            </Row>
          )}

          {this.props.userIsEditPass && (
            <Row>
              <Col xs={12} md={12}>
                <PassFormComponent
                  key={
                    this.props.userIsEditPass ? 0 : this.props.userSelectedID
                  }
                  notifyFunc={options => this.onNotification(options)}
                />
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
    userSelectedID: getUIUserSelectedID,
    userIsEditPass: getUIUserIsEditPass,
    userIsEdit: getUIUserIsEdit,
    userIsNew: getUIUserIsNew,
    user: getUser
  }),
  {
    retrieveUsers,
    onCancelUserForm
  }
)(UserManagement);
