import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  FormGroup,
  Label,
  Input
} from "reactstrap";
import {
  getUIFilterMenu,
} from '../../../../actions/ui_action';

import {
  getMenus,
} from '../../../../actions/menus_action';

const CustomDropdownMenu = (props) => (
  <FormGroup>
    <Label for="filterMenu">Cuisine</Label>
    <Input
      type="select"
      name="select"
      id="filterMenu"
      value={props.menu}
      onChange={event => props.retrieveFunc(parseInt(event.target.value))}
    >
      {
        props.menus.map(
          menu => <option value={menu.id} key={menu.id}>{menu.name}</option>
        )
      }
      <option value='0' key='all'>All Cuisines</option>
    </Input>
  </FormGroup>
);

export default connect(
  createStructuredSelector({
    menus: getMenus,
    menu: getUIFilterMenu,
  }),
  {})(CustomDropdownMenu);
