import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
  Progress
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button, CardFooterStats } from "..";

import { getCustomers } from "../../actions/customers_action";
import {
  getUICustomerSelectedID,
  selectCustomerSuper
} from "../../actions/ui_action";

class CustomersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.options = {
      sortIndicator: false,
      sortName: "lastUpdateDate",
      sortOrder: "desc"
    };
  }

  agentFormatter = (cell, row) => (cell ? cell.username : "");

  agentSort = (a, b, order) => {
    if (order === "desc") {
      return b.username - a.username ? -1 : 1;
    } else {
      return a.username - b.username ? 1 : -1;
    }
  };

  filterAgent = (cell, row) => (cell ? cell.username : "");

  actionFormatter = (cell, row) => (
    <div>
      {
        <React.Fragment>
          <Button
            id={`viewCustomer${cell}`}
            round
            icon
            iconMini
            neutral
            disabled={row.id === this.props.customerSelectedID}
            color="info"
            onClick={() => {
              this.props.selectCustomerSuper(cell);
            }}
          >
            <i className="now-ui-icons files_paper" />
          </Button>
          <UncontrolledTooltip
            placement="left"
            target={`viewCustomer${cell}`}
            delay={0}
          >
            View Customer
          </UncontrolledTooltip>
        </React.Fragment>
      }
    </div>
  );

  activeFormatter = (cell, row) => (
    <div>
      {cell ? (
        <span style={{ color: "red" }}>Not Active</span>
      ) : (
        <span>Active</span>
      )}
    </div>
  );

  customerFormatter = (cell, row) => (
    <div>
      {row.inactive ? (
        <span style={{ color: "red" }}>{cell}</span>
      ) : (
        <span>{cell}</span>
      )}
    </div>
  );

  dateFormatter = (cell, row) =>
    cell ? moment.unix(cell).format("DD/MM/YYYY") : null;

  taxFormatter = (cell, row) => (
    <div>{cell === "N" ? <span>Non-GST</span> : <span>GST</span>}</div>
  );

  render() {
    return (
      <Card className="card-chart">
        <Progress
          animated
          value={100}
          style={
            !this.props.customers.loading
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        />
        <CardHeader>
          <CardTitle className="title card-header-margin">Customers</CardTitle>
        </CardHeader>
        <CardBody style={{ paddingBottom: 0 }}>
          <BootstrapTable
            data={this.props.customers}
            options={this.options}
            tableHeaderClass="table-column-header"
            tableBodyClass="table-body-myclass"
            trClassName={(row, rowIdx) =>
              row.id === this.props.customerSelectedID
                ? "table-highlight-myclass"
                : null
            }
            bordered={false}
            search={true}
            height="250"
            scrollTop={"Top"}
          >
            <TableHeaderColumn
              dataField="id"
              isKey
              hidden
              searchable={false}
              editable={false}
            >
              Customer ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="name"
              dataFormat={this.customerFormatter}
              dataSort
              editable={false}
            >
              Customer
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="inactive"
              dataFormat={this.activeFormatter}
              dataSort
              editable={false}
              width="100"
            >
              Active
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="hasGST"
              dataFormat={this.taxFormatter}
              dataSort
              searchable={false}
              width="90"
            >
              Tax
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="user"
              dataFormat={this.agentFormatter}
              filterValue={this.filterAgent}
              sortFunc={this.agentSort}
              dataSort
              width="150"
            >
              Agent
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="lastUpdateDate"
              dataFormat={this.dateFormatter}
              dataSort
              searchable={false}
              editable={false}
              thStyle={{ whiteSpace: "normal" }}
              width="150"
            >
              Last Updated
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="id"
              dataFormat={this.actionFormatter}
              editable={false}
              headerAlign="right"
              dataAlign="right"
              width="90"
            />
          </BootstrapTable>
        </CardBody>
        <CardFooter className="card-footer-margin">
          <CardFooterStats>
            {[
              {
                i: "now-ui-icons travel_info",
                t: `Total: ${this.props.customers.length} Customers`
              }
            ]}
          </CardFooterStats>
        </CardFooter>
      </Card>
    );
  }
}

export default connect(
  createStructuredSelector({
    customers: getCustomers,
    customerSelectedID: getUICustomerSelectedID
  }),
  {
    selectCustomerSuper
  }
)(CustomersComponent);
