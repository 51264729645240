import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  FormGroup,
  Label,
  Input
} from "reactstrap";
import {
  getUIFilterMonth,
} from '../../../../actions/ui_action';

const CustomDropdownMonth = (props) => (
  <FormGroup>
    <Label for="filterMonth">Month</Label>
    <Input
      type="select"
      name="select"
      id="filterMonth"
      value={props.month}
      onChange={event => props.retrieveFunc(parseInt(event.target.value))}
    >
      <option value="1">January</option>
      <option value="2">February</option>
      <option value="3">March</option>
      <option value="4">April</option>
      <option value="5">May</option>
      <option value="6">June</option>
      <option value="7">July</option>
      <option value="8">August</option>
      <option value="9">September</option>
      <option value="10">October</option>
      <option value="11">November</option>
      <option value="12">December</option>
    </Input>
  </FormGroup>
);

export default connect(
  createStructuredSelector({
    month: getUIFilterMonth,
  }),
  {})(CustomDropdownMonth);
