import axios from "axios";
import { DJANGO_CORE_ADDR } from "../config";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { notifySuccess, notifyError } from "../components/NotificationOptions";

import {
  getUIAccountSelectedID,
  getUIAccountSelectedGST,
  onCancelCreditForm
} from "./ui_action";

import { retrieveCredits } from "./credits_action";

export const FETCH_CREDIT = "credit/FETCH_CREDIT";
export const SET_CREDIT = "credit/SET_CREDIT";
export const ERROR_CREDIT = "credit/ERROR_CREDIT";

export const getCredit = state => state.credit;
export const getCreditLoading = state => state.credit.loading;
export const getCreditError = state => state.credit.error;

export const fetchCredit = () => ({
  type: FETCH_CREDIT
});

export const setCredit = value => ({
  type: SET_CREDIT,
  value
});

export const errorCredit = message => ({
  type: ERROR_CREDIT,
  message
});

export const createCredit = (credit, notifyFunc) => async (
  dispatch,
  getState
) => {
  dispatch(fetchCredit());
  if (!credit.description || !credit.amount) {
    dispatch(errorCredit("Fields are required"));
  } else {
    try {
      const responsePostCredit = await axios.post(
        `${DJANGO_CORE_ADDR}/credits/create/?gst=${getUIAccountSelectedGST(
          getState()
        )}`,
        Object.assign({}, credit, {
          customer: getUIAccountSelectedID(getState()),
          void: false
        }),
        { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
      );
      console.log("Credit Note created!", responsePostCredit);
      await notifyFunc(notifySuccess(`Credit Note created successfully.`));
      await dispatch(onCancelCreditForm());
      await dispatch(
        retrieveCredits(
          getUIAccountSelectedID(getState()),
          getUIAccountSelectedGST(getState())
        )
      );
    } catch (error) {
      console.error(error);
      dispatch(errorCredit("Some Error!"));
    }
  }
};

export const retrieveCredit = creditID => async (dispatch, getState) => {
  dispatch(fetchCredit());
  try {
    const responseGetCredit = await axios.get(
      `${DJANGO_CORE_ADDR}/credits/list/?id=${creditID}&gst=${getUIAccountSelectedGST(
        getState()
      )}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    dispatch(setCredit(responseGetCredit.data.credit));
  } catch (error) {
    console.error(error);
    dispatch(errorCredit("Some Error!"));
  }
};

export const updateCredit = (credit, notifyFunc) => async (
  dispatch,
  getState
) => {
  try {
    const responsePutCredit = await axios.patch(
      `${DJANGO_CORE_ADDR}/credits/update/?gst=${getUIAccountSelectedGST(
        getState()
      )}`,
      {
        id: credit.id,
        description: credit.description
      },
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );

    console.log("Credit edited!");
    await notifyFunc(notifySuccess(`Credit Note updated successfully`));
    await dispatch(
      retrieveCredits(
        getUIAccountSelectedID(getState()),
        getUIAccountSelectedGST(getState())
      )
    );
    await dispatch(onCancelCreditForm());
  } catch (error) {
    console.error(error);
    dispatch(error("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};

export const removeCredit = (creditId, notifyFunc) => async (
  dispatch,
  getState
) => {
  try {
    const responseDeleteCredit = await axios.delete(
      `${DJANGO_CORE_ADDR}/credits/delete/?gst=${getUIAccountSelectedGST(
        getState()
      )}&id=${creditId}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    console.log("credit deleted!");
    await notifyFunc(notifySuccess(`credit deletion successful`));
    await dispatch(
      retrieveCredits(
        getUIAccountSelectedID(getState()),
        getUIAccountSelectedGST(getState())
      )
    );
    await dispatch(onCancelCreditForm());
  } catch (error) {
    console.error(error);
    dispatch(error("Some Error!"));
    notifyFunc(notifyError("System error. Please try again later."));
  }
};

export const retrieveOutstandings = () => async (dispatch, getState) => {
  dispatch(fetchCredit());
  try {
    const responseGetOutstandings = await axios.get(
      `${DJANGO_CORE_ADDR}/outstandings/listinvoice/?id=${getUIAccountSelectedID(
        getState()
      )}&gst=${getUIAccountSelectedGST(getState())}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );

    dispatch(
      setCredit({
        outstandings: responseGetOutstandings.data
      })
    );
  } catch (error) {
    console.error(error);
    dispatch(errorCredit("Some Error!"));
  }
};

export const retrieveCreditPDF = (creditID, notifyFunc) => async (
  dispatch,
  getState
) => {
  dispatch(fetchCredit());
  try {
    await notifyFunc(notifySuccess(`Generating Credit Note...`));
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const responseGetCredit = await axios.get(
      `${DJANGO_CORE_ADDR}/credits/list/?id=${creditID}&gst=${getUIAccountSelectedGST(
        getState()
      )}`,
      { headers: { Authorization: `JWT ${localStorage.getItem("token")}` } }
    );
    console.log("Received Credit Note Generation: ", responseGetCredit.data);
    dispatch(setCredit(responseGetCredit.data.credit));

    let pdf = {};

    if (responseGetCredit.data.credit.customer.hasGST === "Y") {
      let customerInfo = [
        {
          text: `Company : ${responseGetCredit.data.credit.customer.name}`
        },
        {
          text: `Address : ${responseGetCredit.data.credit.customer.address}`
        },
        {
          text: `Singapore ${responseGetCredit.data.credit.customer.postal}`
        },
        {
          text: `E-Mail : ${responseGetCredit.data.credit.customer.email}`
        },
        {
          text: `Telephone : ${responseGetCredit.data.credit.customer.contact}`
        },
        {
          text: `HP : ${responseGetCredit.data.credit.customer.altContact}`,
          margin: [0, 0, 0, 5]
        }
      ];

      let info = [];

      info.push(customerInfo);

      let pdfTable = [
        [
          {
            text: "Description",
            alignment: "center",
            style: "tableHeader",
            border: [true, true, true, false]
          },
          {
            text: "Amount",
            alignment: "center",
            style: "tableHeader",
            border: [true, true, true, false]
          }
        ],
        [
          { text: responseGetCredit.data.credit.description },
          {
            text: `$${responseGetCredit.data.credit.amount.toFixed(2)}`,
            alignment: "center"
          }
        ]
      ];

      pdfTable.push([
        { text: " ", alignment: "center" },
        { text: " ", alignment: "center" }
      ]);

      const gstInPercent = (responseGetCredit.data.gst * 100).toFixed(0);
      const gstFormula = total =>
        (total * gstInPercent) / (100 + parseInt(gstInPercent));

      pdfTable.push([
        {
          text: `Total (inclusive of ${gstInPercent}% GST $${gstFormula(
            responseGetCredit.data.credit.amount
          ).toFixed(2)})`,
          alignment: "center"
        },
        {
          text: `$${responseGetCredit.data.credit.amount.toFixed(2)}`,
          alignment: "center"
        }
      ]);
      pdf = {
        content: [
          {
            text: "ISO DELIGHT PTE LTD",
            style: "header",
            alignment: "center"
          },
          {
            text: "Company / GST Registration Number: 200918960G",
            alignment: "center"
          },
          {
            text: "1002 Tai Seng Ave, #01-2550 Singapore 534409",
            alignment: "center"
          },
          {
            text: "Telephone : 64876387       Facsimile : 62823655",
            alignment: "center"
          },
          {
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    text: "CREDIT NOTE",
                    style: "subheader",
                    alignment: "center",
                    border: [false, true, false, false]
                  }
                ],
                [
                  {
                    alignment: "justify",
                    columns: [
                      info,
                      [
                        {
                          text: `Credit Note : ISO/CN/${
                            responseGetCredit.data.credit.year
                          }/${responseGetCredit.data.credit.id}`,
                          margin: [0, 0, 0, 5],
                          alignment: "right"
                        },
                        {
                          text: `Date : ${moment
                            .unix(responseGetCredit.data.credit.date)
                            .format("DD MMM YYYY")}`,
                          margin: [0, 0, 0, 5],
                          alignment: "right"
                        }
                      ]
                    ],
                    border: [false, false, false, false]
                  }
                ]
              ]
            },
            margin: [0, 5, 0, 5]
          },
          {
            table: {
              heights: [10, 400, 10, 10],
              widths: ["*", 100],
              headerRows: 1,
              body: pdfTable
            }
          }
        ],
        styles: {
          header: {
            fontSize: 16,
            bold: false,
            alignment: "justify",
            margin: [0, 0, 0, 5]
          },
          subheader: {
            fontSize: 12,
            bold: false,
            alignment: "justify",
            margin: [0, 0, 0, 5]
          },
          tableHeader: {
            bold: true,
            fontSize: 10
          }
        },
        defaultStyle: { fontSize: 9 }
      };
    } else {
      let customerInfo = [
        {
          text: `Company : ${responseGetCredit.data.credit.customer.name}`
        },
        {
          text: `Address : ${responseGetCredit.data.credit.customer.address}`
        },
        {
          text: `Singapore ${responseGetCredit.data.credit.customer.postal}`
        },
        {
          text: `E-Mail : ${responseGetCredit.data.credit.customer.email}`
        },
        {
          text: `Telephone : ${responseGetCredit.data.credit.customer.contact}`
        },
        {
          text: `HP : ${responseGetCredit.data.credit.customer.altContact}`,
          margin: [0, 0, 0, 5]
        }
      ];

      let info = [];

      info.push(customerInfo);

      let pdfTable = [
        [
          {
            text: "Description",
            alignment: "center",
            style: "tableHeader",
            border: [true, true, true, false]
          },
          {
            text: "Amount",
            alignment: "center",
            style: "tableHeader",
            border: [true, true, true, false]
          }
        ],
        [
          { text: responseGetCredit.data.credit.description },
          {
            text: `$${responseGetCredit.data.credit.amount.toFixed(2)}`,
            alignment: "center"
          }
        ]
      ];

      pdfTable.push([
        { text: " ", alignment: "center" },
        { text: " ", alignment: "center" }
      ]);
      pdfTable.push([
        { text: `Total `, alignment: "center" },
        {
          text: `$${responseGetCredit.data.credit.amount.toFixed(2)}`,
          alignment: "center"
        }
      ]);
      pdf = {
        content: [
          {
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    text: "CREDIT NOTE",
                    style: "subheader",
                    alignment: "center",
                    border: [false, false, false, false]
                  }
                ],
                [
                  {
                    alignment: "justify",
                    columns: [
                      info,
                      [
                        {
                          text: `Credit Note : CN/${
                            responseGetCredit.data.credit.year
                          }/${responseGetCredit.data.credit.id}`,
                          margin: [0, 0, 0, 5],
                          alignment: "right"
                        },
                        {
                          text: `Date : ${moment
                            .unix(responseGetCredit.data.credit.date)
                            .format("DD MMM YYYY")}`,
                          margin: [0, 0, 0, 5],
                          alignment: "right"
                        }
                      ]
                    ],
                    border: [false, false, false, false]
                  }
                ]
              ]
            },
            margin: [0, 5, 0, 5]
          },
          {
            table: {
              heights: [10, 400, 10, 10],
              widths: ["*", 100],
              headerRows: 1,
              body: pdfTable
            }
          }
        ],
        styles: {
          header: {
            fontSize: 16,
            bold: false,
            alignment: "justify",
            margin: [0, 0, 0, 5]
          },
          subheader: {
            fontSize: 12,
            bold: false,
            alignment: "justify",
            margin: [0, 0, 0, 5]
          },
          tableHeader: {
            bold: true,
            fontSize: 10
          }
        },
        defaultStyle: { fontSize: 9 }
      };
    }

    pdfMake.createPdf(pdf).open();
  } catch (error) {
    console.error(error);
    dispatch(errorCredit("Some Error!"));
  }
};
