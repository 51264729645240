import { combineReducers } from "redux";
import ui from "./ui_reducer";
import user from "./user_reducer";
import selecteduser from "./selecteduser_reducer";
import users from "./users_reducer";
import chartdata from "./chartdata_reducer";
import customers_summary from "./customers_summary_reducer";
import order from "./order_reducer";
import orders from "./orders_reducer";
import salesorders from "./salesorders_reducer";
import customer from "./customer_reducer";
import customers from "./customers_reducer";
import invoice from "./invoice_reducer";
import invoices from "./invoices_reducer";
import menus from "./menus_reducer";
import menuitems from "./menuitems_reducer";
import trips from "./trips_reducer";
import rates from "./rates_reducer";
import foodprep from "./foodprep_reducer";
import mode from "./mode_reducer";
import debit from "./debit_reducer";
import debits from "./debits_reducer";
import credit from "./credit_reducer";
import credits from "./credits_reducer";
import payment from "./payment_reducer";
import payments from "./payments_reducer";
import dailystats from "./dailystats_reducer";

export default combineReducers({
  ui,
  user,
  selecteduser,
  users,
  chartdata,
  customers_summary,
  order,
  orders,
  salesorders,
  customer,
  customers,
  invoice,
  invoices,
  menus,
  menuitems,
  trips,
  rates,
  foodprep,
  mode,
  debit,
  debits,
  credit,
  credits,
  payment,
  payments,
  dailystats
});
