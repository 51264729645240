import React from "react";
import NotificationAlert from 'react-notification-alert';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Row, Col } from "reactstrap";

import { PanelHeader, SetRateAdminComponent } from "../../components";

import {
  getUIRateIsEdit,
  onCancelRateForm,
} from '../../actions/ui_action';

import { getRate, retrieveRate } from '../../actions/rates_action';

class Rate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.notifyRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {

    if(Object.keys(this.props.rate).length === 0)
    {
      return true;
    }

    return nextProps.rateIsEdit !== this.props.rateIsEdit
  }

  componentDidMount() {
    this.props.onCancelRateForm();
    this.props.retrieveRate();
  }

  onNotification = options => this.notifyRef.current.notificationAlert(options);

  render() {
    return (
      <div>
        <NotificationAlert ref={this.notifyRef} />
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <SetRateAdminComponent
                key={this.props.rateIsEdit ? 0 : this.props.rate.id}
                notifyFunc={(options) => this.onNotification(options)}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
    rate: getRate,
    rateIsEdit: getUIRateIsEdit,

  }),
  {
    retrieveRate,
    onCancelRateForm
  })(Rate);