import CustomerSuperPage from "../views/Dashboard/CustomerSuperPage.jsx";
import ChangePassPage from "../views/Dashboard/UserManagePass";

const dashRoutes = [
  {
    path: "/customer-page",
    name: "Customer Config",
    icon: "business_briefcase-24",
    component: CustomerSuperPage
  },
  {
    hide: true,
    path: "/changepass",
    component: ChangePassPage
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/customer-page",
    name: "Customer Config"
  }
];
export default dashRoutes;
