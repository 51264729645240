import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { FormGroup, Label, Input } from "reactstrap";
import { getUIFilterAgent } from "../../../../actions/ui_action";

import { getUsers } from "../../../../actions/users_action";

const CustomDropdownMenu = props => (
  <FormGroup>
    <Label for="filterMenu">Agent</Label>
    <Input
      type="select"
      name="select"
      id="filterAgent"
      value={props.agent}
      onChange={event => props.retrieveFunc(parseInt(event.target.value))}
    >
      {props.agents.map(
        agent =>
          agent.groups === "sales" && (
            <option value={agent.id} key={agent.id}>
              {agent.username}
            </option>
          )
      )}
      <option value={0} key={0}>
        All Agents
      </option>
    </Input>
  </FormGroup>
);

export default connect(
  createStructuredSelector({
    agents: getUsers,
    agent: getUIFilterAgent
  }),
  {}
)(CustomDropdownMenu);
